import { useQuery } from "react-query";
import { projectServices } from "../service/projectService";

const useGetOneProject = (projectId) => {
  const fetchOneProject = async () => {
    const data = await projectServices.getOneProject(projectId);
    return data;
  };

  const getProject  = useQuery(["oneProject", projectId], fetchOneProject, {
    enabled: !!projectId, 
    staleTime: 0,          // Data is always considered stale
    cacheTime: 0,          // Cache is cleared immediately after it's unused
    refetchOnMount: true,  // Refetch data every time the component mounts
    refetchOnWindowFocus: true,
  });

  return getProject;
};

export default useGetOneProject;
