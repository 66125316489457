import { useQuery } from "react-query";
import attendanceServices from "./attendanceServices";

const useAttendanceHook = (params) => {
  return useQuery(["attendance",params.month,params.startDate,params.endDate], () => {
    return attendanceServices.getAttendance(params);
  });
};

export default useAttendanceHook;
