import moment from "moment";
import { DataTable } from "../../../PageResponsibility/src/admin/components/table/Data-Table";
import useEventStore from "../../store/eventStore";
import { useLocation } from "react-router-dom";

const HolidayTable = () => {
  const { holidays } = useEventStore();
  const state=useLocation()

  const Columns = [
    { header: "S.No", cell: ({ row }) => row.index + 1 },
    {
      header: "Occasion",
      cell:({row})=>row.original.title
    },
    {
      header: "Description",
      cell:({row})=>row.original.description

      
    },
    {
      header: "Date",
      cell: ({ row }) => moment(row.original.date).format("DD-MM-YYYY"),
    },
    // {header:'FullDay',
    //   cell:({row})=>row.allDay===true?'yes':'No'
    // },
  ];

  return (
    <DataTable
      columns={Columns}
      data={(!state?.state?holidays:state?.state?.data)||[]}
      heading="Holidays list"
      defaultTheme={true}
      TableHeight={"250px"}
    />
  );
};

export default HolidayTable;
