import React, { useEffect, useState } from "react";
import { DataTable } from "../components/table/Data-Table";
import axios from "../config/axiosAuth";
import { baseUrl } from "../config/baseUrl";
import "./page.css";
import { useNavigate } from "react-router-dom";

const Page = ({meetings}) => {
  // const [meeting, setMeeting] = useState();

  // const handleAllmeeting = () => {
  //   axios
  //     .get(baseUrl + "/allMeetings")
  //     .then((res) => {
  //       setMeeting(res.data);
  //     })

  //     .catch((err) => {
  //     });
  // };
const navigate=useNavigate()
  // useEffect(() => {
  //   handleAllmeeting();
  // }, []);
  

  const JoinMeetingClick=(id)=>{
    const data={linkId:id.linkId,
      id:id._id
    }
   navigate(`/meet/${id.linkId}`,{
    state: data
    
  })
}
const columns = [
  {
    header: "S.No",
    cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },
  {
    accessorKey: "title",
    header: "Title",
  },

  {
    accessorKey: "date",
    Header: "Date-Time",
  },

  {
    accessorKey: "description",
    header: "Description",
  },
  {
    header: "Meeting",
    cell: ({ row }) => {
      return (
        <span
          style={{
            width: "10vw",
            padding:"6px",
            background: "#085394e6",
            color: "white",
            borderRadius: "5px",
            cursor:"pointer"
          }}
          onClick={()=>{return(
            JoinMeetingClick(row.original))}}
        >
          Join Meeting
        </span>
      );
    },
  },
];

  return (
    <div>
          {/* <div style={{display:"flex",justifyContent:"flex-end"}}>
        <button type="btn" className="join_meeting_btn">Join Meeting</button>
      </div> */}
      <div>
        <DataTable heading={"Meetings"}columns={columns} data={meetings||[]} defaultTheme={true} TableHeight={'200px'}/>
      </div>

    
    </div>
  );
};

export default Page;
