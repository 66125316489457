import React, { useState } from "react";
import PeopleSection from "./PeopleSection";
import ChatSection from "./ChatSection";
import { IoIosChatboxes } from "react-icons/io";
import { BsFillPeopleFill } from "react-icons/bs";

const RightSection = ({ roomId, socketRef, userId,messages, setMessages,userName }) => {
  const[showchat,setShowchat]=useState(true);
  const[showPeople,setShowPeople]=useState(false)
  const openChat=()=>{
    setShowchat(true)
    setShowPeople(false)
  }

  const openPeople=()=>{
    setShowchat(false)
    setShowPeople(true)
  }
  return (
    <>
    <div className="right_section_options">

    <div onClick={openChat}>  <IoIosChatboxes color="white" size="28px" /></div>  <div onClick={openPeople}><BsFillPeopleFill  color="white" size="28px"/></div>
    </div>
      {showchat && <ChatSection socketRef={socketRef} userName={userName} userId={userId} roomId={roomId} messages={messages} setMessages={setMessages}/>}
      {showPeople && <PeopleSection roomId={roomId} />}
    </>
  );
};

export default RightSection;
