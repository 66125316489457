import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  Modal,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Pagination,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { SendMessageModal } from "../../components/modals/SendMessageModal";

const ResponsibilityForTabs = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [expandedEmployee, setExpandedEmployee] = useState(null);

  const { responsibilty, takeResponsibilityBackMutation } = useEmployeeHook();

  const filterResponsibility = responsibilty?.filter(
    (employee) =>
      employee.responsibility && Object.keys(employee.responsibility).length > 0
  );

  const pageSize = 3;

  let totalPages = Math.ceil(filterResponsibility?.length / pageSize);

  const toggleEmployee = (employeeId) => {
    setExpandedEmployee((prev) => (prev === employeeId ? null : employeeId));
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [sendMessageModal, setSendMessageModal] = useState(false);
  const [employeeId, setEmployeeId] = useState([]);

  const handleOpenModal = (employee) => {
    setSelectedEmployee(employee);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedEmployee(null);
    setModalOpen(false);
  };

  const handleDeleteResponsibility = (employeeId, responsibilityId) => {
    takeResponsibilityBackMutation.mutate({
      employeeId: employeeId,
      responsibility: responsibilityId,
    });

    handleCloseModal();
    setSendMessageModal(true);
    setEmployeeId([employeeId]);
  };

  return (
    <>
      <h3 className="ml-2">Responsibility</h3>
      <div className="table-container">
        <table>
          <thead>
            <tr
              style={{
                color: "#085394",
                borderBottom: "2px solid #085394",
              }}
            >
              <th>S.No.</th>
              <th>Name</th>
              <th>Employee Id</th>
              <th colSpan={2}>Responsibilities</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="tableBody">
            {filterResponsibility && filterResponsibility?.length > 0 ? (
              filterResponsibility
                ?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
                .map((employee, index) => {
                  const continuousSNo =
                    (currentPage - 1) * pageSize + index + 1;
                  const isExpanded = expandedEmployee === employee.employeeId;

                  return (
                    <React.Fragment key={employee._id}>
                      <tr
                        style={{
                          borderBottom: "1px solid #ddd",
                          cursor: "pointer",
                        }}
                        onClick={() => toggleEmployee(employee.employeeId)}
                      >
                        <td>{continuousSNo}</td>
                        <td>{employee?.name}</td>
                        <td>{employee?.employeeId}</td>
                        <td>
                          {isExpanded ? (
                            employee.responsibility &&
                            Object.keys(employee.responsibility).length > 0 ? (
                              <ul
                                style={{
                                  listStyle: "none",
                                  padding: 0,
                                  marginLeft: 0,
                                }}
                              >
                                {Object.entries(employee.responsibility).map(
                                  ([key, value]) => (
                                    <li
                                      key={key}
                                      style={{
                                        marginBottom: "8px",
                                      }}
                                    >
                                      <strong>{key}:</strong>{" "}
                                      {Array.isArray(value) ? (
                                        value.map((item, index) => (
                                          <span key={index}>
                                            {item}
                                            {index < value.length - 1 && ", "}
                                          </span>
                                        ))
                                      ) : (
                                        <span>{value}</span>
                                      )}
                                    </li>
                                  )
                                )}
                              </ul>
                            ) : (
                              "No Responsibilities"
                            )
                          ) : (
                            <span>Show Responsibilities</span>
                          )}
                        </td>
                        <td>
                          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </td>

                        <td>
                          <Button
                            variant="contained"
                            onClick={() => handleOpenModal(employee)}
                          >
                            Take Responsibility
                          </Button>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })
            ) : (
              <tr>
                <td colSpan="4">No Employees present</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <Stack spacing={2} className="pagination-container">
        <Typography>Page: {currentPage}</Typography>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          color="primary"
        />
      </Stack>

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            padding: "20px",
          }}
        >
          <Typography variant="h6">
            {selectedEmployee?.name}'s Responsibilities
          </Typography>
          <List>
            {selectedEmployee &&
              Object.entries(selectedEmployee?.responsibility || {}).map(
                ([responsibilityId, value]) => (
                  <ListItem key={responsibilityId}>
                    <ListItemText primary={responsibilityId} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() =>
                          handleDeleteResponsibility(
                            selectedEmployee._id,
                            responsibilityId
                          )
                        }
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              )}
          </List>
        </div>
      </Modal>

      <SendMessageModal
        isOpen={sendMessageModal}
        onClose={() => setSendMessageModal(false)}
        employeeId={employeeId}
      />
    </>
  );
};

export default ResponsibilityForTabs;
