import React, { Component, useEffect, useRef, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import ProgressBar from "react-customizable-progressbar";
import { PiMagnifyingGlassThin } from "react-icons/pi";
import axios from "../../config/axiosAuth";
import { baseUrl } from "../../config/baseUrl";
import { CloseFullscreen } from "@mui/icons-material";
import useProjectHook from "../../hooks/useProjectHook";
import { hexToRGBA } from "../../../utils/lib";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";

const DougnutCharts = () => {
  const [option, setOption] = useState(null);
  const [taskProgress, setTaskProgress] = useState([]);
  const [taskAssignment, setTaskAssignmnet] = useState([]);
  const [task, setTask] = useState("");
  const { departmentList } = useEmployeeHook();
  const [selectedProject, setSelectedProject] = useState("");
  const [progress, setProgress] = useState(0);
  const { addProjectMutation } = useProjectHook();
  const { data: projects } = useProjectHook();
  const [searchEmployee, setSearchEmployee] = useState("");

  const CanvasJSChart = CanvasJSReact.CanvasJSChart;
const navigate=useNavigate()
  useEffect(() => {
    if (Array.isArray(projects) && projects.length > 0) {
      const SelectedProjectId = projects[0]?._id;
      setSelectedProject(SelectedProjectId);
    }
  }, [projects]);

  const containerProps = {
    width: "100%",
    height: "200px",
    margin: "auto",
  };
  const containerRef = useRef(null);
  const [radius, setRadius] = useState(0);

  useEffect(() => {
    const calculateRadius = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const width = (20 / 100) * containerWidth;
        const calculatedRadius = width / 3;
        setRadius(calculatedRadius);
      }
    };

    calculateRadius();

    window.addEventListener("resize", calculateRadius);
    return () => window.removeEventListener("resize", calculateRadius);
  }, []);

  const handletaskprogress = () => {
    axios
      .get(baseUrl + "/taskprogress", {})

      .then((res) => {
        setTaskProgress(res.data?.projects);
      })

      .catch((err) => {
      });
  };

  const handletaskassignment = () => {
    axios
      .post(baseUrl + "/getTaskAssignment", {
        teamId: task,
        name: searchEmployee,
      })

      .then((res) => {
        setTaskAssignmnet(res.data.employees);
      })

      .catch((err) => {
      });
  };

  // const handletaskbyproject = () => {
  //   axios
  //     .post(baseUrl + "/task-by-projects", {
  //       employeeId: task,
  //     })

  //     .then((res) => {
  //     })

  //     .catch((err) => {
  //     });
  // };

  useEffect(() => {
    handletaskprogress();
    handletaskassignment();
    // handletaskbyproject();
  }, [task, searchEmployee]);

  const handlechangetask = (e) => {
    setTask(e.target.value);
  };

  const handlTotalemployeeChart = () => {
    axios
      .get(baseUrl + "/employeeGender")
      .then((res) => {
        setOption(res.data.result);
      })

      .catch((err) => {
      });
  };

  useEffect(() => {
    handlTotalemployeeChart();
  }, []);

  useEffect(() => {
    if (selectedProject !== "") {
      handleWeeklyProgress();
    }
  }, [selectedProject]);

  const handleWeeklyProgress = () => {
    axios
      .post(baseUrl + "/projectWeeklyProgress", {
        projectId: selectedProject,
      })

      .then((res) => {
        setProgress(res.data.finalPercentage);
      })

      .catch((err) => {
      });
  };
  return (
    <>
      <div className="flex gap-3 pt-[25px] w-full" ref={containerRef}>
        <div className="w-[30%] h-auto bg-white  rounded-lg">
          <h4 className="ml-4 mt-4 font-bold text-xl">TOTAL EMPLOYEES</h4>
          <div className="w-full">
            {option && (
              <CanvasJSChart options={option} containerProps={containerProps} />
            )}
          </div>
        </div>

        <div
          className="w-[20%] h-auto bg-white rounded-lg flex flex-col items-center justify-center "
          style={{ width: "20%" }}
        >
          <p className=" font-bold text-lg p-2 text-left w-full">
            Weekly Progress
          </p>
          <select
            style={{
              width: "10vw",
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "auto",
              marginLeft: "10px",
            }}
            value={selectedProject}
            onChange={(e) => setSelectedProject(e.target.value)}
          >
            <option value="">Select Project</option>
            {projects?.map((project) => (
              <option key={project.id} value={project._id}>
                {project.projectName}
              </option>
            ))}
          </select>
          <div
            className="flex justify-center items-center h-auto w-full"
            style={{ width: "20%" }}
          >
            <ProgressBar
              radius={radius}
              strokeWidth={12}
              strokeColor="#25CD25"
              strokeLinecap="round"
              trackStrokeWidth={12}
              counterClockwise
              progress={progress}
            >
              {({ text }) => (
                <div>
                  <strong>{progress}%</strong>
                  <p className="text-left">Weekly Progress</p>
                </div>
              )}
              <div className="absolute inset-0 flex justify-center items-center text-lg font-bold text-gray-800">
                <div>
                  <h1 className="text-[#25CD25] font-[20-px] flex justify-center">
                    {progress}%
                  </h1>
                  <p className="text-[gray] text-[10px] text-left w-full">
                    Task Completed
                  </p>
                </div>
              </div>
            </ProgressBar>
          </div>
        </div>

        <div className="w-[20%] h-auto flex flex-col justify-between max-h-[300px]">
          <div className="w-full h-[15%]  bg-white  rounded-md">
            <select
              onChange={handlechangetask}
              className="h-full w-full border-0 rounded-lg p-2 text-[10px]"
              name="cars"
              id="cars"
              value={task}
            >
              <option value="" className="text-sm ">
                Assign Task To
              </option>
              {departmentList && departmentList?.length > 0
                ? departmentList?.map((department) => (
                    <option value={department._id} className="text-sm ">
                      {department?.teamName}
                    </option>
                  ))
                : null}
            </select>
          </div>

          <div className="w-full h-[80%] bg-white  rounded-lg ">
            <div className="p-2 w-full">
              <div className="search_icon  ">
                <PiMagnifyingGlassThin />
                <input
                  placeholder="Search"
                  onChange={(e) => setSearchEmployee(e.target.value)}
                />
              </div>
            </div>
            <div className="w-full h-[70%] overflow-auto">
              <div className="flex justify-between mt-4  flex-col gap-4">
                {taskAssignment && taskAssignment?.length > 0 ? (
                  taskAssignment?.map((taskCounts) => (
                    <div className="w-full flex justify-between items-center  hover:bg-[#f4f7fc] p-2 hover:border-l-2	hover:border-sky-500 cursor-pointer	" onClick={()=>navigate(`/task/${taskCounts?._id}`)}>
                      <Avatar
                        sx={{
                          width: "30px",
                          height: "30px",
                          fontSize: "16px",
                        }}
                      >
                        {taskCounts?.name[0]}
                      </Avatar>
                      <div className="w-[35%] text-left text-[12px] text-[navyblue] pl-2">
                        <h3>{taskCounts.name}</h3>
                      </div>

                      <div className="text-[10px] text-[lightGrey] text-right">
                        {taskCounts.totalTaskCount} tasks on Progress
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="w-full text-[lightGrey] text-center">
                    NO RESULTS FOUND
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="w-[30%] bg-white  rounded-lg p-4 px-8">
          <h4 className="p-1  text-xl font-bold">TASK PROGRESS</h4>
          <div className="flex justify-between w-full p-2  ">
            <div style={{ height: "200px", overflowY: "auto", width: "100%" }}>
              {taskProgress && taskProgress?.length > 0
                ? taskProgress?.map((success) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        marginBottom: "20px",
                      }}
                    >
                      <div className="w-full">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "10px",
                          }}
                        >
                          <div className="mt-2">
                            <p>{success?.projectName.slice(0, 16)}....</p>
                          </div>

                          <div className="mt-2">
                            <p style={{ color: "lightgrey" }}>
                              {success?.completedTask}/{success?.totalTasks}
                            </p>
                          </div>
                        </div>
                        <div className="w-full">
                          <div
                            className="w-full h-[10px] rounded-[3px]"
                            style={{
                              backgroundColor: hexToRGBA(success.color, 0.2),
                            }}
                          >
                            <div
                              className="h-full rounded-[3px]"
                              style={{
                                width: `${success?.finalPercentage}%`,
                                backgroundColor: success.color,
                              }}
                            ></div>
                          </div>

                          {/* <progress value={success?.finalPercentage} max={100} 
                       style={{
                        width: "100%",
                        height: "10px",
                        borderRadius:'3px',
                        color: success?.color || "blue", // Set color from API or default
                        backgroundColor:hexToRGBA(
                          success?.color,
                          0.2
                        ), // Set background color from API or default
                    }}/> */}
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DougnutCharts;
