import React, { useEffect, useRef, useState, useCallback } from "react";
import { ZegoExpressEngine } from "zego-express-engine-webrtc";
import { IoIosChatboxes } from "react-icons/io";
import { base } from "../../config/baseUrl";
import io from "socket.io-client";
import useUserStore from "../../store/userStore";
import { useQuery } from "react-query";
import authService from "../../services/authServicesUser";
import "./meetings.css";

import {
  enabledMic,
  enabledVideo,
  disableMic,
  disableVideo,
  endcallButton,
  enterFullScreen,
  enableSpeaker,
} from "./importConstants";
import { LuScreenShare } from "react-icons/lu";

import { Speaker } from "@material-ui/icons";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import ScreenShareRoomDetailsStore from "../../store/ScreenShareRoomDetailsStore";
import RightSection from "./RightSection";

const Meeting = () => {
  const { state } = useLocation();
  const Room = state.linkId;
  const user = useUserStore((state) => state.user);
  const [socketRef, zegoRef] = useOutletContext();
  const Navigate = useNavigate();

  const peerRef = useRef(null);
  const localStreamId = useRef(null);
  const remoteStreamId = useRef(null);
  const localVideoStream = useRef(null);
  const remoteVideoStream = useRef([]);
  const roomId = useRef(null);
  const myUserId = useRef(null);
  const screenStreamId = useRef(null);
  const screenMixingTaskId = useRef(null);
  //////////////
  const [MicroPhoneMuted, setMicroPhoneMuted] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);
  const [showChatSection, setShowChatSection] = useState(false);
  const [speakerMuted, setSpeakerMuted] = useState(false);
  const [callStarted, setCallStarted] = useState(false);
  const [screenShareStream, setScreenShareStream] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [remoteVideoList, setRemoteVideoList] = useState([]);
  const [messages, setMessages] = useState([]);
  const [peerPresent, setPeerPresent] = useState(false);
  const [refsSet, setRefsSet] = useState(false);
  const [unFlipLocalVideo, setUnFlipLocalVideo] = useState(false);
  const [screenShareStreamObject, setScreenShareStreamObject] = useState(null);
  const activeStreams = useRef([]);
  const combinedStreams = useRef([]);
  const myUserName = useRef(null);

  const {
    screenShareRoom,
    setIsInGroupMeetingTab,
    screenShareSteamIdFromStore,
    isInGroupMeetingTab,
  } = ScreenShareRoomDetailsStore();

 

  useEffect(() => {
    setTimeout(() => {
      socketRef.current.on("joinRequestAccepted", (data) => {
        socketRef.current.emit("join", { room: Room });
      });
      socketRef.current.on("joinRequestRejected", (data) => {
        endVideoCall();
      });
      socketRef.current.on("activeUsers", (data) => {
        const activeUsersList = data.users;
        let newRemoteVideoList = [...remoteVideoList];

        activeUsersList.forEach((stream) => {
          if (!newRemoteVideoList.includes(stream.streamId)&&stream.id!==myUserId) {
            newRemoteVideoList = [stream.streamId, ...newRemoteVideoList];
          }
        });
        setRemoteVideoList(newRemoteVideoList);
      });
      socketRef.current.on("joined", (data) => {
        zegoRef.current.startPublishingStream(
          localStreamId.current,
          localVideoStream.current,
          { roomID: Room }
        );
        socketRef.current.on("message", (data) => {
          setMessages((prev) => [...prev, data.message]);
          console.log("recieved ", data);
        });
        socketRef.current.emit("addStreamToList", {
          streamId: localStreamId.current,
          room: Room,
          userId: myUserId.current,
        });
        const DATA = JSON.stringify(data);
        const currentRoomId = JSON.parse(DATA);
        roomId.current = currentRoomId.roomId;
        myUserId.current = currentRoomId.userId;
        myUserName.current = currentRoomId.userName;
        console.log(
          `userJoinedin ${currentRoomId.roomId} user ${currentRoomId.userId}`
        );
        startCall();
      });
      socketRef.current.on("ready", (data) => {
        console.log(data, "peerpresent");
        setPeerPresent(true);
      });
      socketRef.current.on("replacedStream", async (data) => {
        setScreenShareStream(data.streamId);
        await zegoRef.current.stopPlayingStream(data.streamId);
        const screenStream = await zegoRef.current.startPlayingStream(
          data.streamId
        );
        setScreenShareStreamObject(screenStream);
      });
      socketRef.current.on("resetReplacedStream", (data) => {
        setScreenShareStream(null);
        setScreenShareStreamObject(null);
      });
      socketRef.current.on("adminWantsToMuteYou", (data) => {
        zegoRef.current.muteMicrophone(true);
        setMicroPhoneMuted(true);
      });
      socketRef.current.on("signal", (data) => {
        try {
          if (peerRef.current) {
            peerRef.current.signal(data);
          }
        } catch (error) {
          console.error("Error sending signal:", error);
        }
      });
      socketRef.current.on("userId", (data) => {
        myUserId.current = data.userId;
        zegoLogin();
      });

      zegoRef.current.on("soundLevelUpdate", (streamList) => {
        const streamlist = streamList.filter((item) => item.type !== "push");
        streamlist.forEach((stream) => {
          console.log(
            "soundLevel",
            localStreamId.current,
            streamList,
            "streamId",
            stream.streamID
          );
          if (stream.soundLevel > 8) {
            socketRef.current.emit("activeStreamOfUser", {
              streamId: stream.streamID,
              room: Room,
            });
          }
        });
        // const streamlist = streamList.filter((item) => item.type === "pull");
        // const streamlist = streamList;
        // console.log("soundLevelUpdate", streamList);
        // streamlist.forEach((stream) => {
        // if (stream.soundLevel > 10) {
        //   const itemExists = activeStreams.current.includes(stream.streamID);
        //   if (!itemExists) {
        //       console.log(
        //         "enetrd 10 and adding stream to active",
        //         activeStreams.current,
        //         "itemExists",
        //         itemExists
        //       );
        //       // activeStreams.current = [
        //       //   ...activeStreams.current,
        //       //   stream.streamID,
        //       // ];
        //     const updatedActiveStreams = new Set(activeStreams.current);
        //     updatedActiveStreams.add(stream.streamID);
        //     activeStreams.current = Array.from(updatedActiveStreams);
        //   }
        // }
      });
      zegoRef.current.on("remoteMicStatusUpdate", (streamID, status) => {
        if (status === "MUTE" && streamID !== localStreamId) {
          socketRef.current.emit("removeUserFromActiveList", {
            streamId: streamID,
            room: Room,
          });
        } else if (status === "OPEN") {
        }
      });
      // });
      socketRef.current.on("flippedStreams", async (data) => {
        if (data.flippedStreamIds.length > 0) {
          setScreenShareStream(data.flippedStreamIds[0]);
          await zegoRef.current.stopPlayingStream(data.flippedStreamIds[0]);
          const screenStream = await zegoRef.current.startPlayingStream(
            data.flippedStreamIds[0]
          );
          setScreenShareStreamObject(screenStream);
          setRefsSet(true);
        }
      });
      socketRef.current.on("finalStreams", (data) => {
        const updatedStreams = data.streamIds.filter(
          (item) => item.streamId !== localStreamId.current
        );
        setRemoteVideoList(updatedStreams);
      });
      socketRef.current.on("adminRemovingYouFromRoom", (data) => {
        endVideoCall();
      });

      return () => {
      };
    }, 1000);
  }, []);
  const GetToken = async () => {
    const res = await authService.zegoToken();
    return res;
  };

  const chatIconClick = () => {
    setShowChatSection(!showChatSection);
  };
  const {
    data: zegoToken,
    isLoading,
    isError,
  } = useQuery("zegoToken", GetToken, {
    onSuccess: (data) => {
      localStorage.setItem("zegoToken", data.token);
    },
  });

  const PlayRemoteStreams = useCallback(async (streamID, videoElement) => {
    if (streamID !== null && videoElement) {
      await zegoRef.current.stopPlayingStream(streamID);
      const remoteStream = await zegoRef.current.startPlayingStream(streamID);
      videoElement.srcObject = remoteStream;
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (refsSet) {
        // combinedStreams.current
        remoteVideoList
          .filter((streamID) => streamID.streamId !== screenShareStream)
          .forEach((stream, index) => {
            PlayRemoteStreams(
              stream.streamId,
              remoteVideoStream.current[index]
            );
          });
      }
    }, 1000);
  }, [
    refsSet,
    PlayRemoteStreams,
    remoteVideoList,
    screenShareStream,
    screenShareStreamObject,
    activeStreams,
    combinedStreams.current,
  ]);

  const zegoLogin = async () => {
    const configOption = {
      videoBitrate: 300,
      audioBitrate: 48,
      camera: {
        audio: true,
        video: {
          quality: 4,
          width: 480,
          height: 480,
          frameRate: 15,
        },
      },
    };
    const token = localStorage.getItem("zegoToken");
    try {
      const loginResponse = await zegoRef.current.loginRoom(
        Room,
        token,
        { userID: myUserId.current, userName: user?.name },
        { userUpdate: true },
        { isUserStatusNotify: true }
      );
      if (loginResponse == true) {
        try {
          localVideoStream.current = await zegoRef.current.createZegoStream({
            configOption,
          });
          const localStream = localVideoStream.current;
          localVideoStream.current.audioMuted = false;
          localStream.playVideo(document.querySelector("#local-video"), {
            enableAutoPlayDialog: true,
          });
          localStreamId.current = new Date().getTime().toString();
        } catch (error) {
          console.error(
            "Failed to create stream or permissions are off",
            error
          );

          if (
            error.name === "NotAllowedError" ||
            error.name === "PermissionDeniedError"
          ) {
            alert(
              "Please grant permission to access your camera and microphone."
            );
          } else {
            alert(
              "An error occurred while creating the stream. Please enable Audio  and video permissions and try again."
            );
          }
        }
      } else if (loginResponse !== true) {
        console.error("Failed to login to the room");
        alert(
          "Failed to login to the room. Please check your credentials and try again."
        );
      }

      zegoRef.current.on("publisherStateUpdate", (result) => {
        var state = result["state"];
        var streamID = result["streamID"];
        var errorCode = result["errorCode"];
        var extendedData = result["extendedData"];
        if (state == "PUBLISHING") {
        
        } else if (state == "NO_PUBLISH") {
        } else if (state == "PUBLISH_REQUESTING") {
          
        }
        if (errorCode !== 0) {
        }
      });
      zegoRef.current.on("roomUserUpdate", (roomId, updateType, userList) => {
        if (updateType === "DELETE") {
        } else if (updateType === "ADD") {
        }
      });

      const config = {
        logLevel: "disable",
        remoteLogLevel: "disable",
        logURL: "disable",
      };
      zegoRef.current.setLogConfig(config);
    } catch (err) {
      endVideoCall();
     
    }
  };

  useEffect(() => {
    setIsInGroupMeetingTab(true);
  }, []);

  const HandleClick = async () => {
    setIsInGroupMeetingTab(true);

    setTimeout(() => {
      setRemoteVideoList([]);
      socketRef.current.emit("requestToJoin", { room: Room });
      setCallStarted(true);
    });
  };
  const startCall = async () => {
    zegoRef.current.setDebugVerbose(false);
    zegoRef.current.on(
      "roomStateChanged",
      async (roomID, reason, errorCode, extendedData) => {
        if (reason == "LOGINED") {
         
        } else if (reason == "LOGIN_FAILED") {
        }
      }
    );
    zegoRef.current.on("roomUserUpdate", (roomId, updateType, userList) => {});
    zegoRef.current.on(
      "roomStreamUpdate",
      async (roomId, updateType, streamList, extendedData) => {
        if (updateType == "ADD") {
        } else if ((updateType = "DELETE")) {
          remoteVideoList?.length <= 0 ? setRefsSet(false) : setRefsSet(true);
          socketRef.current.emit("removeStreamFromList", {
            streamId: streamList[0].streamID,
            room: Room,
          });
        }
      }
    );

    try {
      zegoRef.current.on(
        "roomStateUpdate",
        (roomId, state, errorCode, extendedData) => {
          if (errorCode === 1000017) {
          }
        }
      );
    } catch (err) {
    }
  };

  const endVideoCall = () => {
    setRemoteVideoList([]);
    socketRef.current.emit("removeUserFromActiveList", {
      streamId: localStreamId.current,
      room: Room,
    });
    setRefsSet(false);
    setIsInGroupMeetingTab(false);
    stateCleanups();
    try {
      zegoRef.current?.stopPublishingStream(localStreamId.current);
      zegoRef.current?.destroyStream(localVideoStream.current);
    } catch (error) {
      console.error("Error stopping local video stream:", error);
    }
    localVideoStream.current = null;
    zegoRef.current?.logoutRoom(Room);
    setCallStarted(false);
    socketRef.current.emit("removeStreamFromList", {
      streamId: localStreamId.current,
      room: Room,
    });
    socketRef.current.emit("removeUserFromRoom", {
      userId: myUserId.current,
      room: Room,
    });
    // setTimeout(()=>{
    //   Navigate('/userdashboard')
    // },1000)
  };
  const ToggleVideo = () => {
    setVideoMuted(!videoMuted);
    const stream = localVideoStream.current;
    zegoRef.current.mutePublishStreamVideo(stream, !videoMuted);
  };

  const toggleAudio = () => {
    setMicroPhoneMuted(!MicroPhoneMuted);
    zegoRef.current.muteMicrophone(!MicroPhoneMuted);
  };
  const startScreenShare = async () => {
    setSpeakerMuted(true);
    try {
      // Start capturing screen sharing
      await localVideoStream.current.startCaptureScreen();
      const settings = localVideoStream.current.stream
        .getVideoTracks()[0]
        .getSettings();
      // Update the publishing stream video of localStream
      await zegoRef.current.updatePublishingStream(localVideoStream.current, 0);
      // setUnFlipLocalVideo(true);
    } catch (err) {
      setUnFlipLocalVideo(false);
    }

    socketRef.current.emit("replaceOriginalStreamWithScreenshareStream", {
      streamId: localStreamId.current,
      room: Room,
    });
    socketRef.current.emit("addStreamToList", {
      streamId: localStreamId.current,
      room: Room,
      userId: myUserId.current,
    });
  };

  const stopScreenSharing = async () => {
    setSpeakerMuted(false);
    socketRef.current.emit("replaceScreenshareStreamWithOriginalStream", {
      streamId: localStreamId.current,
      userId: myUserId.current,
      room: Room,
    });
    setUnFlipLocalVideo(false);
    setScreenShareStream(null);
    reUpdateStream();
  };
  useEffect(() => {
    setTimeout(() => {
      zegoRef.current.on("screenSharingEnded", (stream) => {
        setSpeakerMuted(false);
        socketRef.current.emit("replaceScreenshareStreamWithOriginalStream", {
          streamId: localStreamId.current,
          room: Room,
          userId: myUserId.current,
        });
        setUnFlipLocalVideo(false);
        setScreenShareStream(null);
        reUpdateStream();
      });
    }, 1000);
  }, []);
  const reUpdateStream = async () => {
    try {
      await localVideoStream.current.startCaptureCamera();
      await zegoRef.current.updatePublishingStream(localVideoStream.current, 0);
    } catch (er) {}
  };
  useEffect(() => {
    if (unFlipLocalVideo) {
      const newLocalVideo = document.querySelector(
        ".screenshare_view #local-video"
      );
      if (newLocalVideo && localVideoStream.current) {
        localVideoStream.current.playVideo(newLocalVideo, {
          enableAutoPlayDialog: true,
        });
      }
    } else {
      const newLocalVideo = document.querySelector(" #local-video");
      if (newLocalVideo && localVideoStream.current) {
        localVideoStream.current.playVideo(newLocalVideo, {
          enableAutoPlayDialog: true,
        });
      }
    }
  }, [unFlipLocalVideo]);
 
  const stateCleanups = () => {
    setMicroPhoneMuted(false);
    setVideoMuted(false);
    setShowChatSection(false);
    setSpeakerMuted(false);
    setCallStarted(false);
    setScreenShareStream(null);
    setUsersList([]);
    setRemoteVideoList([]);
    setPeerPresent(false);
    setRefsSet(false);
    setUnFlipLocalVideo(false);
    setScreenShareStreamObject(null);
    setMessages([]);
  };

  return (
    <div className="meeting_layout">
      {!callStarted && <div onClick={HandleClick}>JoinCall</div>}
      {callStarted && (
        <>
          <div
            style={{ position: "relative" }}
            className={
              showChatSection
                ? "remote_video_section_with_chat_box remote_video_section"
                : "remote_video_section"
            }
          >
            {screenShareStream !== null && screenShareStream !== undefined && (
              <div className="screenshare_view">
                {/* {unFlipLocalVideo && (
                  <div
                    id="local-video"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                )} */}
                {screenShareStream !== null && (
                  <video
                    autoPlay
                    playsInline
                    ref={(el) => {
                      if (el && screenShareStreamObject) {
                        el.srcObject = screenShareStreamObject;
                      }
                    }}
                  />
                )}
              </div>
            )}
            <div
              className={
                unFlipLocalVideo || screenShareStream
                  ? "remote_videos_container_with_screenShare"
                  : "remote_videos_container"
              }
            >
              {peerPresent &&
                (showChatSection
                  ? remoteVideoList.slice(0, 7)
                  : remoteVideoList.slice(0, 10)
                )
                  
                  .filter((streamID) => streamID.streamId !== screenShareStream)
                  .map((streamID, index) => (
                    <div
                      className={
                        peerPresent
                          ? "double_remote_video_container"
                          : "_single_remote_video_container"
                      }
                      style={{
                        width:
                          remoteVideoList.length === 1
                            ? "100%"
                            : remoteVideoList.length <= 3
                            ? screenShareStream !== null
                              ? "calc(100% - 2px)"
                              : "calc(50% - 3px )"
                            : remoteVideoList.length >= 3
                            ? screenShareStream !== null
                              ? "calc(100% - 3px)"
                              : showChatSection
                              ? "calc(33% - 3px)"
                              : "calc(25% - 3px )"
                            : "calc(25% - 3px )",
                        height:
                          remoteVideoList.length <= 2
                            ? screenShareStream !== null
                              ? "50%"
                              : "100%"
                            : remoteVideoList.length <= 8
                            ? screenShareStream !== null
                              ? "calc(25% - 2px)"
                              : "calc(50% - 2px )"
                            : remoteVideoList.length > 8
                            ? "calc(33% - 2px )"
                            : "calc(33% - 2px )",
                      }}
                    >
                      <video
                        key={streamID.streamId}
                        autoPlay
                        playsInline
                        style={{
                          transform:
                            streamID === screenShareStream ? "scaleX(1)" : "",
                        }}
                        ref={(el) => {
                          remoteVideoStream.current[index] = el;
                          if (index === remoteVideoList.length - 1) {
                            setRefsSet(true);
                          }
                        }}
                
                        className="remote_video"
                      ></video>
                      <div
                        style={{
                          position: "absolute",
                          top: "10px",
                          left: "10px",
                          zIndex: "9",
                        }}
                      >
                        {streamID.name}
                      </div>
                      <div className="mute_mic_container">
                        <img
                          src={disableMic}
                          alt="mutemike"
                          className="mute_mic"
                        />
                      </div>
                    </div>
                  ))
                

                  }
                  {/* {remoteVideoList.length>2?  (showChatSection
                    ? remoteVideoList.slice(2,remoteVideoList.length)
                    : remoteVideoList.slice(2,remoteVideoList.length)
                  )
                    
                    .filter((streamID) => streamID.streamId !== screenShareStream)
                    .map((streamID, index) => (
                      <div
                        className={
                          peerPresent
                            ? "double_remote_video_container"
                            : "_single_remote_video_container"
                        }
                        style={{
                          width:'150px',
                          
                          height:'150px'
                                                  }}
                      >
                        <video
                          key={streamID.streamId}
                          autoPlay
                          playsInline
                          style={{
                            transform:
                              streamID === screenShareStream ? "scaleX(1)" : "",
                          }}
                          ref={(el) => {
                            remoteVideoStream.current[index] = el;
                            if (index === remoteVideoList.length - 1) {
                              setRefsSet(true);
                            }
                          }}
                  
                          className="remote_video"
                        ></video>
                        <div
                          style={{
                            position: "absolute",
                            top: "10px",
                            left: "10px",
                            zIndex: "9",
                          }}
                        >
                          {streamID.name}
                        </div>
                        <div className="mute_mic_container">
                          <img
                            src={disableMic}
                            alt="mutemike"
                            className="mute_mic"
                          />
                        </div>
                      </div>
                    )):''
} */}


                  

              <div
                style={{
                  display:
                    screenShareStream !== null &&
                    screenShareStream === localStreamId.current
                      ? "none"
                      : "",
                  width: peerPresent
                    ? remoteVideoList.length < 1
                      ? "100%"
                      : remoteVideoList.length <= 3
                      ? screenShareStream !== null || unFlipLocalVideo
                        ? "calc(100% - 3px)"
                        : "calc(50% - 3px )"
                      : remoteVideoList.length >= 3
                      ? showChatSection
                        ? unFlipLocalVideo || screenShareStream !== null
                          ? "width(100% - 3px)"
                          : "calc(33% - 3px)"
                        : "calc(25% - 3px )"
                      : "calc(25% - 3px )"
                    : "",
                  height: peerPresent
                    ? remoteVideoList.length < 1
                      ? "100%"
                      : remoteVideoList.length <= 8
                      ? "calc(50% - 2px )"
                      : remoteVideoList.length > 8
                      ? "calc(33% - 2px )"
                      : "calc(33% - 2px )"
                    : "",
                  transform: peerPresent
                    ? unFlipLocalVideo
                      ? ""
                      : screenShareStream !== null
                      ? "scaleX(-1)"
                      : remoteVideoList.length === 0
                      ? "scaleX(-1)"
                      : remoteVideoList.length <= 2
                      ? " scaleX(-1) translateY(-95%)"
                      : "scaleX(-1)"
                    : " scaleX(-1)",
                }}
                className={
                  peerPresent
                    ? remoteVideoList.length === 0
                      ? "local_video_stream_peer_absent"
                      : remoteVideoList.length <= 2
                      ? screenShareStream !== null || unFlipLocalVideo
                        ? "double_remote_video_container"
                        : "local_video_stream_peer_present"
                      : "double_remote_video_container"
                    : "local_video_stream_peer_absent"
                }
                id={unFlipLocalVideo ? "" : "local-video"}
              >
                {" "}
                <div
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    zIndex: "9",
                    transform: "scaleX(-1)",
                  }}
                >
                  you
                </div>
                <div className="mute_mic_container" style={{ left: "12px" }}>
                  <img src={disableMic} alt="mutemike" className="mute_mic" />
                </div>
              </div>
              {showChatSection ? (
                // combinedStreams.current.length > 7 ? (
                remoteVideoList.length > 7 ? (
                  <div
                    className={
                      peerPresent
                        ? "double_remote_video_container"
                        : "_single_remote_video_container"
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "calc(33% - 2px)",
                      height: "calc(33% - 2px )",
                    }}
                  >
                    + {remoteVideoList.length - 7}Others
                  </div>
                ) : (
                  ""
                )
              ) : remoteVideoList.length > 10 ? (
                <div
                  className={
                    peerPresent
                      ? "double_remote_video_container"
                      : "_single_remote_video_container"
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "calc(25% - 2px)",
                    height: "calc(33% - 2px )",
                    color: "black",
                  }}
                >
                  {/* {combinedStreams.current.length - 10}+others */}
                  {remoteVideoList.length - 10}+Others
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          {peerPresent && showChatSection && (
            <div className="chat_section">
              <RightSection
                roomId={state}
                userName={myUserName.current}
                messages={messages}
                setMessages={setMessages}
                socketRef={socketRef.current}
                userId={myUserId.current}
              />
            </div>
          )}

          {callStarted && (
            <div className="call_actions_tab">
              <div
                className="call_action_button_icon_container"
                onClick={toggleAudio}
              >
                <img
                  src={MicroPhoneMuted ? disableMic : enabledMic}
                  alt="mike"
                  className="m-auto  w-6 h-6 cursor-pointer"
                />
              </div>
              <div
                className="call_action_button_icon_container"
                onClick={!speakerMuted ? startScreenShare : stopScreenSharing}
              >
                <LuScreenShare color=" white" size={"28px"} />
              </div>
              <div
                className="call_action_button_icon_container"
                onClick={endVideoCall}
              >
                <img
                  src={endcallButton}
                  alt="mike"
                  className="m-auto w-6 h-6 cursor-pointer"
                />
              </div>
              <div
                className="call_action_button_icon_container"
                onClick={ToggleVideo}
              >
                <img
                  src={videoMuted ? disableVideo : enabledVideo}
                  alt="mike"
                  className="m-auto w-5 h-5 cursor-pointer"
                />
              </div>
              <div
                className="call_action_button_icon_container"
                onClick={chatIconClick}
              >
                <IoIosChatboxes color="white" size="28px" />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Meeting;
