import { useMutation, useQueryClient } from "react-query";
import reportServices from "../../service/reportServices";
import { toast } from "react-toastify";

const useAddComment = () => {
  const queryClient = useQueryClient();

  const submitComment = useMutation(
    (comment) => {
      return reportServices.submitCommentCommon(comment);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("allReports");
        // toast.success(data.message);
      },
    }
  );

  const editCommentCommonMutation = useMutation(
    reportServices.editCommentCommon,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("allReports");
        // toast.success(data.message);
      },
    }
  );

  const deleteCommentCommanMutation = useMutation(
    reportServices.deleteCommentCommon,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("allReports");
      },
    }
  );

  return {
    submitComment,
    editCommentCommonMutation,
    deleteCommentCommanMutation,
  };
};

export default useAddComment;
