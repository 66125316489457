import { useQuery } from "react-query";
import { taskServices } from "../../service/taskServices";

const useProjectTaskReview = (projectId, page, limit) => {
  const fetchTasks = async () => {
    const response = await taskServices.getProjectUnderReview(
      projectId,
      page,
      limit
    );
    return response;
  };

  return useQuery(["projectReview"], fetchTasks);
};

export { useProjectTaskReview };
