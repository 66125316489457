import LogoutIcon from "@mui/icons-material/Logout";
import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { IoNotificationsCircleSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import useUserHook from "../../hooks/userHook";
import "./navbar.scss";
import useSidebarStore from "../../store/sidebarStore";
import moment from "moment";

const Navbar = () => {
  const { userData } = useUserHook();
  const navigate=useNavigate()

  const user = userData?.result;

  // const [usernotification, usersetNotification] = useState([]);
  // const getAllNotifications = async () => {
  //   try {
  //     const response = await axios.get(baseUrlUser + "/getAllNotification");
  //     usersetNotification(response.data.result);

  //     await axios.put(baseUrlUser + "/markRead", {});
  //   } catch (error) {
  //   }
  // };

  // React.useEffect(() => {
  //   getAllNotifications();
  // }, []);

  const [notification, setnotification] = React.useState(null);
  const openNotification = Boolean(notification);
  const handleClickNotification = (event) => {
    setnotification(event.currentTarget);
  };
  
  const handleCloseNotification = () => {

    setnotification(null);
    navigate('/userNotification')
  };
  const{responsibilitySidebar}=useSidebarStore()
  const [anchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const path = window.location.pathname;
  const [currentResponsibilities,setCurrentResponsibilities]=useState([])
  const formatPath = (path) => {
    let formattedPath = path.replace(/([A-Z])/g, " $1"); // Add space before capital letters
    formattedPath =
      formattedPath.charAt(0).toUpperCase() + formattedPath.slice(1); // Capitalize first letter
    return formattedPath.toUpperCase();
  };
  const currentHour = new Date().getHours();
  const getGreeting = () => {
    if (currentHour < 5) return "Good night";
    if (currentHour < 12) return "Good morning";
    if (currentHour < 17) return "Good afternoon";
    if (currentHour < 21) return "Good evening";
    return "Good night";
  };
  const formatTime = (date) => {
    return moment().format('HH:mm');
  };
    React.useEffect(()=>{
if(responsibilitySidebar){
 const current= responsibilitySidebar.find((item)=>item.path===path)
 setCurrentResponsibilities([current])
}
    },[path,responsibilitySidebar])
  const formattedPath = formatPath(path.substring(1));
  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="wrapperInner">
        {formattedPath !=='USERPROFILE'  ?<h3 className=" text-2xl font-semibold my-2 text-black">{formattedPath}</h3>:<>
        <span className=" text-2xl font-semibold my-2 text-black">Hi {user?.name},{getGreeting()} !</span>         <span className="ml-2 text-xl text-black">Current time: {formatTime(currentHour)}</span>
        </>
         }
{currentResponsibilities?.[0]?.givenResponsibilities?.map((item)=><div className="text-black mx-[10px]">{item}</div>)}
        </div>
        <div className="items">
          <div className="item">
            <IoNotificationsCircleSharp
              style={{
                fontSize: "40px",
              }}
              // className="icon"
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClickNotification}
            />
          {user?.unreadNotification?.length>0?<div className="counter" onClick={()=>navigate('/userNotification')}>{user?.unreadNotification?.length}</div>:''}  

         { user?.unreadNotification?.length>0 &&     <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={notification}
              open={openNotification}
              onClose={handleCloseNotification}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              style={{ position: "absolute", top: "28px",backgroundColor:'black' }}
            >
              {user?.unreadNotification?.map((item) => {
                return (
                  <div className="mainDiv">
                    <MenuItem
                      className="modalNotify"
                      onClick={handleCloseNotification}
                    >
                      <div className="mainNoti">
                        <div className="title">
                          <h3>{item?.title}</h3>
                        </div>
                        <div title={item?.body} className="body">
                          <span>{item?.body?.substring(0, 40) + "..."}</span>
                        </div>
                      </div>
                    </MenuItem>
                  </div>
                );
              })}
            </Menu>}
          </div>

          <div className="item">
            {/* <Avatar
              src="https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
              alt=""
              className="avatar"
              onClick={handleProfileClick}
              onMouseEnter={() => setDropdownOpen(true)}
            />
            {isDropdownOpen && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",

                  position: "absolute",
                  top: "100%",
                  right: 0,
                  backgroundColor: "#fff",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  borderRadius: "4px",
                  minWidth: "150px",
                  zIndex: 1000,
                  color: "#000",
                  fontFamily: "Arial, sans-serif",
                }}
              >
                <ul className="links">
                  <Link to="/userprofile" className="link">
                    Profile
                  </Link>
                  <Link className="link">Edit Profile</Link>
                  <Link className="link">Change Password</Link>
                </ul>
              </div>
            )} */}

            <AccountMenu name={user?.name} user={user} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

const AccountMenu = ({ name,user }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  

  return (
    <>
      <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
       {user?.image?
       <img src={user?.image} style={{ width: 32, height: 32,borderRadius:'50%' }} />:
       <Avatar sx={{ width: 32, height: 32 }}>{name?.charAt(0)}</Avatar>
      }
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem component={Link} to="/userprofile">
          <Avatar >{name?.charAt(0)}</Avatar> Profile
        </MenuItem>

        <Divider />

        {/* <MenuItem component={Link} to="/logout">
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem> */}
      </Menu>
    </>
  );
};
