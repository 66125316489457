import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCGqIjh4iPYnKdSC3vOMO99s67nMnzSSuM",
  authDomain: "office-management-frontend.firebaseapp.com",
  projectId: "office-management-frontend",
  storageBucket: "office-management-frontend.appspot.com",
  messagingSenderId: "203395756867",
  appId: "1:203395756867:web:3f9e63cf9b6f7f515db5f6",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
export const findtoken = (setTokenFound) => {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      getToken(messaging, {
        vapidKey:
          "BGQkt8qPqYQgWyeRV-yRFeLqiJ1bZPuUaeOVofY538bRNfxlXQTWdqK37-Hvbj5JX1e5I3TuSqUIX_CSGYg37EA",
      })
        .then((currentToken) => {
          if (currentToken) {
            localStorage.setItem("fcmToken", currentToken);
            setTokenFound(true);
          } else {
           
          }
        })
        .catch((err) => {
        });
    } else {
    }
  });
};


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
