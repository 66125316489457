import React, { useState } from "react";
// import CalendarForSheet from "../DetailedTimesheet/CalendarForSheet";
import useEmployeeStore from "../../store/employeeStore";
import Calendar from "./../../components/calender/Calender";
import CalendarForProjectTimeLine from "./CalendarForProjectTImeline";

const transformEmployeeDataForCalendar = (employeeData) => {
  const transformedData = [];

  employeeData.projects.forEach((project) => {
    const { projectId, start_date, end_date, projectDescription, status } =
      project;

    transformedData.push({
      startDate: start_date?.split("T")[0],
      endDate: end_date?.split("T")[0],
      description: `${status}`,
      id: projectId?._id,
    });
  });

  return transformedData;
};

const getAllProjectsData = (employeeList) => {
  const allProjectsData = [];

  employeeList.forEach((employee) => {
    const employeeColor = employee.colorCode;

    employee.projects.forEach((project) => {
      const { projectId, start_date, end_date, status } = project;

      allProjectsData.push({
        startDate: start_date?.split("T")[0],
        endDate: end_date?.split("T")[0],
        description: ` ${projectId?.projectName} - ${status}`,
        id: projectId?._id,
        color: employeeColor,
      });
    });
  });

  return allProjectsData;
};

function ProjectCalender() {
  const employeeList = useEmployeeStore((state) => state.employees);

  const allProjectsData = getAllProjectsData(employeeList);

  return (
    <>
      <div style={{ display: "flex" }}>
        <div>
          <CalendarForProjectTimeLine transformedData={allProjectsData} />
        </div>

        <div
          style={{
            marginLeft: "20px",
            maxHeight: "70vh",
            overflow: "auto",
            background: "white",
            padding: "2rem",
            borderRadius: "30px",
          }}
        >
          <h2>Employee List</h2>
          <ul style={{ listStyle: "none", padding: 0 }}>
            {employeeList?.map((employee) => (
              <li
                key={employee._id}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                  marginBottom: "5px",
                  minWidth: "max-content",
                  borderRadius: "5px",
                  // color: "#fff", // Set text color to white for better visibility
                }}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: employee.colorCode,
                    marginRight: "10px",
                  }}
                />
                {employee.name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default ProjectCalender;
