import { useQuery } from "react-query";
import { taskServices } from "../../service/taskServices";
// import { taskServices } from "../../service/taskServices";

const useGraphHook = (id) => {
  const fetchGraphData = async () => {
    const response = await taskServices.getGraphData(id);
    return response;
  };

  const {
    data: graph,
    isLoading: isGraphLoading,
    isError: isGraphError,
  } = useQuery(["graph"], fetchGraphData);

  const fetchPieData = async () => {
    try {
      const response = await taskServices.getPieData();
      return response;
    } catch (error) {
      console.error("useGraphHook error:", error);
      throw new Error(error);
    }
  };

  const {
    data: pie,
    isLoading: isPieLoading,
    isError: isPieError,
  } = useQuery(["pie"], fetchPieData);

  return {
    graph,
    isGraphLoading,
    isGraphError,
    pie,
    isPieLoading,
    isPieError,
  };
};

export default useGraphHook;
