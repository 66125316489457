import Mic from '../../assets/CallActionImages/Mic.png';
import Mobile from '../../assets/CallActionImages/Mobile.png';
import muteMic from '../../assets/CallActionImages/MuteMic.png';
import MuteVideo from '../../assets/CallActionImages/MuteVideo.png';
import Video from '../../assets/CallActionImages/Video.png';
import ScreenToggle from '../../assets/CallActionImages/ScreenToggle.png';
import speaker from '../../assets/CallActionImages/SpeakerOnLight.png';

export const enabledMic =Mic;
export const endcallButton=Mobile;
export const disableMic=muteMic
export const enabledVideo =Video;
export const enterFullScreen=ScreenToggle;
export const disableVideo=MuteVideo;
export const enableSpeaker=speaker;
