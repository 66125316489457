import EditNoteIcon from "@mui/icons-material/EditNote";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import React, { useMemo, useState } from "react";
import useReportHook from "../../hooks/ReportHook";
import useUserStore from "../../store/userStore";
import useUserProjectStore from "./../../store/userProject";
import { AddReportUser } from "./AddReportUser";
import { EditReportModalUser } from "./EditReportModalUser";
import { UserAccessReport } from "./UserAccessReport";
import { DataTable } from "../../../PageResponsibility/src/admin/components/table/Data-Table";
import { columns } from "./columns";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../PageResponsibility/src/admin/components/ui/button";

export function UserReport() {
  const { data, editReportUserMutation } = useReportHook();
  const [userAddReportModalIsOpen, setUserAddReportModalIsOpen] =
    useState(false);
  const [editReport, setEditReport] = useState({});
  const [editReportModalIsOpen, setEditReportModalIsOpen] = useState(false);
const navigate=useNavigate()
  const openAddReportModalUser = () => {
    setUserAddReportModalIsOpen(true);
  };
  const { user } = useUserStore();
  const {projects}=useUserProjectStore()

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(6);

  const totalPages = Math.ceil(data?.length / pageSize);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const openEditReportModal = (report) => {
    setEditReport(report);
    setEditReportModalIsOpen(true);
  };

  const closeAddReportModalUser = () => {
    setUserAddReportModalIsOpen(false);
  };
  const closeEditReportModal = () => {
    setEditReportModalIsOpen(false);
  };

  // const renderUserReports = () => {
  //   const currentDate = new Date();
  //   const startIndex = (currentPage - 1) * pageSize;
  //   const endIndex = startIndex + pageSize;
  //   const teamToDisplay = data?.slice(startIndex, endIndex);

  //   return teamToDisplay?.map((report, index) => {
  //     const serialNumber = startIndex + index + 1;

  //     return (
  //       <tr key={report?._id}>
  //         <td>{serialNumber}</td>
  //         {/* <td>{report?.project?.projectName}</td> */}
  //         <td>{report?.date.substring(0, 10)}</td>
  //         <td>{report?.reports?.[0]?.report?  <button
  //               onClick={() => navigate('/viewReport',{state:{
  //                 data:report?.reports
  //               }})}
  //               style={{
  //                 width: "100px",
  //                 padding: "0",
  //                 fontSize: "11px",
  //               }}
  //               className="button-17"
  //             >
  //               View Report
  //             </button>:'No-reports'}</td>
        
  //         {report?.createdAt.substring(0, 10) ===
  //         currentDate?.toISOString()?.substring(0, 10) ? (
  //           <td>
  //             {report?.reports?.[0]?.report?
  //             <button
  //             onClick={() => navigate('/editReport',{state:{
  //               data:report?.reports,
  //               id:report?._id
  //             }})}
               
  //               style={{
  //                 width: "100px",
  //                 padding: "0",
  //                 fontSize: "11px",
  //               }}
  //               className="button-17"
  //             >
  //               Edit
  //             </button>:"Can't Edit"
  //             }
  //           </td>
  //         ) : (
  //           <td style={{ color: "red" }}>Cannot Edit</td>
  //         )}
  //       </tr>
  //     );
  //   });
  // };
  const usercolumns=useMemo(()=>[
   
    {header:'S.No',
      cell:({row})=>row.index+1
    },
    {header:'Date',
      cell:({row})=>row.original?.date.substring(0, 10)
    },
    {header:'Report',
      cell:({row})=>row.original?.reports?.[0]?.report?  <button
      onClick={() => navigate('/viewReport',{state:{
        data:row?.original?.reports
      }})}
      style={{
        width: "100px",
        padding: "0",
        fontSize: "11px",
      }}
      className="button-17"
    >
      View Report
    </button>:'No-reports'
    },
    {header:'Edit',
      cell:({row})=>row?.original?.reports?.[0]?.report?
        <button
        onClick={() => navigate('/editReport',{state:{
          data:row?.original?.reports,
          id:row?.original?._id
        }})}
         
          style={{
            width: "100px",
            padding: "0",
            fontSize: "11px",
          }}
          className="button-17"
        >
          Edit
        </button>:"No report to edit"
        }
    
  ],[projects])
  return (
    <>
    <div>Add Report <EditNoteIcon
                onClick={()=>navigate('/addReport')}
                style={{
                  cursor: "pointer",
                  color: "#085394",
                  fontSize: "18px",
                }}
              /></div>
      <div>
        <DataTable data={data||[]} columns={usercolumns} heading={'Recent Reports'} defaultTheme={true}/>
         {/* <section className="user-report">
          <div
            className="table-container"
            style={{
              padding: "30px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "2px solid gray",
                marginBottom: "10px",
                alignItems: "center",
              }}
            >
              <h5
                style={{
                  fontWeight: "bold",
                }}
              >
               Recent Reports
              </h5>
              
            </div>
            <table>
              <thead
                style={{
                  color: "#c0c3c7",
                  fontWeight: "bold",
                }}
              >
                <tr
                  style={{
                    backgroundColor: "#f1f4f7",
                    fontWeight: "bold",
                  }}
                >
                  <th>S.No.</th>
                  <th>Project Name</th>
                  <th>Date</th>
                  <th>Report</th>

                  <th>Task Completed</th>
                  <th>Status</th>
                  <th>Edit </th>
                </tr>
              </thead>
              <tbody className="tableBody">
                {data != "" ? (
                  renderUserReports()
                ) : (
                  <tr>
                    <td colSpan="7">No Reports to show</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="pagination-container">
            <div
              className={`pagination-button ${
                currentPage === 1 ? "pagination-button--disabled" : ""
              }`}
              onClick={handlePreviousPage}
            >
              <KeyboardArrowLeftIcon /> Previous
            </div>
            <div
              className={`pagination-button ${
                currentPage === totalPages ? "pagination-button--disabled" : ""
              }`}
              onClick={handleNextPage}
            >
              Next <KeyboardArrowRightIcon />
            </div>
          </div>
        </section>  */}
        {/* {props.loading && <Loading />} */}
        <DataTable data={projects} columns={columns} heading='Project Wise Reports 'defaultTheme={true}/>

        <UserAccessReport rights={user.rights} />
      </div>
      
    </>
  );
}
