import moment from "moment";
import { EditStatusModal } from "../../../PageResponsibility/src/admin/components/modals/EditStatus";
import { Edit } from "lucide-react";
import {AddCommentModal} from '../../components/modals/AddCommentModal';
import { CommentDialog } from "../../components/modals/CommentDialog";
import { Link } from "react-router-dom";
const options=[
    {label:'Pending',value:'Pending'},
    {label:'Completed',value:'Completed'},
    {label:'Draft',value:'Draft'},
    {label:'OnGoing',value:'OnGoing'},
  
    {label:'Approved',value:'Approved'},
  
  ]
export const columns = [
    {
      header: "S.No",
      cell: ({ row }) => {
        return <span>{row.index + 1}</span>;
      },
    },
  
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ row }) => {
        const getStatusStyle = (status) => {
          switch (status) {
            case "Completed":
              return { color: "green" ,cursor:'pointer'};
            case "In Progress":
              return { color: "#e3b717",cursor:'pointer' };
            case "Planning":
              return { color: "#f56c27",cursor:'pointer' };
              case"Draft":
              return{color:'blue',cursor:'pointer'}
            default:
              return {};
          }
        };
  
        return (
         <EditStatusModal user={true} trigger={
         <div className="flex items-center justify-center gap-2">
          <span style={getStatusStyle(row.original.status)} >
         {row.original.status}

       </span> 
       <span><Edit size={'12px'} /></span>
          </div>} url={`/updateProjectStatus/${row?.original?._id}`} options={options} id={row?.original?._id} invalidateQuery={'userTotalProjects'}/> 
        );
      },
    },
  
    {
      accessorKey: "projectName",
      header: "Project Name",
    },
    {
      accessorKey: "projectStartDate",
      type: "date",
      header: "Start Date",
      cell: ({ row }) => {
        const startDate = row.getValue("projectStartDate");
        return <span className="text-[green]">{startDate?.substring(0, 10)}</span>
      },
    },
    {
      accessorKey: "projectEndDate",
      type: "date",
      header: "End Date",
      cell: ({ row }) => {
        const endDate = row.getValue("projectEndDate");
        return<span  className="text-[blue]">{ endDate?.substring(0, 10)}</span>;
      },
    },
    {
      accessorKey: "projectDescription",
      header: "Description",
    },
    {
      // accessorKey: "createdAt",
      cell: ({ row }) => moment(row.original.createdAt).format("YYYY-MM-DD"),
      header: "Project Added at",
    },
    {
      header: "Reports",
      cell:({row})=><Link to={`/userReport/${row.original?._id}`} className="button-17 !rounded-[10px]">View Reports</Link>,
    },
    {
      header: "Add Comment",
      cell: ({ row }) => (
        <AddCommentModal
          id={row.original._id}
          model={'report'}
        />
      ),
    },
    {
      header: "Comment",
  
      cell: ({ row }) => {
        return (
          <CommentDialog commonId={row.original._id} model='report' />
        );
      },
    },
  
    // {
    //   header: "Add Employees",
    //   cell: ({ row }) => {
    //     return (
    //       <Link to={`/addEmployee/?projectId=${row.original._id}`}>
    //         <button className="button-17">Add Employees</button>
    //       </Link>
    //     );
    //   },
    // },
  
    // {
    //   accessorKey: "edit",
    //   header: "Edit",
    //   cell: ({ row }) => {
    //     return (
    //       <span className="flex justify-center">
    //         <Link to={`/editProject/${row.original._id}`}>
    //           <RiEditBoxFill className="text-lg" />
    //         </Link>
    //       </span>
    //     );
    //   },
    // },
  
    // {
    //   accessorKey: "details",
    //   header: "Details",
    //   cell: ({ row }) => {
    //     return (
    //       <Link to={`/ProjectDetails/${row.original._id}`}>
    //         <button className="button-17 !rounded-[10px]">Details</button>
    //       </Link>
    //     );
    //   },
    // },
  ];


  export const reportColumns= [
    {
      header: "S.No",
      cell: ({ row }) => {
        return <span>{row.index + 1}</span>;
      },
    },
    {
      accessorKey: "taskAssigned",
      header: "Task Assigned",
    },
 
    {
      accessorKey: "status",
     
      header: "Status",
     
    },
    {
      accessorKey: "projectDescription",
      header: "Description",
    },
    {
      // accessorKey: "createdAt",
      cell: ({ row }) => moment(row.original.createdAt).format("YYYY-MM-DD"),
      header: "Project Added at",
    },
    {
      header: "Reports",
      cell:({row})=><Link to={`/userReport/${row.original?._id}`} className="button-17 !rounded-[10px]">View Reports</Link>,
    },
    {
      header: "Add Comment",
      cell: ({ row }) => (
        <AddCommentModal
          id={row.original._id}
          model={'report'}
        />
      ),
    },
    {
      header: "Comment",
  
      cell: ({ row }) => {
        return (
          <CommentDialog id={row.original._id} model='report' />
        );
      },
    },
  
    // {
    //   header: "Add Employees",
    //   cell: ({ row }) => {
    //     return (
    //       <Link to={`/addEmployee/?projectId=${row.original._id}`}>
    //         <button className="button-17">Add Employees</button>
    //       </Link>
    //     );
    //   },
    // },
  
    // {
    //   accessorKey: "edit",
    //   header: "Edit",
    //   cell: ({ row }) => {
    //     return (
    //       <span className="flex justify-center">
    //         <Link to={`/editProject/${row.original._id}`}>
    //           <RiEditBoxFill className="text-lg" />
    //         </Link>
    //       </span>
    //     );
    //   },
    // },
  
    // {
    //   accessorKey: "details",
    //   header: "Details",
    //   cell: ({ row }) => {
    //     return (
    //       <Link to={`/ProjectDetails/${row.original._id}`}>
    //         <button className="button-17 !rounded-[10px]">Details</button>
    //       </Link>
    //     );
    //   },
    // },
  ];