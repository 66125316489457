import axios from "../config/axiosAuth";
import { baseUrl } from "../config/baseUrl";

const projectServices = {
  getAllProjects: async (term) => {
    let finalurl;
    if (term === "" || term === null) {
      finalurl = `${baseUrl}/allProjects`;
    } else if (term !== "" || term !== null) {
      finalurl = `${baseUrl}/allProjects?status=${term}`;
    } else {
      finalurl = `${baseUrl}/allProjects`;
    }
    try {
      const response = await axios.get(finalurl);
      return response.data.result;
    } catch (error) {
      throw new Error(error);
    }
  },
  getTasks: async () => {
    try {
      const response = await axios.get(baseUrl + "/taskManagement", {});
      return response.data.tasks;
    } catch (error) {
      throw new Error(error);
    }
  },
  getDefaultModules: async () => {
    try {
      const response = await axios.get(`${baseUrl}/getModules`);
      return response.data.result;
    } catch (error) {
      throw new Error(error);
    }
  },
  getPendingProjects: async () => {
    try {
      const response = await axios.get(`${baseUrl}/pendingProject`);
      return response.data.result;
    } catch (error) {
      throw new Error(error);
    }
  },
  updateTeam: async (data) => {
    try {
      const response = await axios.put(baseUrl + "/update-project-team", data);
      return response.data;
    } catch (err) {
      throw new Error(err);
    }
  },

  getCompletedProjects: async () => {
    const response = await axios.get(baseUrl + "/completedProject");
    return response.data.result;
  },

  getOneProject: async (id) => {
    const res = await axios.get(`${baseUrl}/getProjectById/${id}`);
    return res.data;
  },

  addEmployeesInProject: async (data) => {
    const res = await axios.put(`${baseUrl}/addEmployeeInProject`, data);
    return res.data.result;
  },

  getReportForProject: async (id) => {
    const res = await axios.post(`${baseUrl}/getProjectReport`, {
      id: id,
    });
    return res.data.result;
  },

  editProject: async (id, data) => {
    const res = await axios.put(`${baseUrl}/updateProjectStatus/${id}`, data);
    return res.data.result;
  },

  deleteProject: async (id) => {
    const res = await axios.delete(`${baseUrl}/deleteProject/${id}`);
    return res.data.result;
  },

  addProject: async (data) => {
    const res = await axios.post(`${baseUrl}/addProject`, data);
    return res.data.result;
  },
  getProjectSummary: async (data) => {
    const plainUrl = baseUrl + "/projectSummary";
    const QueryUrl =
      baseUrl +
      `/projectSummary?status=${data.status}&projectManager=${data.projectManager}&projectName=${data.project}`;

    const response = await axios.get(
      data.status  &&
        data.projectManager  &&
        data.projectName 
        ? plainUrl
        : QueryUrl
    );
    return response;
  },
};

export { projectServices };
