import { useMutation, useQuery, useQueryClient } from "react-query";
import taskServices from "../../services/TaskServices";

const useTaskMangementHook = (id) => {
  const fetchTasks = async () => {
    const data = await taskServices.getTask(id);
    return data.result;
  };

  const { data: tasks, isLoading, isError } = useQuery("tasks", fetchTasks);

  return {
    tasks,
    isLoading,
    isError,
  };
};

export default useTaskMangementHook;
