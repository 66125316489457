import { baseUrl, baseUrlUser } from "../../config/baseUrl";
import axios from '../../../PageResponsibility/src/admin/config/axiosAuth'

const attendanceServices = {
  getAttendance: async (data) => {
    const  response  = await axios.get(`${baseUrlUser}/attendance?endDate=${data.endDate}&&startDate=${data.startDate}&&month=${data.month}`, {});
    return response.data.result;
  },
};

export default attendanceServices;
