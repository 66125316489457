import axios from "../config/axiosAuth";
import { baseUrl } from "../config/baseUrl";

const leaveServices = {
  getAllLeaves: async () => {
    const response = await axios.get(baseUrl + "/getAllLeaves");
    return response.data.result;
  },

  getAllApprovedLeaves: async () => {
    const response = await axios.get(baseUrl + "/getAllLeaves", {
      params: {
        status: "approved",
      },
    });
    return response.data.result;
  },
  getNonRejectedLeaves:async()=>{
    const response = await axios.get(baseUrl + "/getAllLeaves",{
      params:{
        status:["approved","pending"]
      }
    });
    return response.data.result;
  },

  handleStatusChange: async (id, status) => {
    const response = await axios.put(baseUrl + "/updateLeaveStatus/" + id, {
      status: status,
    });
    return response;
  },
};

export default leaveServices;
