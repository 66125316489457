import { useMutation, useQueryClient } from "react-query";
import { taskServices } from "../../service/taskServices";

const useTaskMangementEditHook = () => {
  const queryClient = useQueryClient();

  const editTaskMutation = useMutation((data) => taskServices.editTask(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["tasks"]);
      queryClient.invalidateQueries(["pie"]);
    },
  });

  return {
    editTaskMutation,
  };
};

export default useTaskMangementEditHook;
