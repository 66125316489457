import React, { useEffect } from "react";
import "./UserProfile.css"; // Import your custom CSS file for styling
// import { AdminSIdeBar } from "../../components/sidebar/AdminSIdeBar";task
import axios from "../../config/axiosAuth";
import { baseUrl } from "../../config/baseUrl";

const Profile = () => {
  const handleProfiledetails = () => {
axios.get(baseUrl+ "/getMyProfile" , {

})

.then((res) => {
})

.catch((err) =>{
})
  };

  useEffect(() => {
    handleProfiledetails();
  },[])

  return (
    <div className="container custom-container rounded bg-white mt-5 mb-5">
      <div
        style={{
          display: "flex",
        }}
      >
        <div className="col-md-3 custom-border-right">
          <div className="d-flex flex-column align-items-center text-center p-3 py-5">
            <img
              className="custom-rounded-circle mt-5"
              width="150px"
              src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
              alt="Profile"
            />
            <span className="custom-font-weight">Edogaru</span>
            <span className="custom-text-muted">edogaru@mail.com.my</span>
          </div>
        </div>
        <div className="col-md-5 custom-border-right">
          <div className="p-3 py-5">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4 className="custom-text-right">Profile Settings</h4>
            </div>
            <div className="row mt-2">
              <div className="col-md-6">
                <label className="custom-label">Name</label>
                <input
                  type="text"
                  className="custom-input"
                  placeholder="First Name"
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <label className="custom-label">Email ID</label>
                <input
                  type="text"
                  className="custom-input"
                  placeholder="Enter email ID"
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <label className="custom-label">Country</label>
                <input
                  type="text"
                  className="custom-input"
                  placeholder="Country"
                />
              </div>
            </div>
            <div className="mt-5 text-center">
              <button className="custom-btn custom-primary" type="button">
                Save Profile
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="p-3 py-5">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h4 className="custom-text-right">Profile Settings</h4>
            </div>
            <div className="row mt-2">
              <div className="col-md-6">
                <label className="custom-label">Name</label>
                <input
                  type="text"
                  className="custom-input"
                  placeholder="First Name"
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-6">
                <label className="custom-label">Country</label>
                <input
                  type="text"
                  className="custom-input"
                  placeholder="Country"
                />
              </div>
            </div>
            <div className="mt-5 text-center">
              <button className="custom-btn custom-primary" type="button">
                Save Profile
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
