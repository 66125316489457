import axios from '../../../PageResponsibility/src/admin/config/axiosAuth'
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "./login.css";
import authService from "./../../services/authServicesUser";
import { Button } from '../../../PageResponsibility/src/admin/components/ui/button';

const ForgotPassUser = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [employeeId, setEmployeeId] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOtpAndNewPasswordInputs, setShowOtpAndNewPasswordInputs] =
    useState(false);

  const handleEmployeeId = (e) => {
    setEmployeeId(e.target.value);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  
  const sendRequest = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when the API call is started.
    try {
      const response = await authService.sendRequest(employeeId);
      setShowOtpAndNewPasswordInputs(true);
    } catch (error) {
      console.error("Error sending OTP", error);
      toast.error("Error sending OTP");
    } finally {
      setLoading(false); // Set loading to false when the API call is finished (success or error).
    }
  };


  const resetPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    setLoading(true); // Set loading to true when the API call is started.
    try {
      const response = await authService.resetPasswordUser(
        employeeId,
        otp,
        newPassword
      );
      // toast.success("Password reset successfully");
      navigate("/");
    } catch (error) {
      console.error("Error resetting password", error);
      // toast.error("Error resetting password");
    } finally {
      setLoading(false); // Set loading to false when the API call is finished (success or error).
    }
  };

  return (
    <div className="login-container">
      <div className="login-bg">
      {/* <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

        }}
        className='shadow-2xl'
      > */}
      <div className="login-top">
          <div>User</div>
        </div>
            <form className="login-form">
        <div style={{ width: "100%", height: "auto"  }} className="forgetForm form-container h-[80%] bg-white shadow-xl rounded-[5px]">
          
            <div className="forgetTitle font-bold text-2xl mb-[10px]">Reset Password

            </div>

            <div className="forgettext text-[14px]">
              Please enter your Employee Id and we'll send you an email with new Credentials
            </div>
              <div className="mb-3 mt-3">
                {/* <label>Employee ID</label> */}
                <input
                  type="text"
                  placeholder="Enter Employee ID"
                  value={employeeId}
                  onChange={handleEmployeeId}
                />
              </div>
              {/* {showOtpAndNewPasswordInputs && (
                <>
                  <div className="mb-3">
                    <label>OTP</label>
                    <input
                      type="text"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={handleOtpChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label>New Password</label>
                    <input
                      type="password"
                      placeholder="Enter new password"
                      value={newPassword}
                      onChange={handleNewPasswordChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label>Confirm Password</label>
                    <input
                      type="password"
                      placeholder="Confirm password"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                  </div>
                </>
              )} */}

              <ToastContainer />
              {/* </div> */}
      </div>
              <Button
                type="submit"
                className={`forgetButton ${
                  loading ? "btn-loading" : ""
                } btn-success`}
                onClick={sendRequest}
                disabled={loading}
              >
                {loading
                  ? "Loading..."
                 
                  : "Send Request"}
              </Button>
            <p className="logintext text-[blue] underline" onClick={() => navigate("/")}>
              Login here
            </p>
            </form>

            {/* <hr /> */}
    </div>
    </div>
          
       
    // </div>
  );
};

export default ForgotPassUser;
