// OfficeCalendar.js
import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./newcalender.css"; // Import the custom CSS file
import CalenderCategory from "./calenderCategory";
import axios from "../../config/axiosAuth";
import { baseUrl } from "../../config/baseUrl";
import EventTable from "../table/EventTable";
import { LeaveTable } from "../table/LeaveTable";
import BirthdayTable from "../table/BirthdayTable";
import ProjectTable from "../table/Project-Table";
import Meeting from "../../Meeting/page";
import { hexToRGBA } from "../../../utils/lib";
import useEventHook from "../../hooks/eventsHooks";
import useEventStore from "../../store/eventStore";
import HolidayTable from "../table/HolidayTable";
import useHolidayStore from "../../store/holidayStore";
import { DataTable } from "../table/Data-Table";
import moment from "moment";
import { DescriptionModal } from "../modals/DiscriptionModal";

const OfficeCalendar = () => {
  const [activeCategory, setActiveCategory] = useState("all");
  const [allEvents, setAllEvents] = useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const { setOtherEvents, setProjectEvents, setHolidays } = useEventStore();
  const [eventClicked, setEventClicked] = useState(false);
  const [clickedEventData, setClickedEventData] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [reload, setReload] = useState("");
  const toggleModal = () => {
    if (
      activeCategory === "meetings" ||
      activeCategory === "holidays" ||
      activeCategory === "event"
    ) {
      setShowModal(!showModal);
    }
  };
  const handleGetmeeting = () => {
    axios
      .get(baseUrl + "/allMeetings")

      .then((res) => {
        setMeetings(res.data);
      })

      .catch((err) => {});
  };

  const handleallevents = () => {
    axios
      .get(baseUrl + "/allEvent")
      .then((res) => {
        let otherEvents = res.data.filter((data) => {
          const category = data.category?.toLowerCase();
          return category === "event";
        });
        let projectevents = res.data.filter((data) => {
          const category = data.category?.toLowerCase();
          return category === "projects";
        });

        let holidayEvents = res.data.filter((data) => {
          const category = data.category?.toLowerCase();
          return category === "holidays";
        });

        setProjectEvents(projectevents);
        setOtherEvents(otherEvents);
        setHolidays(holidayEvents);
        let mapped = res.data.map((data) => {
          return {
            allDay: data?.allDay === true ? true : false,
            hasEnd: data?.end ? true : false,
            title: data.title,
            // start: data?.start?.split("T")[0] || data?.date?.split("T")[0],
            start: data?.start || data?.date,
            end: data?.end,
            className: `event-${
              data.type === "Meeting" ? "meeting" : "birthday"
            }`,
            description: data.description,
            category: data.category,
            backgroundColor:
              data.category === "meetings"
                ? hexToRGBA("#000080", 0.4)
                : data.category === "holidays"
                ? hexToRGBA("#008000", 0.4)
                : data.category === "leaves"
                ? hexToRGBA("#FF0000", 0.4)
                : data.category === "birthday"
                ? hexToRGBA("#720057", 0.4)
                : data.category === "projects"
                ? hexToRGBA("a57548", 0.4)
                : hexToRGBA("5296a5", 0.4),
            textColor: "white",
            borderColor:
              data.category === "meetings"
                ? hexToRGBA("#000080", 0.4)
                : data.category === "holidays"
                ? hexToRGBA("#008000", 0.4)
                : data.category === "leaves"
                ? hexToRGBA("#FF0000", 0.4)
                : data.category === "birthday"
                ? hexToRGBA("#720057", 0.4)
                : data.category === "projects"
                ? hexToRGBA("a57548", 0.4)
                : hexToRGBA("5296a5", 0.4),
          };
        });
        setAllEvents(mapped);
      })

      .catch((err) => {});
  };

  useEffect(() => {
    handleGetmeeting();
    handleallevents();
  }, [reload]);

  const handle = (e) => {
    setClickedEventData([e.event]);
    setEventClicked(true);
  };
  const handleDateClick = (e) => {
    if (
      activeCategory === "meetings" ||
      activeCategory === "holidays" ||
      activeCategory === "event"
    ) {
      setShowModal(!showModal);
    }
  };

  const renderTable = () => {
    switch (activeCategory) {
      case "event":
        return <EventTable />;
      case "leaves":
        return <LeaveTable />;
      case "birthday":
        return <BirthdayTable />;
      case "Projects":
        return <ProjectTable />;
      case "meetings":
        return <Meeting meetings={meetings} />;
      case "holidays":
        return <HolidayTable />;
      default:
        return null;
    }
  };

  const filteredEvents =
    activeCategory === "all"
      ? allEvents
      : allEvents.filter(
          (event) =>
            event?.category?.toLowerCase() === activeCategory?.toLowerCase()
        );
  useEffect(() => {}, [filteredEvents]);
  const ClickedEventColumns = [
    {
      header: "S.No",
      cell: ({ row }) =>  row.index + 1,
    },
    {
      header: "Event",
      cell: ({ row }) =>  row?.original?.title,
    },
    {
      header: "Description",
      cell:({row})=>row?.original?._def?.extendedProps?.description.length>14? <DescriptionModal trigger={<span className="flex w-[100px] overflow-hidden truncate">{`${row?.original?._def?.extendedProps?.description.substring(0,14)}....`}</span>} data={row?.original?._def?.extendedProps?.description}/>:row?.original?._def?.extendedProps?.description

    },
    {
      header: "Start",

      cell: ({ row }) =>
        moment(row?.original?.start).format("DD-MM-YYYY hh:mm:ss"),
    },
    {
      header: "End",

      // cell: ({ row }) =>
        cell: ({ row }) =>( row?.original?._instance?.range?.end ?  moment(row?.original?._instance?.range?.end).format("DD-MM-YYYY hh:mm:ss"):'-'),

    },
  ];
  return (
    <>
      <div className="w-full flex justify-evenly ">
        <div className="w-[70%] ">
          <FullCalendar
            plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            slotMinTime="00:00:00"
            slotMaxTime="24:00:00"
            events={filteredEvents}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "timeGridWeek,dayGridMonth",
            }}
            slotDuration="01:00:00"
            slotHeight={60}
            allDaySlot={true}
            editable={true}
            height={450}
            droppable={true}
            eventClick={handle}
            dateClick={handleDateClick}
          />

          {eventClicked && (
            <div className="mt-10">
              <DataTable
                columns={ClickedEventColumns}
                data={clickedEventData}
                defaultTheme={true}
                heading="Clicked Event"
                TableHeight={"150px"}
              />
            </div>
          )}
          <div className="mt-5">{renderTable()}</div>
        </div>

        <div className="w-[25%]">
          <CalenderCategory
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
            showModal={showModal}
            toggleModal={toggleModal}
            setReload={setReload}
          />
        </div>
      </div>
    </>
  );
};

export default OfficeCalendar;
