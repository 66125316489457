/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Calender from "../../components/calender/Calender";
import {
  transformProjectData,
  transformProjectDataToToDoList,
} from "../../../utils/transformData";
import Error from "../protectedPages/Error";
import Loading from "../protectedPages/Loading";
import useProjectHook from "../../hooks/useProjectHook";
import { DataTable } from "../../components/table/Data-Table";
import { TesterColumns, column, columns, recentTasksColumns } from "./columns";
import { FolderOpenDot } from "lucide-react";
import { Link } from "react-router-dom";
import Bargraph from "../../pages/projects/Bargraph";
import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import moment from "moment";
import { AddTask } from "./AddTask.jsx";
import "./style.css";
import { AddCommentModal } from "../reports/AddCommentModal.js";
import ProgressBar from "react-customizable-progressbar";
import PercentageOverTime from "./PercentageOverTime.jsx";
import useEmployeeHook from "../../hooks/useEmployeeHook.jsx";
import { hexToRGBA } from "../../../utils/lib.js";
const Project = () => {
  // const [projectSummary, getProjectSummary] = useState([]);
  const { data: employees, isLoading: employeeLoading } = useEmployeeHook();
  // const [task, setTask] = useState([]);
  const [finalurl, setFinalUrl] = useState(baseUrl + "/projectSummary");
  const [filterKeys, setFilterKeys] = useState([
    {
      project: "",
      projectManager: "",
      status: "",
    },
  ]);
  const [projectListfilterKeys, setProjectListFilterKeys] = useState({
    status: null,
  });
  const params = {
    searchTerm: projectListfilterKeys.status,
    status: filterKeys.status,
    projectManager: filterKeys.projectManager,
    projectName: filterKeys.project,
  };
  const { data: projects,projectSummary } = useProjectHook(params);

  // const handleprojectsummary = () => {
  //   const plainUrl = baseUrl + "/projectSummary";
  //   const QueryUrl =
  //     baseUrl +
  //     `/projectSummary?status=${filterKeys.status}&projectManager=${filterKeys.projectManager}&projectName=${filterKeys.project}`;
  //   axios
  //     .get(
  //       filterKeys.project !== "" &&
  //         filterKeys.projectManager !== "" &&
  //         filterKeys.project !== ""
  //         ? QueryUrl
  //         : plainUrl
  //     )

  //     .then((res) => {
  //       getProjectSummary(res.data.projectData);
  //     })

  //     .catch((err) => {
  //     });
  // };
  // useEffect(() => {
  //   handleprojectsummary();
  // }, [filterKeys.status, filterKeys.project, filterKeys.projectManager]);

  // const handletaskManagementTable = async () => {
  //   try {
  //     const response = await axios.get(baseUrl + "/taskManagement", {});
  //     setTask(response.data.tasks);

  //   } catch (err) {
  //   }
  // };

  const { tasks } = useProjectHook();

  useEffect(() => {
    // handletaskManagementTable();
  }, []);

  const { data, isLoading, isError, totalProjects } = useProjectHook(params);

  const Status = [
    "Pending",
    "Completed",
    "Planning",
    "Approved",
    "In Progress",
    "Draft",
    "OnGoing",
  ];
  if (isError) {
    return <Error />;
  }

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {/* <div
        className="project_calendar"
        style={{
          display: "flex",
          gap: "20px",
        }}
      >
        <Calender events={pendingList} />
        <ToDoList project={toDoList} />
      </div> */}

      <div className="flex gap-3 mb-[40px]">
        <>
          <div className=" max-h-[50vh] w-[45%]  bg-white rounded-sm  relative p-[15px]">
            <div className="flex  z-10  min-h-[40px]  pb-[0px] font-semibold text-sm overflow-x-auto">
              <p className="text-md font-bold min-w-[120px] p-[8px]">
                Project Summary
              </p>

              <select
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "10px",
                  marginLeft: "auto",
                  width: "auto",
                  maxWidth: " 75px",
                  border: "none",
                }}
                value={filterKeys.project}
                onChange={(e) =>
                  setFilterKeys((prev) => ({
                    ...prev,
                    project: e.target.value,
                  }))
                }
              >
                <option value="0"> Project</option>
                {projects?.map((project) => (
                  <option key={project._id} value={project.projectName}>
                    {project.projectName}
                  </option>
                ))}
              </select>
              <select
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "10px",
                  marginLeft: "auto",
                  width: "auto",
                  maxWidth: " 150px",
                  border: "none",
                }}
                value={filterKeys.projectManager}
                onChange={(e) =>
                  setFilterKeys((prev) => ({
                    ...prev,
                    projectManager: e.target.value,
                  }))
                }
              >
                <option value="0">Project Manager</option>
                {employees?.map((employee) => (
                  <option key={employee._id} value={employee.name}>
                    {employee.name}
                  </option>
                ))}
              </select>
              <select
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "10px",
                  marginLeft: "auto",
                  width: "auto",
                  maxWidth: " 75px",
                  border: "none",
                }}
                value={filterKeys.status}
                onChange={(e) =>
                  setFilterKeys((prev) => ({
                    ...prev,
                    status: e.target.value,
                  }))
                }
              >
                <option value="0">Status</option>
                {Status?.map((Status) => (
                  <option key={Status} value={Status}>
                    {Status}
                  </option>
                ))}
              </select>
            </div>

            <div
              className="overflow-auto w-full "
              style={{ height: "calc(100% - 40px)" }}
            >
              <div className="project_table">
                <table>
                  <tr
                    style={{
                      borderBottom: "1px solid lightGrey",
                      position: "sticky",
                      top: "-1px",
                      zIndex: 30,
                    }}
                  >
                    <th>Name</th>
                    <th>ProjectManager</th>
                    <th>Due Date</th>
                    <th style={{ zIndex: 10 }}>Status</th>
                    <th style={{ zIndex: 10 }}>Progress</th>
                  </tr>
                  <tbody>
                    {projectSummary && projectSummary?.projectData?.length > 0 ? (
                      projectSummary?.projectData?.map((rowdata, index) => (
                        <tr>
                          <td>{rowdata.projectName}</td>
                          <td>{rowdata.managerTeam?.leaderId?.name}</td>
                          <td>
                            {moment(rowdata.projectSubmitDate).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                          <td
                            style={{
                              backgroundColor: rowdata.color + "00",
                              position: "relative",
                              // width: "3vw",
                              // height: "3vh",
                              width: "60px",
                              borderRadius: "10px",
                              color: rowdata.color,
                            }}
                          >
                            <span
                              style={{
                                paddingInline: "10px",
                                backgroundColor: hexToRGBA(rowdata.color, 0.5),
                                width: "max-content",
                                height: "20px",
                                display: "flex",
                                borderRadius: "10px",
                                left: 0,
                                top: "50%",
                                transform: "translateY(-50%)",
                                position: "absolute",
                              }}
                            >
                              {rowdata.status}
                            </span>
                          </td>

                          <td style={{ padding: "0", margin: "0" }}>
                            <div className="td_progress">
                              <ProgressBar
                                radius={10}
                                progress={rowdata?.progressPercentage}
                                strokeWidth={2}
                                strokeColor={rowdata?.color}
                                strokeLinecap="round"
                                trackStrokeWidth={2}
                                clockwise
                              >
                                <div className="absolute inset-0 flex justify-center items-center text-[5px] font-bold text-gray-[200]">
                                  <p>{rowdata?.progressPercentage}%</p>
                                </div>
                              </ProgressBar>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5">NO DATA FOUND</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>

        <>
          <div className="w-[30%] max-h-[50vh] bg-white rounded-sm p-2 font-bold">
            {/* <h4>All Project Task Percentage</h4> */}
            <Bargraph />
          </div>
        </>

        <>
          <div className="w-[30%] max-h-[50vh] bg-white rounded-sm p-2 font-a pb-[20px]">
            {/* <h4>Percentage Over Time</h4> */}
            <PercentageOverTime />
          </div>
        </>
      </div>

      <div className="flex flex-col gap-[40px] w-full h-max-content">
        <div className="flex flex-col w-full relative">
          {/* <div className="flex justify-end mt-4 ">
            <span>Add Project</span>
            <Link to="/addProject">
              <FolderOpenDot />
            </Link>
          </div> */}
          <select
            style={{
              display: "flex",
              justifyContent: "flex-end",

              marginLeft: "auto",
              minWidth: "150px",
              maxWidth: " 200px",
              border: "none",
              height: "45px",
              paading: "10px",
              backgroundColor: "whitesmoke",
              padding: "5px",
              position: "absolute",
              right: 20,
              top: "10px",
            }}
            value={projectListfilterKeys.status}
            onChange={(e) =>
              setProjectListFilterKeys((prev) => ({
                ...prev,
                status: e.target.value,
              }))
            }
          >
            <option value="">Status</option>
            {Status?.map((Status) => (
              <option key={Status} value={Status}>
                {Status}
              </option>
            ))}
          </select>

          <DataTable
            columns={columns}
            data={data || []}
            defaultTheme={true}
            heading="Projects List"
            TableHeight={"400px"}
          />
        </div>

        <div>
          <div
            style={{
              width: "8vw",
              minWidth: "150px",
              borderRadius: "5px",
              background: "",
              color: "white",
              padding: "5px",
              // maxHeight:'40px',
              marginTop: "1rem",
              marginLeft: "Auto",
            }}
          >
            <AddTask />
          </div>

          <DataTable
            columns={recentTasksColumns}
            data={tasks || []}
            defaultTheme={true}
            heading="Recent tasks"
          />
        </div>
        <>
          {/* <DataTable columns={TesterColumns} data={''} defaultTheme={true} heading=' Recent Tester Sheet'/> */}
        </>
      </div>
    </>
  );
};

export default Project;
