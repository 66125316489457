export const tableStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    backgroundColor: "#fff",
    maxHeight: "80vh",
    overflow: "auto",
  },
};
export const SelectCustomStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: '100px', // Adjust this value as needed
  }),
  container:(provided,state)=>({...provided,zIndex:99}),
  control: (baseStyles, state) => ({
    ...baseStyles,

    height: "40px",
    border: " 1px solid #ccc",
    borderRadius: "5px",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: '80%',

    overflowY: 'auto',
    alignItems: 'flex-start',
    marginTop:'5px'

  }),
  multiValue: (provided, state) => ({
    ...provided,
    maxWidth: '45%',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '100%',
  }),
};
