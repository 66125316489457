import React, { useEffect, useRef } from "react";
import io from "socket.io-client";
import useUserStore from "../../store/userStore";
import useUserHook from "../../hooks/userHook";
import { base } from "../../config/baseUrl";

function App() {
  const { user } = useUserHook();

  const socketRef = useRef();
  const pcRef = useRef();

  useEffect(() => {

    
        },[]);

    
  return <div>DO NOT CLOSE THIS TAB</div>;
}

export default App;
