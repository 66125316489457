import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

export const AddCommentModal = ({ isOpen, closeModal, onSubmit }) => {
  const [comment, setComment] = useState("");

  const handleSubmit = (e) => {
    if (!comment.trim()) {
      alert("Please fill all the fields");
      return;
    }

    e.preventDefault();
    onSubmit(comment);
    setComment("");
    closeModal();
  };

  return (
    <Dialog open={isOpen} onClose={closeModal}>
      <DialogContent>
        <h2>Add Comment</h2>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Comment"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <DialogActions>
            <Button onClick={closeModal} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};
