import { useMutation, useQuery, useQueryClient } from "react-query";
import { hiringServices } from "../service/hiringServices";
import { toast } from "react-toastify";

const useHiringHook = () => {
  const fetchHiring = async () => {
    const res = await hiringServices.getAllHiring();
    return res;
  };

  const queryClient = useQueryClient();
  const { data, isLoading, isError, error } = useQuery("hiring", fetchHiring, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const editHiringMutation = useMutation(
    (data) => {
      return hiringServices.updateHiring(data.id, data.data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("hiring");
      },
    }
  );

  const addHiringMutation = useMutation(
    (data) => {
      return hiringServices.addHiring(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("hiring");
      },
    }
  );

  const deleteHiringMutation = useMutation(
    (id) => {
      return hiringServices.deleteHiring(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("hiring");
      },
    }
  );

  return {
    data,
    isLoading,
    isError,
    error,
    editHiringMutation,
    addHiringMutation,
    deleteHiringMutation,
  };
};

export default useHiringHook;
