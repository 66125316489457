import { create } from "zustand";

// Create a Zustand store
const useHolidayStore = create((set) => ({
  holiday: [],

  setHolidays: (requests) => set({ holiday: requests }),
  addHoliday: (request) =>
    set((state) => ({ holiday: [...state.holiday, request] })),
  updateHoliday: (id, newHoliday) =>
    set((state) => ({
      holiday: state.holiday.map((item) => {
        if (item._id === id) {
          return {
            ...item,
            ...newHoliday,
          };
        }
        return item;
      }),
    })),

  findHoliday: (id) => {
    const holiday = useHolidayStore.getState().holiday;
    return holiday.find((item) => item._id === id);
  },

  removeHoliday: (id) =>
    set((state) => ({
      holiday: state.holiday.filter((item) => item._id !== id),
    })),
  totalHolidays: 0,
  setTotalHolidays: (totalCount) => set({ totalHolidays: totalCount }),
}));

export default useHolidayStore;
