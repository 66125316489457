import ProtectRoute from "./user/pages/protectedPages/ProtectRoute";
import React from "react";
import { Layout } from "./Layout";

// AppContainer.js

export const renderProtectedRoute = (Component) => {
  return (
    <ProtectRoute
      Component={() => (
        <Layout>
          <Component />
        </Layout>
      )}
    />
  );
};
