import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { TableModal } from "./TableModal";
import { AddCommentModal } from "./AddCommentModal";
import { EditReportModalUser } from "./EditReportModalUser";
import useReportHook from "../../hooks/ReportHook";
import Error from "../protectedPages/Error";
import Loading from "../protectedPages/Loading";
import { useNavigate } from "react-router-dom";
import { DataTable } from "../../../PageResponsibility/src/admin/components/table/Data-Table";
import { Button } from "../../../PageResponsibility/src/admin/components/ui/button";

export const UserAccessReport = ({ rights }) => {
  const {
    userAccessReports,
    isUserAccessReportLoadin,
    isUserAccessReportError,
    editReportUserMutation,
    submitCommentCommonMutation,
    accessedUsers
  } = useReportHook();

  const rightFor = (id) => {
    const right = rights?.find((right) => right.employeeId == id);
    return right ? right.accessType : [];
  };
console.log(accessedUsers,'accessedUsers')
  const [isCommentTableModalOpen, setIsCommentTableModalOpen] = useState(false);
  const [selectedComments, setSelectedComments] = useState([]);
  const [adminCommentsSelected, setAdminCommentsSelected] = useState(false);
  const [reportId, setReportId] = useState("");

  const openCommentTable = (comments) => {
    setSelectedComments(comments);
    setIsCommentTableModalOpen(true);
  };

  const closeCommentTable = () => {
    setIsCommentTableModalOpen(false);
  };

  // Pagination
  const pageSize = 3;
  let totalPages = Math.ceil(userAccessReports?.length / pageSize);

  const [currentPage, setCurrentPage] = useState(1);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  //Edit Modal

  const [taskCompleted, setTaskCompleted] = useState("");
  const [status, setStatus] = useState("");
  const [editReport, setEditReport] = useState("");
  const [editReportModalIsOpen, setEditReportModalIsOpen] = useState(false);
const navigate=useNavigate()
  const openEditReportModal = (report) => {
    setEditReport(report);
    setEditReportModalIsOpen(true);
  };

  const closeEditReportModal = () => {
    setEditReportModalIsOpen(false);
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    const reportData = {
      taskCompleted,
      status,
    };

    editReportUserMutation.mutate({ id: editReport._id, data: reportData });

    setEditReportModalIsOpen(false);

    closeEditReportModal();
  };

  //Add Comment User
  const [commentModalIsOpen, setCommentModalIsOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const openCommentModal = (report) => {
    setSelectedReport(report);
    setCommentModalIsOpen(true);
  };

  const closeCommentModal = () => {
    setCommentModalIsOpen(false);
  };

  const handleSubmitComment = async (comment) => {
    const commentData = {
      comment,
      id: selectedReport._id,
    };
    submitCommentCommonMutation.mutate(commentData);
    setCommentModalIsOpen(false);
  };

  if (isUserAccessReportError) {
    return <Error />;
  }
  const columns=[
    {header:'S.No',
      cell:({row})=>row.index+1
    },
    {header:'Name',
      cell:({row})=>row.original?.name
    },
    {header:'Designation',
      cell:({row})=>row.original?.designation?.name
    },
   {header:'View',
      cell:({row})=><Button  className="button-17"onClick={()=>navigate(`/accessReport/${row?.original?._id}`)}>View</Button>
    }
  ]
  const usercolumns=[
   
    {header:'S.No',
      cell:({row})=>(console.log(row,'rowww'))
    },
    {header:'Name',
      cell:({row})=>row.original?.createdBy?.name
    },
    {header:'Designation',
      cell:({row})=>row.original?.createdBy?.designation?.name
    },
   {header:'Status',
      cell:({row})=>row.original?.status
    },{header:'Admin Comment',
      cell:({row})=>row.original?.commentByAdmin?.length === 0 ? (
        <p style={{ color: "red" }}>No Comment</p>
      ) : (
        <button
          onClick={() => {
            setReportId(row?.original?._id);
            openCommentTable(row?.original?.commentByAdmin);
            setAdminCommentsSelected(true);
          }}
          style={{
            width: "100px",
            padding: "0",
            fontSize: "11px",
          }}
          className="button-17"
        >
          View Comment
        </button>
      )
    },
    {header:'Employee Comment',
      cell:({row})=>row.original?.commentByEmployee?.length === 0 ? (
        <p style={{ color: "red" }}>No Comment</p>
      ) : (
        <button
          onClick={() => {
            setReportId(row?.original?._id);
            openCommentTable(row?.original?.commentByEmployee);
            setAdminCommentsSelected(false);
          }}
          style={{
            width: "100px",
            padding: "0",
            fontSize: "11px",
          }}
          className="button-17"
        >
          View Comment
        </button>
      )
    },
    {header:'Date',
      cell:({row})=>row.original?.createdAt.split("T")[0]
    },
    {header:'Add Comment',
      cell:({row})=> {
        const accessType = rightFor(row.original?.createdBy._id);
      return  accessType?.indexOf("comment") >= 0 ? (
        <button
          onClick={() => openCommentModal(row?.original)}
          style={{
            width: "100px",
            padding: "0",
            fontSize: "11px",
          }}
          className="button-17"
        >
          Add Comment
        </button>
      ) : (
        "You Do Not Have Access to Comment"
      )}
    },
  
    {header:'Edit',
      cell:({row})=> {
        const accessType = rightFor(row.original?.createdBy?._id) 
      return  accessType?.indexOf("edit") >= 0 ? 
       row?.original?.reports?.[0]?.report? <button
        onClick={() => navigate('/editReport',{state:{
          data:row?.original?.reports,
          id:row?.original?._id,
          access:true
        }})}
          
          style={{
            width: "100px",
            padding: "0",
            fontSize: "11px",
          }}
          className="button-17"
        >
          Edit
        </button>:'No-report'
      : (
        "You Do Not Have Access to Edit"
      )}}
    
  ]
  return (
    <DataTable columns={columns} data={accessedUsers||[]}defaultTheme={true} heading={'Access Reports'}/>
  
  );
};
