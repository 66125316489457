// import { DeleteIcon } from "lucide-react";
import { CommentDialog } from "../../components/modals/CommentDialog";
import { AddCommentModal } from "../reports/AddCommentModal";
import EditFollowUpModal from "./EditFollowUp";
import moment from "moment";

export const columns = [
  {
    header: "S.No",
    cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },

  {
    accessorKey: "candidateName",
    header: "Candidate Name",
  },
  {
    accessorKey: "email",
    header: "Email",
  },
  {
    accessorKey: "phone",
    header: "Phone",
  },
  {
    accessorKey: "applyFor",
    header: "Apply For",
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      const status = row.getValue("status");
      let color;
      switch (status.toLowerCase()) {
        case 'pending':
          color = '#e0a314';
          break;
        case 'rejected':
          color = 'red';
          break;
        case 'approved':
          color = 'green';
          break;
        default:
          color = 'black';
      }
      return <span style={{ color: color ,fontWeight:"bold"}}>{status}</span>;
    },
  },
  {
    accessorKey: "dateTime",
    cell: ({ row }) => {
      const dateTime = row.getValue("dateTime");
      return moment(dateTime).format("DD-MM-YYYY , LT"); // Adjust format as per your requirement
    },
    header: "Date Time",
  },
  {
    cell: ({ row }) => {
      return <EditFollowUpModal followUp={row.original} />;
    },
    header: "Edit",
  },

  {
    header: "Add Comment",
    cell: ({ row }) => <AddCommentModal id={row.original._id} url={'/addFollowupCommentByAdmin'} />,
  },

  {
    cell: ({ row }) => {
      return <CommentDialog id={row.original} url={'/getFollowupComments'}/>;
    },
    header: "Comments",
  },
];
