import { baseUrl } from "../config/baseUrl";
import axios from "./../config/axiosAuth";

const eventService = {
  getAllBirthdays: async () => {
    const response = await axios.get(baseUrl + "/getAllBirthday");
    return response.data.result;
  },

  getAllHolidays: async () => {
    const response = await axios.get(baseUrl + "/getAllHoliday");
    return response.data.result;
  },

  addHoliday: async (data) => {
    const response = await axios.post(baseUrl + "/addHoliday", data);
    return response.data.result;
  },

  updateHoliday: async (data, id) => {
    const response = await axios.put(baseUrl + "/updateHoliday/" + id, data);
    return response.data.result;
  },

  deleteHoliday: async (id) => {
    const response = await axios.delete(baseUrl + "/deleteHoliday/" + id);
    return response.data.result;
  },

  getAllEvents: async () => {
    const response = await axios.get(baseUrl + "/allEvent");
    return response.data.result;
  },

  addOtherEvent: async (data) => {
    const response = await axios.post(baseUrl + "/addOtherEvent", data);
    return response.data.result;
  },

  updateOtherEvent: async (data, id) => {
    const response = await axios.put(baseUrl + "/updateOtherEvent/" + id, data);
    return response.data.result;
  },

  deleteOtherEvent: async (id) => {
    const response = await axios.delete(baseUrl + "/deleteOtherEvent/" + id);
    return response.data.result;
  },
};

export default eventService;
