import { useQuery } from "react-query";
import {
  getAllTickets,
  getTicketbyProjectId,
} from "../../service/testingServices";

const useGetAllTIckets = (data) => {
  const allTickets = useQuery("allTickets", getAllTickets);

  const { data: ticketsByproject } = useQuery(
    ["getTicketsById", data?.testModule,data?.projectId],
    () => getTicketbyProjectId(data),
    {
      enabled: !!data,
      refetchOnMount: true,

    }
  );
  return {
    allTickets,
    ticketsByproject,
  };
};

export default useGetAllTIckets;
