import React, { useCallback, useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import { base, baseUrl } from "../../config/baseUrl";
import { ZegoExpressEngine } from "zego-express-engine-webrtc";
import { useOutletContext } from "react-router-dom";
import authService from "../../service/authServices";
import { useQuery } from "react-query";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaSquareShareNodes } from "react-icons/fa6";
import {
  Fade,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import axios from "../../config/axiosAuth";
import { toast } from "react-toastify";
import useScreenShareStore from "../../store/ScreenShare";
const Admin = () => {
  const activeConnections = useRef({});
  // const socketRef = useRef();
  // const ZegoRef=useRef();

  const streamIDRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const remoteVideoStream = useRef([]);
  const roomID = useRef(null);
  const myUserId = useRef(null);
  const [socketRef, zegoRef] = useOutletContext();
  const [remoteVideoList, setRemoteVideoList] = useState();
  const [refsSet, setRefsSet] = useState(false);
  const {callStarted,setCallStarted,screenShareRoom,setScreenShareRoom}=useScreenShareStore()

  const [showModalOfVideoActions, setShowModalOfVideoActions] = useState(null);
  
  useEffect(() => {
    setTimeout(() => {
      socketRef.current.emit("adminScreenShareRoomJoin");
      socketRef.current.on("adminJoinedSuccesfully", (data) => {
        roomID.current = data.roomId;
        setScreenShareRoom(data.roomId)
        myUserId.current = data.userId;
        const streamIds = data.streamIds;
        setRemoteVideoList(streamIds);
        startCall();
      });
      socketRef.current.on("recieveRemoteScreens", (data) => {
        const updatedStreams = data.streamIds;
        setRemoteVideoList(updatedStreams);
      });
     
    }, 0.5000);
  }, []);

  const startCall = async () => {
    zegoRef.current.setDebugVerbose(false);
    zegoRef.current.on(
      "roomStateChanged",
      async (roomID, reason, errorCode, extendedData) => {
        if (reason == "LOGINED") {
         
        } else if (reason == "LOGIN_FAILED") {
        }
      }
    );
    zegoRef.current.on("roomUserUpdate", (roomId, updateType, userList) => {
    });
    zegoRef.current.on(
      "roomStreamUpdate",
      async (roomId, updateType, streamList, extendedData) => {
        if (updateType == "ADD") {
        } else if ((updateType = "DELETE")) {
          const streamID = streamList[0].streamID;
          zegoRef.current.stopPlayingStream(streamID);
        }
      }
    );

    const configOption = {
      videoBitrate: 300,
      audioBitrate: 48,
      camera: {
        audio: true,
        video: {
          quality: 4,
          width: 480,
          height: 480,
          frameRate: 15,
        },
      },
    };
    const token = localStorage.getItem("zegoToken");
    try {
      const loginResponse = await zegoRef.current.loginRoom(
        roomID.current,
        token,
        { userID: myUserId.current, userName: Admin?.name },
        { userUpdate: true },
        { isUserStatusNotify: true }
      );
      if (loginResponse == true) {
        setCallStarted(true);
      } else if (loginResponse !== true) {
        console.error("Failed to login to the room");
        alert(
          "Failed to login to the room. Please check your credentials and try again."
        );
      }

      zegoRef.current.on("publisherStateUpdate", (result) => {
        var state = result["state"];
        var streamID = result["streamID"];
        var errorCode = result["errorCode"];
        var extendedData = result["extendedData"];
        if (state == "PUBLISHING") {
         
        } else if (state == "NO_PUBLISH") {
        } else if (state == "PUBLISH_REQUESTING") {
          
        }
        setCallStarted(true);
        if (errorCode !== 0) {
        }
      });
      zegoRef.current.on("roomUserUpdate", (roomId, updateType, userList) => {
        if (updateType === "DELETE") {
        } else if (updateType === "ADD") {
        }
      });

      const config = {
        logLevel: "disable",
        remoteLogLevel: "disable",
        logURL: "disable",
      };
      zegoRef.current.setLogConfig(config);
    } catch (err) {
    }
    try {
      zegoRef.current.on(
        "roomStateUpdate",
        (roomId, state, errorCode, extendedData) => {
          
          if (errorCode === 1000017) {
          }
        }
      );
    } catch (err) {
    }
  };
 

  

  const PlayRemoteStreams = useCallback(
    async (streamID, videoElement) => {
      if (callStarted) {
        await zegoRef.current.stopPlayingStream(streamID);
        const remoteStream = await zegoRef.current.startPlayingStream(streamID);
        videoElement.srcObject = remoteStream;
      }
    },
    [callStarted]
  );
  const MenuOfVideoClicked = (id) => {
    if (id.id !== showModalOfVideoActions) {
      setShowModalOfVideoActions(id.id);
    } else {
      setShowModalOfVideoActions(null);
    }
  };
  const requestReshareScreen = (id) => {
    try {
      axios
        .post(baseUrl + "/sendNotificationToEmployees", {
          title: "Reshare Request",
          body: "Please navigate back to dashboard and reshare your screen",
          to: [id.id],
        })
        .then((response) => {
          setShowModalOfVideoActions(null);
          socketRef.current.emit('removeUserFromScreenShareRoom',{userId:id.id,room:roomID.current||screenShareRoom})
        })
        .catch((err) => {
        });
    } catch (err) {
    }
  };
  useEffect(() => {
    if (refsSet) {
      remoteVideoList.forEach((stream, index) => {
        PlayRemoteStreams(stream.streamId, remoteVideoStream.current[index]);
      });
    }
  }, [remoteVideoList, refsSet, PlayRemoteStreams, callStarted]);

  return (
    <>
      {/* <div>
        <button
          type="btnn"
          className="screenshare_btnnn"
          style={{
            backgroundColor: "blue",
            color:"white",
            borderRadius: "5px",
            width: "8vw",
            height: "5vh",
            border: "none",
          }}
        >
          Screen Sharing
        </button>
        <button 
         style={{
          backgroundColor: "blue",
          borderRadius: "5px",
          width: "8vw",
          height: "5vh",
          border: "none",
          color:"white"
        }}
        type="btnn" className="screenshare_btnnn">
          Listing
        </button>
      </div> */}
      <h1>DO NOT CLOSE THE SCREEN click here</h1>
      <div id="vidoes"></div>
      {remoteVideoList && remoteVideoList.length > 0 && (
        <div className={"remote_video_section"}>
          {remoteVideoList?.map((streamID, index) => (
            <div
              className={"double_remote_video_container"}
              style={{
                width:
                  remoteVideoList.length === 1
                    ? "100%"
                    : remoteVideoList.length <= 4
                    ? "calc(50% - 2px )"
                    : remoteVideoList.length >= 4
                    ? "calc(25% - 2px )"
                    : "calc(25% - 2px )",
                height:
                  remoteVideoList.length <= 2
                    ? "100%"
                    : remoteVideoList.length <= 8
                    ? "calc(50% - 2px )"
                    : remoteVideoList.length > 8
                    ? "calc(33% - 2px )"
                    : "calc(33% - 2px )",
              }}
            >
              <video
              
                key={streamID.streamId}
                autoPlay={true}
                playsInline
                ref={(el) => {
                  remoteVideoStream.current[index] = el;
                  if (index === remoteVideoList.length - 1) {
                    setRefsSet(true);
                  }
                }}
                style={{ transform: "scaleX(1)" }}
                className="remote_video"
              ></video>
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  zIndex: "9",
                  color: "white",
                }}
              >
                <div style={{ position: "relative" }}>
                  <div onClick={() => MenuOfVideoClicked(streamID)}>
                    <BsThreeDotsVertical />
                  </div>
                  {showModalOfVideoActions === streamID.id && (
                    <div
                      style={{
                        width: "200px",
                        height: "100px",
                        position: "absolute",
                        background: "white",
                        borderRadius: "10px",
                        top: " 20px",
                        right: " 0",
                        boxShadow: "3px 3px 6px #000",
                        color: "#085394",
                        fontSize: "12px",
                      }}
                    >
                      <List>
                        <Tooltip
                          title={`Request ${streamID.name} to reshare/share their screen`}
                        >
                          <ListItem
                            onClick={() => requestReshareScreen(streamID)}
                          >
                            <ListItemIcon>
                              <FaSquareShareNodes
                                color="#085394"
                                style={{ width: "28px", height: "28px" }}
                              />
                            </ListItemIcon>
                            <ListItemText sx={{ fontSize: "14px" }}>
                              Request Reshare{" "}
                            </ListItemText>
                          </ListItem>
                        </Tooltip>
                        {/* <ListItem>  <ListItemText></ListItemText></ListItem>
                    <ListItem>  <ListItemText></ListItemText></ListItem> */}
                      </List>
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  left: "10px",
                  zIndex: "9",
                  color: "white",
                }}
              >
                {streamID.name}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Admin;
