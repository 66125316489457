import Navbar from "./user/components/navbar/Navbar";
import React from "react";
import UserSidebar from "./user/components/sidebar/UserSidebar";

export const Layout = ({ children }) => (
  <div className="home">
    <UserSidebar />
    <div className="homeContainer">
      <Navbar />
      <div className="layout_seyction">
          <div
            className=""
            style={{
              width: "100%",
              height: "calc(100% - 0px)",
              overflow: "auto",
              padding:'0px 10px'
            }}
          >
      {children}
      </div>
      </div>
    </div>
  </div>
);
