import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import { EditIcon } from "../../../components/ui/icons";
import useHiringHook from "../../../hooks/useHiringHook";
import { Input } from "../../../components/ui/input";

export const UpdateHiringModal = ({ hiring }) => {
  const { register, handleSubmit, reset, setValue, formState } = useForm();

  const { errors } = formState;
  useEffect(() => {
    if (hiring) {
      const { position, experience, salary, hiring_last_date, noOfCandidates,hiringStatus } =
        hiring;

      setValue("position", position || "");
      setValue("experience", experience || "");
      setValue("salary", salary || "");
      setValue("hiring_last_date", hiring_last_date || "");
      setValue("noOfCandidates", noOfCandidates || "");
      setValue("hiringStatus",hiringStatus  || "");

    }
  }, [hiring, setValue]);

  const { editHiringMutation, deleteHiringMutation } = useHiringHook();

  const onSubmit = (data) => {
    editHiringMutation.mutate({
      id: hiring?._id,
      data,
    });

    reset();
  };

  return (
    <Dialog>
      <DialogTrigger>
        <EditIcon className="text-primary" />
      </DialogTrigger>
      <DialogContent>
        <h2>Update Hiring</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label htmlFor="position">Position:</label>
            <Input
              type="text"
              id="position"
              name="position"
              {...register("position", {
                validate: (value) => value !== "" || "Position is required",

                maxLength: {
                  value: 30,
                  message: "Maximum 30a characters allowed",
                },
                minLength: {
                  value: 1,
                  message: "Minimum 1 characters required",
                },
              })}
            />

            {errors.position && (
              <p className="error-message">{errors.position.message}</p>
            )}
          </div>
          <div>
            <label htmlFor="experience">Experience (years):</label>
            <Input
              type="number"
              id="experience"
              name="experience"
              {...register("experience", {
                validate: (value) => value !== "" || "Experience is required",

                pattern: {
                  value: /^-?\d{1,2}(-\d{1,2})?$/,
                  message: "Experience must be a number (negative values allowed)",
                },
                maxLength: {
                  value: 2,
                  message: "Maximum 2 characters allowed",
                },

                minLength: {
                  value: 1,
                  message: "Minimum 1 characters required",
                },
              })}
            />

            {errors.experience && (
              <p className="error-message">{errors.experience.message}</p>
            )}
          </div>
          <div>
            <label htmlFor="salary">Salary:</label>
            <Input
              type="text"
              id="salary"
              name="salary"
              {...register("salary", {
                validate: (value) => value !== "" || "Salary is required",

                pattern: {
                  value: /^[0-9\b]+$/,
                  message: "Salary must be a number",
                },
                maxLength: {
                  value: 10,
                  message: "Maximum 10 characters allowed",
                },

                minLength: {
                  value: 1,
                  message: "Minimum 1 characters required",
                },
              })}
            />

            {errors.salary && (
              <p className="error-message">{errors.salary.message}</p>
            )}
          </div>
          <div>
            <label htmlFor="hiring_last_date">Last Date of Hiring:</label>
            <Input
              type="date"
              id="hiring_last_date"
              name="hiring_last_date"
              {...register("hiring_last_date", {})}
            />

            {errors.hiring_last_date && (
              <p className="error-message">{errors.hiring_last_date.message}</p>
            )}
          </div>
          <div>
            <label htmlFor="noOfCandidates">Number of Candidates:</label>
            <Input
              type="number"
              id="noOfCandidates"
              name="noOfCandidates"
              {...register("noOfCandidates", {
                validate: (value) =>
                  value !== "" || "Number of Candidates is required",

                pattern: {
                  value: /^[0-9\b]+$/,
                  message: "Number of Candidates must be a number",
                },
                maxLength: {
                  value: 2,
                  message: "Maximum 2 characters allowed",
                },

                minLength: {
                  value: 1,
                  message: "Minimum 1 characters required",
                },
              })}
            />

            {errors.noOfCandidates && (
              <p className="error-message">{errors.noOfCandidates.message}</p>
            )}
          </div>
          <div>
            <label htmlFor="hiringStatus">Hiring Status:</label>
            <select
              id="hiringStatus"
              name="hiringStatus"
              {...register("hiringStatus", {
                required: {
                  value: true,
                  message: "Hiring Status is required",
                },
              })}
            >
              <option value="">Select status</option>
              <option value="opening">Opening</option>
              <option value="closing">Closing</option>
              <option value="ongoing">Ongoing</option>
            </select>

            {errors.hiringStatus && (
              <span className="error-message">
                {errors.hiringStatus.message}
              </span>
            )}
          </div>
          <DialogFooter className="flex justify-between my-2 gap-4">
            {formState.isValid && (
              <DialogClose asChild>
                <Button type="submit">Save</Button>
              </DialogClose>
            )}

            {!formState.isValid && <Button type="submit">Save</Button>}

            <DialogClose asChild>
              <Button
                onClick={() => {
                  deleteHiringMutation.mutate(hiring?._id);
                }}
                isLoading={deleteHiringMutation.isPending}
              >
                Remove
              </Button>
            </DialogClose>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
