/* eslint-disable no-unused-vars */
import EditNoteIcon from "@mui/icons-material/EditNote";
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../../config/baseUrl";
import axios from "../../config/axiosAuth";
import useAttendanceHook from "./attendance.hook";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { CommentModal } from "../../components/modals/CommentModal";
import { DailyLogs } from "../../components/modals/DailyLogsModal";
import { columns } from "./columns";
import { DataTable } from "../../components/table/Data-Table";
import Loading from "../protectedPages/Loading";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import { PiMagnifyingGlassThin } from "react-icons/pi";

const Attendance = () => {
  const [team, setTeam] = useState("")
  const [searchEmployee, setSearchEmployee] = useState("");
const {departmentList}=useEmployeeHook();
const params={
  designation:team,
  name:searchEmployee
}
  const { data: employees, isLoading: employeeLoading } = useEmployeeHook(params);

  // if ((isLoading || employeeLoading) ) {
  //   return <Loading />;
  // }
  const handleChangeTeam = (e) => {
    setTeam(e.target.value);
  };
  return(
  <>
     <div className="flex gap-4 items-end mb-[10px]">
          <div className=" h-[15%] max-w-[250px] p-2   bg-white  rounded-md">
            <select
              onChange={handleChangeTeam}
              className="h-full w-full border-0 rounded-lg  text-[13px]"
              name=""
              id=""
              value={team}
            >
              <option value="" className="text-sm ">
                Select Department
              </option>
              {departmentList && departmentList?.length > 0
                ? departmentList?.map((department) => (
                    <option value={department._id} className="text-sm ">
                      {department?.teamName}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="h-[15%] max-w-[250px]">
            <div className="search_icon  ">
              <PiMagnifyingGlassThin />
              <input
                placeholder="Search"
                onChange={(e) => setSearchEmployee(e.target.value)}
                className="p-2 h-full"
              />
            </div>
          </div>
        </div>
  <DataTable columns={columns} data={employees||[]}defaultTheme={true} TableHeight={'350px'}/>
  </> )
};

export default Attendance;
