import { useMutation, useQuery } from "react-query";
import useEventStore from "../store/eventStore";
import eventService from "../service/eventService";

const useEventHook = () => {
  const {
    eventData,
    setEvents,
    setOtherEvents,
    addOtherEvents,
    updateOtherEvents,
    deleteOtherEvents,
  } = useEventStore();

  const fetchAllEvents = async () => {
    const res = await eventService.getAllEvents();
    return res;
  };

  const {
    data: allEvents,
    error: allEventsError,
    isLoading: allEventsLoading,
    isError: allEventsIsError,
  } = useQuery("allEvents", fetchAllEvents, {
    onSuccess: (data) => {
      setEvents(data);
      setOtherEvents(data?.otherEvent);
    },
  });

  const addOtherEventMutation = useMutation(
    (data) => eventService.addOtherEvent(data),
    {
      onSuccess: (data) => {
        addOtherEvents(data);
      },
    }
  );

  const updateOtherEventMutation = useMutation(
    (data) => eventService.updateOtherEvent(data.data, data.id),
    {
      onSuccess: (data) => {
        updateOtherEvents(data.data, data.id);
      },
    }
  );

  const deleteOtherEventsMutation = useMutation(
    (id) => eventService.deleteOtherEvent(id),
    {
      onSuccess: (data) => {
        deleteOtherEvents(data);
      },
    }
  );

  return {
    allEvents,
    allEventsError,
    allEventsLoading,
    allEventsIsError,
    eventData,
    addOtherEventMutation,
    updateOtherEventMutation,
    deleteOtherEventsMutation,
  };
};

export default useEventHook;
