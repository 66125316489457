// LeadModal.js
import React, { useState } from "react";

import { useForm, Controller } from "react-hook-form";

import { useEffect } from "react";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";

import { Button } from "../../components/ui/button.jsx";
import { EditIcon } from "../../components/ui/icons.jsx";
import { TextField } from "@mui/material";
import useLeadsHooks from "./Leads.hook.js";

const EditLeadModal = ({ Lead }) => {
  const { control, handleSubmit, setValue, formState } = useForm({
    defaultValues: {
      clientName: Lead?.clientName || "",
      companyName: Lead?.companyName || "",
      phone: Lead?.phone || "",
      email: Lead?.email || "",
      enquiryAbout: Lead?.enquiryAbout || "",
      feedBack: Lead?.feedBack || "",
      status: Lead?.status || "",
      link:Lead?.link||""
    },
  });

  const { errors } = formState;
  const[open,setOpen]=useState(false)

  const statusOptions = ["Followup", "Close", "Hot Leads", "Done"];

  const handlePhoneChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, "");
    const slicedValue = numericValue.slice(0, 10);
    setValue("phone", slicedValue);
  };

  const { deleteLeadMutation, editLeadMutation } = useLeadsHooks();

  const onSubmit = (data) => {
    editLeadMutation.mutate({ id: Lead?._id, data: data });
    setOpen(false)
  };

  useEffect(() => {
    // Use the useEffect hook to set default values when the Lead prop changes
    if (Lead) {
      const {
        clientName,
        companyName,
        phone,
        email,
        enquiryAbout,
        feedBack,
        status,
      } = Lead;

      setValue("clientName", clientName || "");
      setValue("companyName", companyName || "");
      setValue("phone", phone || "");
      setValue("email", email || "");
      setValue("enquiryAbout", enquiryAbout || "");
      setValue("feedBack", feedBack || "");
      setValue("status", status || "");
    }
  }, [Lead, setValue]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <EditIcon className="text-primary " />
      </DialogTrigger>
      <DialogContent>
        <div>
          <h2 id="modal-title" className="mb-4">
            Lead Form
          </h2>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div style={{ display: "flex", gap: "16px" }}>
              <Controller
                name="clientName"
                control={control}
                defaultValue={Lead?.clientName}
                rules={{
                  pattern: {
                    value: /^[a-zA-Z ]*$/,
                    message: "Only Alphabhet are allowed",
                  },

                  maxLength: {
                    value: 30,
                    message: "Client Name should not exceed 30 characters",
                  },

                  minLength: {
                    value: 3,
                    message: "Client Name should contain atleast 3 characters",
                  },

                  validate: (value) =>
                    value.trim() !== "" || "Name is required",
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Client Name"
                    variant="outlined"
                    fullWidth
                    error={!!errors.clientName}
                    helperText={errors.clientName?.message}
                  />
                )}
              />

              <Controller
                name="companyName"
                rules={{
                  // required: "Company Name is required",
                  pattern: {
                    value: /^[a-zA-Z0-9\s]*$/,
                    message: "Only alphabet or number are allowed",
                  },

                  maxLength: {
                    value: 50,
                    message: "Maximum 50 characters are allowed",
                  },

                  minLength: {
                    value: 3,
                    message: "Minimum 3 characters are allowed",
                  },

                  validate: (value) =>
                    value.trim() !== "" || "Company Name is required",
                }}
                control={control}
                defaultValue={Lead?.companyName}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Company Name"
                    variant="outlined"
                    error={!!errors.companyName}
                    helperText={errors.companyName?.message}
                    fullWidth
                  />
                )}
              />
            </div>

            <div style={{ display: "flex", gap: "16px" }}>
              <Controller
                name="phone"
                control={control}
                defaultValue={Lead?.phone}
                rules={{
                  // required: "Phone is required",
                  pattern: {
                    value: /^[0-9]+$/i,
                    message: "Phone should contain only numbers",
                  },

                  maxLength: {
                    value: 10,
                    message: "Phone should not exceed 10 characters",
                  },

                  minLength: {
                    value: 10,
                    message: "Phone should contain 10 characters",
                  },

                  validate: (value) =>
                    value.trim() !== "" || "Phone is required",
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Phone"
                    variant="outlined"
                    fullWidth
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                    onChange={handlePhoneChange}
                  />
                )}
              />

              <Controller
                name="email"
                control={control}
                defaultValue={Lead?.email}
                rules={{
                  // required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },

                  maxLength: {
                    value: 50,
                    message: "Email should not exceed 50 characters",
                  },

                  minLength: {
                    value: 3,
                    message: "Email should contain atleast 3 characters",
                  },

                  validate: (value) =>
                    value.trim() !== "" || "Email is required",
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email"
                    variant="outlined"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </div>

            <div style={{ display: "flex", gap: "16px" }}>
              <Controller
                name="enquiryAbout"
                control={control}
                rules={{
                  // required: "Enquiry About is required",
                  pattern: {
                    value: /^[A-Za-z]+$/i,
                    message: "Enquiry About should contain only alphabets",
                  },

                  maxLength: {
                    value: 50,
                    message: "Enquiry About should not exceed 50 characters",
                  },

                  minLength: {
                    value: 3,
                    message:
                      "Enquiry About should contain atleast 3 characters",
                  },

                  validate: (value) =>
                    value.trim() !== "" || "Enquiry About is required",
                }}
                defaultValue={Lead?.enquiryAbout}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Enquiry About"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />

              <Controller
                name="feedBack"
                rules={{
                  // required: "Feedback is required",
                  pattern: {
                    value: /^[A-Za-z]+$/i,
                    message: "Feedback should contain only alphabets",
                  },

                  maxLength: {
                    value: 100,
                    message: "Feedback should not exceed 100 characters",
                  },

                  minLength: {
                    value: 3,
                    message: "Feedback should contain atleast 3 characters",
                  },

                  validate: (value) =>
                    value.trim() !== "" || "Feedback is required",
                }}
                control={control}
                defaultValue={Lead?.feedBack}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Feedback"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </div>

            <Controller
              name="status"
              control={control}
              defaultValue={Lead?.status}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Status"
                  variant="outlined"
                  fullWidth
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option aria-label="None" value="" />
                  {statusOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </TextField>
              )}
            />
              <Controller
              name="link"
              control={control}
              defaultValue=""
              rules={{
                required: "URL link is required",
                pattern: {
                  value: /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
                  message: "Please enter a valid URL"
                },
                validate: {
                  checkProtocol: (value) => {
                    if (value && !value.startsWith('http://') && !value.startsWith('https://')) {
                      return "URL must start with http:// or https://";
                    }
                    return true;
                  }
                }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="URL Link"
                  variant="outlined"
                  fullWidth
                  error={!!errors.link}
                  helperText={errors.link?.message}
                  SelectProps={{
                    native: true,
                  }}
                >
                </TextField>
              )}
            />
            <DialogFooter>
              {formState.isValid && (
                // <DialogClose asChild>
                  <Button type="submit">Save</Button>
                // </DialogClose>
              )}

              {!formState.isValid && <Button type="submit">Save</Button>}

              {/* <DialogClose asChild> */}
                <Button
                  onClick={() => {
                    deleteLeadMutation.mutate(Lead?._id);
                  }}
                  isLoading={deleteLeadMutation.isLoading}
                >
                  Remove
                </Button>
              {/* </DialogClose> */}
            </DialogFooter>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditLeadModal;
