import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { transformProjectDataToToDoList } from "../../../utils/transformData";
import { EmpForTheMonth } from "../../components/infoCard/EmpForTheMonth";
import { ToDoList } from "../../components/infoCard/ToDoList";
import useUserHook from "../../hooks/userHook";
import empForTheMothStore from "../../store/empForTheMonth";
import Error from "../protectedPages/Error";
import OfficeCheckInOut from "./CheckInCheckOut";
import { Widgets } from "./Widgets";
import { useQuery } from "react-query";
import authService from "../../services/authServicesUser";
import ScreenShareRoomDetailsStore from "../../store/ScreenShareRoomDetailsStore";
import useUserProjectStore from "../../store/userProject";
import { logoutFunc } from "../../../utils/lib";

const UserDashboard = () => {
  const {
    user,

    totalCompletedProjects,
    completedProjects,
    userLeaves,
    userLoading,
    userIsError,
    userLeavesLoading,
    userTotalProjectsLoading,
    userCompletedProjectsLoading,
    updateDeviceTokenMutation,
    userApprovedLeaves
  } = useUserHook();
  const [socketRef, zegoRef,reshareScreen] = useOutletContext();
  // const [callStarted, setCallStarted] = useState(false);
  const { totalProjects } = useUserProjectStore();
  const {
    setRoom,
    screenShareRoom,
    isInGroupMeetingTab,
    setScreenShareSteamIdFromStore,
    screenShareCallStarted,
    setScreenShareCallStarted,
    screenShareSteamIdFromStore,
    setUserId,
    userId,
    setResharingScreen,
    resharingScreen,
    userIdFromStore,
    setUserIdFRomStore,
  } = ScreenShareRoomDetailsStore();
  const roomId = useRef(null);
  const localscreenShareStream = useRef(null);
  const myUserId = useRef(null);
  const screenStreamId = useRef(null);
  const navigate = useNavigate();
  // deleteOtherEventsMutation.mutate(id);
  useEffect(() => {
    updateDeviceTokenMutation.mutate(localStorage.getItem("fcmToken"));
  }, []);
  const loading =
    userLoading ||
    userLeavesLoading ||
    userTotalProjectsLoading ||
    userCompletedProjectsLoading;

  const { employeeForTheMonth } = empForTheMothStore();
  const isEmployeeOfTheMonth = user?._id === employeeForTheMonth?._id;
  const toDoList = transformProjectDataToToDoList(completedProjects);
  const [donotshowButton, setDoNotShowButton] = useState(true);

    return (
    <div
      style={{
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <div className="homeContentTitle">Dashboard</div>

      {/* {donotshowButton && ( */}
        <button
          className="button-17"
          style={{ width: "170px" }}
          onClick={reshareScreen}
        >
          Re-Share Screen
        </button>
      {/* )} */}
      <div
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "space-between",
        }}
      >
        <EmpForTheMonth isEmployeeOfTheMonth={isEmployeeOfTheMonth} />
        {/* <OfficeCheckInOut /> */}
      </div>

      <div className="homeContentBody">
        <div
          style={{
            display: "flex",
          }}
        >
          <Widgets title="Total Projects" total={totalProjects} />
          <Widgets title="Approved Leaves" total={userApprovedLeaves?.length} />
          <Widgets title="Completed Projects" total={totalCompletedProjects} />
          <ToDoList project={toDoList} title="Completed Projects" />
        </div>
      </div>
      {/* <div
        id="local_share_screen"
        style={{
          width: "300px",
          height: "300px",
          position: "absolute",
          top: "10px",
        }}
      ></div> */}
      {/* <Link target="_blank" to="/screen"> */}

      {/* </Link> */}
    </div>
  );
};

export default UserDashboard;
