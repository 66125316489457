// useProjectStore.js

import { create } from "zustand";

const useProjectStore = create((set) => ({
  projects: [],
  totalProjects: 0,
  setTotalProjects: (totalCount) => set({ totalProjects: totalCount }),
  completedProjects: [],
  pendingProjects: [],
  setPendingProjects: (project) =>
    set((state) => ({
      pendingProjects: project,
    })),
  setProjects: (project) =>
    set((state) => ({
      projects: project,
    })),
  totalCompletedProjects: 0,
  totalPendingProjects: 0,
  setTotalPendingProjects: (totalCount) =>
    set({ totalPendingProjects: totalCount }),

  setTotalCompletedProjects: (totalCount) =>
    set({ totalCompletedProjects: totalCount }),
  setCompletedProjects: (project) =>
    set((state) => ({
      completedProjects: project,
    })),
  updateProject: (updatedProject) =>
    set((state) => ({
      projects: state.projects.map((project) =>
        project._id === updatedProject._id ? updatedProject : project
      ),
    })),
  deleteProject: (projectId) =>
    set((state) => ({
      projects: state.projects.filter(
        (project) => project.projectId !== projectId
      ),
    })),


}));

export default useProjectStore;
