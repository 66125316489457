import React, { useEffect, useState } from 'react'
import { DataTable } from '../../components/table/Data-Table'
import axios from '../../config/axiosAuth'
import { baseUrl } from '../../config/baseUrl'
import { useParams } from 'react-router-dom'
import moment from 'moment'

const TasksByUserId = () => {
const params =useParams()
const [data,setData]=useState([])
    const getTaskbyEmployeeId=async()=>{
        try{
            const response =await axios.post(baseUrl+'/task-by-employee-id',{
                employeeId:params.id
            })
            console.log(response,'target by id')
            setData(response?.data?.employees)

        }catch(err){
            throw new Error(err)
        }
    }
    useEffect(()=>{
        getTaskbyEmployeeId()
    },[])

    const column= [
        {
          header: "S.No",
          cell: ({ row }) => row.index+1
    
        },
        {
          header: "Task",
          accessorKey:'task'
        },
        {
            header: "Task Assigned At",
         cell:({row})=>moment(row.original?.createdAt).format('DD-MM-YYYY')
          },
        {
          header: "Project Name",
          cell: ({ row }) => {
            return row.original?.projectId?.projectName
          },
        },
        {
          header: "Task Status",
          accessorKey:'status'
        },
        {
          header: "Description",
          accessorKey:'description'
        },
       

      ];
  return (
   <DataTable columns={column} data={data||[]} defaultTheme={true}/>
  )
}

export default TasksByUserId
