export const tableStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "80%",
    backgroundColor: "#fff",
    maxHeight: "80vh",
    overflow: "auto",
  },
};
