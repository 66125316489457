import { create } from "zustand";
import { persist } from 'zustand/middleware';


const ScreenShareRoomDetailsStore = create(
  // persist(
    (set) => ({
    screenShareRoom: null,
    resharingScreen:false,
    isInGroupMeetingTab:false,
    screenShareSteamIdFromStore:null,
    screenShareCallStarted:false,
    userId:null,
    userIdFromStore:null,
    setUserIdFRomStore:(value)=>({userIdFromStore:value}),
    setUserId:(id)=>set({userId:id}),
    setRoom: (room) => set({screenShareRoom:room} ),
    setScreenShareSteamIdFromStore:(value)=>set({screenShareSteamIdFromStore:value}),
    setIsInGroupMeetingTab:(value)=>set({isInGroupMeetingTab:value}),
    setScreenShareCallStarted:(value)=>set({screenShareCallStarted:value}),
    setResharingScreen:(value)=>set({resharingScreen:value})
  })
  // ,{
  //   name: "screen-share-room-details", // unique name for the storage
  //   getStorage: () => localStorage, // use localStorage
  // }),
  
  )
    

export default ScreenShareRoomDetailsStore;
