import React, { useState } from "react";
import Loading from "../protectedPages/Loading";

import AddFollowUpModal from "./AddFollowUp";
import useFollowUpHooks from "./followUpHooks";
import { DataTable } from "../../components/table/Data-Table";
import { columns } from "./columns";

export function FollowUps() {
  const [addFollowUpModalIsOpen, setAddFollowUpModalIsOpen] = useState(false);

  const { followUps, isLoading, isError, deleteFollowUpMutation } =
    useFollowUpHooks();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="flex justify-end">
        <AddFollowUpModal
          isOpen={addFollowUpModalIsOpen}
          onClose={() => setAddFollowUpModalIsOpen(false)}
        />
      </div>
      <DataTable columns={columns} data={followUps||[]} defaultTheme={true}/>
    </>
  );
}