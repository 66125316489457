import React, { useEffect, useState } from "react";
import { LuUserCircle2, LuUserPlus2 } from "react-icons/lu";
import { IoPersonRemove } from "react-icons/io5";
import './meetings.css';

import axios from "axios";
import { baseUrl } from "../../config/baseUrl";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
} from "@mui/material";
import { MdMicOff } from "react-icons/md";

const PeopleSection = ({ roomId, }) => {
  const [usersInvitedToCall, setUsersInvitedToCall] = useState([]);
  const [showAddPeoplePopup, setAddPeoplePopup] = useState(false);
  const [searchedEmployeeName, setSearchedEmployeeName] = useState("");
  const [invitedPeople, setInvitedPeople] = useState([]);

 
  const location = useLocation();
  const LinkId = location.pathname.split("");
  console.log(roomId,'roomID from  people')

  const searchedEmployee = (event) => {
    setSearchedEmployeeName(event.target.value);
  };
  console.log(invitedPeople, "setInvitedPeople from people");
  useEffect(() => {
    console.log(invitedPeople);
  }, [invitedPeople]);

 
  const filteredEmployeeIntheCall = usersInvitedToCall?.filter((employee) => {
    return employee.name
      .toLowerCase()
      .includes(searchedEmployeeName.toLowerCase());
  });

 
  const PeoplePresentIntheCall = async () => {
    try {
      const response = await axios.get(baseUrl + `/meeting/${roomId.id}`);
      //   setAddPeoplePopup(false);
      setUsersInvitedToCall(response?.data?.employees);

      console.log(response.data, "response of people ");
    } catch (error) {
      console.log(error);
      // toast.error(error.response?.data?.message);
    }
  };
  console.log(roomId, "roomId.roomId");

  useEffect(() => {
    PeoplePresentIntheCall();
  }, []);

  

  return (
    <>
      <div className="people_head">People</div>
      <>
        
        <div
          style={{
            width: "100%",
            height: "75px",
            display: "flex",
            alignItems: "flex-end",
          }}
          className="search_container"
        >
          <TextField
            fullWidth
            // color='white'
            label="Search Employee to give invite"
            variant="standard"
            value={searchedEmployeeName}
            onChange={searchedEmployee}
          />
        </div>
        <div className="all_people_invited_section">
          <List>
            {filteredEmployeeIntheCall?.map((employee) => (
              <ListItem
                className="list_item_employee"
               
              >
                <ListItemAvatar>
                  <Avatar>{employee.name[0]}</Avatar>
                </ListItemAvatar>

                <ListItemText>{employee.name}</ListItemText>

               
                  <ListItemIcon>
                    <MdMicOff  color='white'/>
                  </ListItemIcon>
              
{/* 
                  <ListItemIcon
                   >
                    <IoPersonRemove color="white" />
                  </ListItemIcon> */}
              </ListItem>
            ))}
          </List>
        </div>
      </>
     
    </>
  );
};

export default PeopleSection;
