import React, { useEffect, useState } from 'react'
import { DataTable } from '../../components/table/Data-Table'
import { columns, employeeProjectcolumns } from './columns'
import useEmployeeHook from '../../hooks/useEmployeeHook'
import { useParams } from 'react-router-dom'

const EmployeeWiseProjects = () => {
    const[data,setData]=useState([])
    const {id}=useParams()
    console.log(id,'id')
    const{fetchEmployeeProjectsMutation}=useEmployeeHook(id)
    useEffect(()=>{
        getProjects()
    },[id])
    const getProjects=async()=>{
        const response= await   fetchEmployeeProjectsMutation.mutateAsync(id)
        console.log(response,'fetchEmployeeProjectsMutation')
        setData(response)
    }
  return (
    <div>
      <DataTable columns={employeeProjectcolumns} heading='Employee Project List ' defaultTheme={true} data={data||[]}/>
    </div>
  )
}

export default EmployeeWiseProjects
