import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import useBirthdayStore from "../store/birthdayStore";
import useHolidayStore from "../store/holidayStore";
import useLeaveRequestStore from "../store/leaveStore";
import leaveServices from "../service/leaveServices";
import eventService from "../service/eventService";
import { projectServices } from "../service/projectService";

const useTimesheetHook = () => {
  const {
    approvedLeave,
    setApprovedLeave,
    setTotalApprovedLeaves,
    totalApprovedLeaves,
    detailedTimeSheetsLeave
  } = useLeaveRequestStore();

  const { holiday, setHolidays, addHoliday, updateHoliday, removeHoliday } =
    useHolidayStore();
  const { birthdays, setBirthdays } = useBirthdayStore();

  const fetchLeaves = async () => {
    const data = await leaveServices.getAllLeaves();
    return data;
  };

  const fetchBirthdays = async () => {
    const data = await eventService.getAllBirthdays();
    return data;
  };
const getTimesheetLeaves=async()=>{
  const data= await leaveServices.getNonRejectedLeaves();
  return data;
}
  const fetchHolidays = async () => {
    const data = await eventService.getAllHolidays();
    return data;
  };

  const fetchProjects = async () => {
    const data = await projectServices.getAllProjects();
    return data.project;
  };

  const queryClient = useQueryClient();

  const { data: leaves, isLoading: leavesLoading } = useQuery(
    "approvedLeaves",
    fetchLeaves,
    {
      // refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setApprovedLeave(data);
        setTotalApprovedLeaves(data.length);
      },
    }
  );
  const{data:filteredLeaves}=useQuery("filteredLeaves",getTimesheetLeaves,)

  const { data: projects, isLoading: projectsLoading } = useQuery(
    "projects",
    fetchProjects
  );

  const { data: birthday, isLoading: birthdaysLoading } = useQuery(
    "birthdays",
    fetchBirthdays,
    {
      // refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setBirthdays(data);
      },
    }
  );

  const { data: holidays, isLoading: holidaysLoading } = useQuery(
    "holidays",
    fetchHolidays,
    {
      onSuccess: (data) => {
        setHolidays(data);
      },
    }
  );

  const addHolidayMutation = useMutation(
    (data) => eventService.addHoliday(data),
    {
      onSuccess: (data) => {
        addHoliday(data);
        queryClient.invalidateQueries("holidays");
      },
    }
  );

  const updateHolidayMutation = useMutation(
    (data) => eventService.updateHoliday(data.data, data.id),
    {
      onSuccess: (data) => {
        updateHoliday(data.data, data.id);
      },
    }
  );

  const deleteHolidayMutation = useMutation(
    (id) => eventService.deleteHoliday(id),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("holidays");
      },
    }
  );

  const loading =
    holidaysLoading || birthdaysLoading || projectsLoading || leavesLoading;

  return {
    leaves,
    projects,
    birthday,
    holidays,
    leavesLoading,
    projectsLoading,
    birthdaysLoading,
    holidaysLoading,
    totalApprovedLeaves,
    loading,
    addHolidayMutation,
    updateHolidayMutation,
    deleteHolidayMutation,
    filteredLeaves
  };
};

export default useTimesheetHook;
