import { useState } from "react";
import useTimesheetHook from "../../hooks/useTimesheerHook";
import { CircularProgress, Pagination, Typography, Stack } from "@mui/material";
import useEventStore from "../../store/eventStore";
import { DataTable } from "./Data-Table";
import moment from "moment";

const Projecttable = () => {
  const { projectEvents } = useEventStore();
 

  
  const columns=[{header:'S.No',cell:({row})=>row.index+1},
    {header:'Project Name',cell:({row})=>row.original?.name},
    {header:'StartDate',cell:({row})=>moment(row.original?.start).format('DD-MM-YYYY')},
    {header:'EndDate',cell:({row})=>moment(row.original?.end).format('DD-MM-YYYY')},
  ]

  return (
    <>
    
      <DataTable columns={columns} data={projectEvents||[]} defaultTheme={true} TableHeight={'200px'} heading='Projects'/>
    </>
  );
};
export default Projecttable;
