import { create } from "zustand";

const useSidebarStore = create((set) => ({
  activeSideBar: 'user',
  responsibilitySidebar:[],
  setActiveSideBar: (role) => set({   isUserSideBar: role, }),
  setResponsibilitySidebar: (role) => set({   responsibilitySidebar: role, }),

  
}));

export default useSidebarStore;
