import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  MenuItem,
  TextField,
} from "@mui/material";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
import { Option  } from '@mui/base/Option'

// import { Option as BaseOption, optionClasses } from '@mui/base/Option';
import { useForm, Controller } from "react-hook-form";
import useTaskMangementEditHook from "../TaskManagementEditHook.hook";
import { Select, SelectContent, SelectItem } from "../../../../PageResponsibility/src/admin/components/ui/select";
// import useTaskMangementHook from "../TaskManagement.hooks";

export const EditTaskModal = ({ open, onClose, task }) => {
  const { editTaskMutation } = useTaskMangementEditHook();

  const { control, handleSubmit, setValue, formState,reset } = useForm({
    defaultValues: {
      taskCompleted: task?.completed || "",
      status: task?.status || "",
    },
  });
  const { errors } = formState;

  useEffect(() => {
    setValue("taskCompleted", task?.completed);
    setValue("status", task?.status);
  }, [task, setValue]);

  const onSubmit = (data) => {
    console.log("Form data:", data);

    editTaskMutation.mutate({
      _id: task._id,
      data: { completed: data.taskCompleted, status: data.status },
    });
reset()
    onClose();
  };
  const statusOptions = ['Pending', 'InProgress', 'Completed', 'Planning', 'Approved', 'Delivered'];
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Task</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="taskCompleted"
            control={control}
            rules={{
              required: "This Field cannot be empty ",
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Task Completed"
                margin="normal"
              />
            )}
          />
          {errors.taskCompleted && (
            <p className="error-message">{errors.taskCompleted.message}</p>
          )}
<Controller
  name="status"
  control={control}
  defaultValue=""
  rules={{
    required: "This field cannot be empty",
  }}
  render={({ field, fieldState: { error } }) => (
    <>
      {/* <Select
        {...field}
        fullWidth
        displayEmpty
        inputProps={{ "aria-label": "Select Status" }}
      >
        
        <MenuItem value="" disabled>
          Select Status
        </MenuItem>
        <MenuItem value="Pending">Pending</MenuItem>
        <MenuItem value="Delivered">Delivered</MenuItem>
      </Select> */}
      <Controller
            name="status"
            control={control}
            // defaultValue={.status}
            render={({ field }) => (
              <TextField
                {...field}
                select
                label="Status"
                variant="outlined"
                fullWidth
                SelectProps={{
                  native: true,
                }}
              >
                <option aria-label="None" value="" />
                {statusOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            )}
          />
      {error && <p error>{error.message}</p>}
    </>
  )}
/>
          {errors.status && (
            <p className="error-message">{errors.status.message}</p>
          )}

          <DialogActions>
            <Button type="button" onClick={onClose} color="secondary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};
