import { id } from "date-fns/locale";
import { create } from "zustand";

const useEventStore = create((set) => ({
  eventData: [],
  otherEvents: [],
  projectEvents:[],
  holidays:[],
  setHolidays:(event)=>set({holidays:event}),
  setProjectEvents:(event)=>set({projectEvents:event}),
  setOtherEvents: (event) => set({ otherEvents: event }),
  addOtherEvents: (event) =>
    set((state) => ({ otherEvents: [...state.otherEvents, event] })),
  updateOtherEvents: (id, event) =>
    set((state) => ({
      otherEvents: state.otherEvents.map((item) =>
        item._id === id ? event : item
      ),
    })),
  findOtherEvent: (id) => {
    const found = useEventStore.getState().otherEvents,
      result = found?.find((e) => e._id === id);
    return result;
  },
  deleteOtherEvents: (id) =>
    set((state) => ({
      otherEvents: state.otherEvents.filter((item) => item._id !== id),
    })),

  totalEvents: 0,
  setTotalEvents: (totalCount) => set({ totalEvents: totalCount }),

  setEvents: (event) => set({ eventData: event }),
}));

export default useEventStore;
