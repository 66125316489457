import { useQuery } from "react-query";
import { taskServices } from "../../service/taskServices";

const useTaskManagementHook = (projectId, page, limit) => {
  const fetchTasks = async () => {
    const response = await taskServices.getTasks(projectId, page, limit);
    return response;
  };

  return useQuery(["tasks"], fetchTasks);
};

export { useTaskManagementHook };
