import React, { useEffect, useState } from "react";
import { DataTable } from "../../../PageResponsibility/src/admin/components/table/Data-Table";
import { columns, reportColumns } from "./columns";
import { useNavigate, useParams } from "react-router-dom";
import axios from '../../../PageResponsibility/src/admin/config/axiosAuth'
import { baseUrlUser } from "../../config/baseUrl";
import { CommentDialog } from "../../components/modals/CommentDialog";
import moment from "moment";
import { AddCommentModal } from "../../components/modals/AddCommentModal";
const DetailedUserReport = () => {
  const {id} = useParams();
  const[data,setData]=useState([])
  console.log(id, "useparams");
const Navigate=useNavigate()
  const getReportsByProject = async () => {
    try {
      const response = await axios.post(baseUrlUser+"/report-by-project", { projectId: id });
      console.log("response of userreport", response);
      setData(response?.data?.result)
    } catch (err) {
      throw new Error(err);
    }
  };

  useEffect(()=>{
    getReportsByProject()
  },[])
   const reportColumns= [
    {
      header: "S.No",
      cell: ({ row }) => {
        return <span>{row.index + 1}</span>;
      },
    },
    // {
    //   accessorKey: "taskAssigned",
    //   header: "Task Assigned",
    // },
 
    {
      accessorKey: "status",
     
      header: "Status",
     
    },
    // {
    //   accessorKey: "projectDescription",
    //   header: "Description",
    // },
    {
      // accessorKey: "createdAt",
      cell: ({ row }) => moment(row.original.createdAt).format("YYYY-MM-DD"),
      header: "Project Added at",
    },
    {
      header: "Reports",
      cell:({row})=><div className="button-17 !rounded-[10px]"onClick={()=>Navigate(`/viewReport`,{state:{data:row?.original?.reports}})}>View Reports</div>,
    },
    {
      header: "Add Comment",
      cell: ({ row }) => (
        <AddCommentModal
          id={row.original._id}
          model={'report'}
        />
      ),
    },
    {
      header: "Comment",
  
      cell: ({ row }) => {
        return (
          <CommentDialog id={row.original._id} model='report' />
        );
      },
    },
  
    // {
    //   header: "Add Employees",
    //   cell: ({ row }) => {
    //     return (
    //       <Link to={`/addEmployee/?projectId=${row.original._id}`}>
    //         <button className="button-17">Add Employees</button>
    //       </Link>
    //     );
    //   },
    // },
  
    // {
    //   accessorKey: "edit",
    //   header: "Edit",
    //   cell: ({ row }) => {
    //     return (
    //       <span className="flex justify-center">
    //         <Link to={`/editProject/${row.original._id}`}>
    //           <RiEditBoxFill className="text-lg" />
    //         </Link>
    //       </span>
    //     );
    //   },
    // },
  
    // {
    //   accessorKey: "details",
    //   header: "Details",
    //   cell: ({ row }) => {
    //     return (
    //       <Link to={`/ProjectDetails/${row.original._id}`}>
    //         <button className="button-17 !rounded-[10px]">Details</button>
    //       </Link>
    //     );
    //   },
    // },
  ];
  return (
    <div>
      <DataTable
        heading="Reports List"
        data={data||[]}
        columns={reportColumns}
        defaultTheme={true}
      />
    </div>
  );
};

export default DetailedUserReport;
