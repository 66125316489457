import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import leaveServices from "../service/leaveServices";
import useLeaveRequestStore from "../store/leaveStore";

const useLeaveHook = () => {
  const fetchLeaves = async () => {
    const res = await leaveServices.getAllLeaves();
    return res;
  };

  const { setLeaveRequests, totalLeaves, setTotalLeaves } =
    useLeaveRequestStore();

  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useQuery("leaves", fetchLeaves);

  useEffect(() => {
    if (!isLoading && !isError) {
      setLeaveRequests(data);
      setTotalLeaves(data.length);
    }
  }, [isLoading, isError]);

  const statusChangeMutation = useMutation(
    (data) => {
      return leaveServices.handleStatusChange(data.id, data.action);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("leaves");
      },
    }
  );

  return {
    isError,
    isLoading,
    data,
    totalLeaves,
    statusChangeMutation,
  };
};

export default useLeaveHook;
