import { create } from "zustand";

const useEmployeeStore = create((set) => ({
  employees: [],

  totalEmp: 0,
  addEmployee: (newEmployees) =>
    set((state) => ({
      employees: newEmployees,
    })),

  addNewEmployee: (newEmployee) =>
    set((state) => ({
      employees: [...state.employees, newEmployee],
    })),
  updateEmployee: (updatedEmployee) =>
    set((state) => ({
      employees: state.employees.map((employee) =>
        employee._id === updatedEmployee._id ? updatedEmployee : employee
      ),
    })),

  deleteEmployee: (employeeId) =>
    set((state) => ({
      employees: state.employees.filter(
        (employee) => employee._id !== employeeId
      ),
    })),
  setTotalEmp: (totalCount) => set({ totalEmp: totalCount }),
}));

export default useEmployeeStore;
