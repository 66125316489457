import EditNoteIcon from "@mui/icons-material/EditNote";
import { CircularProgress, Pagination, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import useTaskMangementHook from "./TaskManagement.hooks";
import useUserStore from "./../../store/userStore";
import useReportHook from "../../hooks/ReportHook";
import DoneIcon from "@mui/icons-material/Done";
import Loading from "../protectedPages/Loading";
import Error from "../protectedPages/Error";
import { EditTaskModal } from "./Modal/EditTaskModal";

export function TaskManagement() {
  const user = useUserStore((state) => state.user);

  const { tasks, isLoading } = useTaskMangementHook(user?._id);

  const [inputComment, setInputComment] = useState("");
  const [currentTaskIndex, setCurrentTaskIndex] = useState(null);

  const { addReportUserMutation } = useReportHook();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(7);

  const totalPages = Math.ceil(tasks?.length / pageSize);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);

  const handleEditClick = (task) => {
    setCurrentTask(task);
    setEditModalOpen(true);
  };

  const handleSubmit = async (task) => {
    const reportData = {
      project: task.projectId._id,
      taskAssigned: task?.task,
      taskCompleted: inputComment,
      createdBy: user._id,
    };

    addReportUserMutation.mutate(reportData);
    setInputComment("");
  };

  const renderTask = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const task = tasks?.slice(startIndex, endIndex);

    return task?.map((report, index) => {
      const serialNumber = startIndex + index + 1;

      return (
        <tr key={report?._id}>
          <td>{serialNumber}</td>
          <td>{report?.projectId?.projectName}</td>
          <td>{report?.projectId?.projectStartDate?.substring(0, 10)}</td>
          <td>{report?.projectId?.projectEndDate?.substring(0, 10)}</td>
          <td>{report?.projectId?.status}</td>
          <td>{report?.task}</td>
          <td>{report?.start_date?.substring(0, 10)}</td>
          <td>{report?.end_date?.substring(0, 10)}</td>
          <td style={{color:report?.status==='Completed'?'green':report?.status==='Pending'?'red':'blue'}}>{report?.status}</td>
          {/* <td>{report?.completed}</td> */}
          {/* <td>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <input
                type="text"
                placeholder="Add Report..."
                value={index === currentTaskIndex ? inputComment : ""}
                onChange={(e) => {
                  setInputComment(e.target.value);
                  setCurrentTaskIndex(index);
                }}
              />
              <DoneIcon
                style={{
                  cursor: "pointer",
                  color: "#4CAF50",
                  fontSize: "28px",
                }}
                onClick={() => {
                  handleSubmit(report);
                }}
              />
            </div>
          </td> */}

          <td>
            <EditNoteIcon
              style={{
                cursor: "pointer",
                color: "#085394",
                fontSize: "27px",
              }}
              onClick={() => {
                handleEditClick(report);
              }}
            />
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <h3>Task Management</h3>
      <div>
        <div
          className="table-container"
          style={{
            padding: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "2px solid gray",
              marginBottom: "10px",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                fontWeight: "bold",
              }}
            >
              Your Tasks
            </h5>
          </div>
          <table>
            <thead
              style={{
                color: "#c0c3c7",
                fontWeight: "bold",
              }}
            >
              <tr
                style={{
                  backgroundColor: "#f1f4f7",
                  fontWeight: "bold",
                }}
              >
                <th>S.No.</th>
                <th>Project Name</th>
                <th>Project Start Date</th>
                <th>Project End Date</th>
                <th>Project Status</th>
                <th>Task Assigned</th>
                <th>Task Start Date</th>
                <th>Task End Date</th>
                <th>Status</th>
                {/* <th>Task Completed</th> */}
                {/* <th>Add Report</th> */}
                <th>Edit Task</th>
              </tr>
            </thead>
            <tbody className="tableBody">
              {tasks != "" ? (
                renderTask()
              ) : (
                <tr>
                  <td colSpan="12">"No Task Assigned"</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Stack spacing={2} className="pagination-container">
          <Typography>
            Page: {currentPage} of {totalPages}
          </Typography>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(event, value) => setCurrentPage(value)}
            color="primary"
          />
        </Stack>

        {isLoading && <Loading />}
      </div>

      <EditTaskModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        task={currentTask}
      />
    </>
  );
}
