import { useMutation, useQuery, useQueryClient } from "react-query";
import reportServices from "../../service/reportServices";

const useAccessReportsHooks = () => {
  const queryClient = useQueryClient();
  const {
    data: accessReports,
    isLoading,
    isError,
    refetch,
  } = useQuery(["accessReports"], () => {
    return reportServices.getAccessReports();
  });

  const removeAccessMutation = useMutation(
    (data) => {
      return reportServices.removeAccess(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["accessReports"]);
      },
    }
  );

  return {
    accessReports,
    isLoading,
    isError,
    removeAccessMutation,
    refetch,
  };
};

export default useAccessReportsHooks;
