import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TableModal } from "./TableModal";
import { EditReportModalUser } from "./EditReportModalUser";
import useReportHook from "../../hooks/ReportHook";
import Error from "../protectedPages/Error";
import Loading from "../protectedPages/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { DataTable } from "../../../PageResponsibility/src/admin/components/table/Data-Table";
import { Button } from "../../../PageResponsibility/src/admin/components/ui/button";
import { baseUrlUser } from "../../config/baseUrl";
import axios from '../../../PageResponsibility/src/admin/config/axiosAuth'
import moment from "moment";
import DatePicker from "react-datepicker";
import { months } from "../../../PageResponsibility/src/utils/lib";
import { CommentDialog } from "../../components/modals/CommentDialog";
import { AddCommentModal } from "../../components/modals/AddCommentModal";
export const UserAccessReportById = ({ rights }) => {
  const {
    accessedUsers,
  } = useReportHook();
const navigate=useNavigate()
  const rightFor = (id) => {
    const right = rights?.find((right) => right.employeeId == id);
    return right ? right.accessType : [];
  };
  console.log(accessedUsers, "accessedUsers");
  const [reports, setReports] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const params = useParams();
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), selectedMonth - 1, 1)
  );
  const [endDate, setEndDate] = useState(new Date());
  const getMonthlyReports = async () => {
    const year = startDate.getFullYear();
    try {
      const response = await axios.post(baseUrlUser + "/monthly-reports", {
        employeeId: params.id,
        month: selectedMonth,
        year: year,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
      });
      setReports(response.data);
      console.log(response, "monthlyreports");
    } catch (err) {}
  };
  useEffect(() => {
    getMonthlyReports();
  }, [startDate, selectedMonth, endDate]);
  
  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value));
  };
  const reportColumns= [
    {
      header: "S.No",
      cell: ({ row }) => {
        return <span>{row.index + 1}</span>;
      },
    },
 
    {
      // accessorKey: "createdAt",
      cell: ({ row }) => moment(row.original.date).format("YYYY-MM-DD"),
      header: "Date ",
    },
    {
      header: "Reports",
      cell:({row})=> row.original?.reports?.[0]?.report?  <button
      onClick={() => navigate('/viewReport',{state:{
        data:row?.original?.reports
      }})}
      style={{
        width: "100px",
        padding: "0",
        fontSize: "11px",
      }}
      className="button-17"
    >
      View Report
    </button>:'No-reports'
    },
    {
      header: "Add Comment",
      cell: ({ row }) => (
        <AddCommentModal
          id={row.original._id}
          model={'report'}
        />
      ),
    },
    {
      header: "Comment",
  
      cell: ({ row }) => {
        return (
          <CommentDialog id={row.original._id} model='report' />
        );
      },
    },
  
   
  ];
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <select
          style={{ width: "10vw" }}
          name="month"
          id="Select"
          value={selectedMonth}
          onChange={handleMonthChange}
        >
          {months?.map((item) => (
            <option value={item.value}>{item.label}</option>
          ))}
        </select>

        <div style={{ width: "10vw" }}>
          <DatePicker
             dateFormat="dd/MM/yyyy"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Start Date"
          />
        </div>
        <div style={{ width: "10vw" }}>
          <DatePicker
             dateFormat="dd/MM/yyyy"
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="End Date"
          />
        </div>
      </div>
      <DataTable
        columns={reportColumns}
        data={reports || []}
        defaultTheme={true}
        heading={"Access Reports"}
      />
    </>
  );
};
