import React, { useState, useEffect } from "react";
// import ReactApexChart from 'react';
import CanvasJSReact from "@canvasjs/react-charts";
import axios from "../../config/axiosAuth";
import { baseUrl } from "../../config/baseUrl";
import useProjectHook from "../../hooks/useProjectHook";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const PercentageOverTime = () => {
  const [chartData, setChartData] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState("");

  const { data: projects } = useProjectHook();
  useEffect(() => {
    if (Array.isArray(projects) && projects.length > 0) {
      const SelectedProjectId = projects[0]?._id;
      setSelectedProjectId(SelectedProjectId);
    }
  }, [projects]);

  useEffect(() => {
    if (selectedProjectId !== "") {
      fetchProjectPercentageOverTimeByProjectId();
    }
  }, [selectedProjectId]);

  const fetchProjectPercentageOverTimeByProjectId = () => {
    axios
      .post(baseUrl + "/projectOvertime", { projectId: selectedProjectId })
      .then((res) => {
        const dataPoints = Object.entries(res.data?.result)?.map(
          ([key, value]) => ({
            label: key,
            y: value.completedTasks,
          })
        );

        setChartData(dataPoints);
      })
      .catch((err) => {
      });
  };

  const options = {
    animationEnabled: true,
    height: 240,
    padding: "10px",
    title: {},
    subtitles: [
      {
        text: "Percentage Over Time",
        fontSize: 12,
        fontWeight: "normal",
        textAlign: "left",
        horizontalAlign: "left",
        margin: 25,
      },
    ],
    axisX: {
      labelAngle: 0,
      labelTextAlign: "center",
      title: "Department",
      interval: 1,
      gridDashType: "line",
      gridColor: "lightgrey",
      gridThickness: 1,
      labelFontColor: "lightgrey",
      titleFontColor: "grey",
      titleFontSize: 12,
      titleFontWeight: "Bold",
      lineColor: "lightgrey",
    },

    axisY: {
      labelFontColor: "lightgrey",
      gridDashType: "line",
      title: "Percentage",
      gridColor: "lightGrey",
      gridThickness: 1,
      tickColor: "lightGrey",
      tickThickness: 1,
      tickLength: 10,
      titleFontColor: "grey",
      titleFontSize: 12,
      titleFontWeight: "Bold",
      lineColor: "lightgrey",
    },
    data: [
      {
        type: "line",
        color: "lightgreen",
        showInLegend: false,
        dataPoints: chartData,
      },
    ],
  };
  return (
    <>
      <div id="chart">
        <select
          style={{
            width: "10vw",
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "10px",
            marginLeft: "auto",
          }}
          value={selectedProjectId}
          onChange={(e) => setSelectedProjectId(e.target.value)}
        >
          <option value="0">Select Project</option>
          {projects?.map((project) => (
            <option key={project.id} value={project._id}>
              {project.projectName}
            </option>
          ))}
        </select>
        <CanvasJSChart options={options} />
      </div>
    </>
  );
};

export default PercentageOverTime;
