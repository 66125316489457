import { toast } from "react-toastify";

import reportServices from "../services/reportServices";
import { useUserReportStore } from "./../store/userReports";
import { useNavigate } from "react-router-dom";

const { useQuery, useQueryClient, useMutation } = require("react-query");

const useReportHook = () => {
  const queryClient = useQueryClient();
const navigate=useNavigate()
  const { setReports, setTotalReports } = useUserReportStore();
const acessReportById=async() =>{
  const res = await reportServices.getAllReportUser();
  return res;
}
  const fetchAllReportsUser = async () => {
    const res = await reportServices.getAllReportUser();
    return res;
  };

  const fetchUserAccessReports = async () => {
    const res = await reportServices.getUserAccessReport();
    return res;
  };

  const { data, isError, isLoading } = useQuery(
    "userReports",
    fetchAllReportsUser,
    {
      onSuccess: (data) => {
        setReports(data);
        setTotalReports(data.length);
      },
    }
  );
const fetchUserAccessReportsUsers = async () => {
  const res = await reportServices.getAccessReportsUser();
  return res;
};
  const {
    data: userAccessReports,
    isLoading: isUserAccessReportLoading,
    isError: isUserAccessReportError,
  } = useQuery("userAccessReports", fetchUserAccessReports);

  const {
    data: accessedUsers,
    isLoading: accessedUsersLoading,
    isError: accessedUsersReportError,
  } = useQuery("accessedUsers", fetchUserAccessReportsUsers);

  const addReportUserMutation = useMutation(
    (data) => {
      return reportServices.addReportUser(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("userReports");
        navigate('/userReports')
      },
    }
  );
  const editAccessReportUserMutation = useMutation(
    (data) => {
      return reportServices.editAccessReportUser(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("userReports");
        queryClient.invalidateQueries("userAccessReports");
        navigate('/userReports')

      },
    }
  );

  const editReportUserMutation = useMutation(
    (data) => {
    return reportServices.editReportUser(data.id, data.data);
    
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("userReports");
      }
    }
  );

  const editCommentCommonMutation = useMutation(
    (data) => {
      return reportServices.editCommentCommon(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("userAccessReports");
      },
    }
  );

  const deleteCommentCommanMutation = useMutation(
    (data) => {
      return reportServices.deleteCommentCommon(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("userAccessReports");
      },
    }
  );

  const submitCommentCommonMutation = useMutation(
    (data) => {
      return reportServices.submitCommentCommon(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("userAccessReports");
      },
    }
  );

  return {
    data,
    isError,
    isLoading,
    addReportUserMutation,
    userAccessReports,
    isUserAccessReportLoading,
    isUserAccessReportError,
    deleteCommentCommanMutation,
    editCommentCommonMutation,
    submitCommentCommonMutation,
    editReportUserMutation,
    editAccessReportUserMutation,
    accessedUsers
  };
};

export default useReportHook;
