import React, { useEffect, useRef } from "react";
import io from "socket.io-client";
import "./home.scss";
import { AppContainer } from "./AppContainer";

const App = () => {
  return (
    <div className="app ">
      <AppContainer />
    </div>
  );
};

export default App;
