import { useMutation, useQuery, useQueryClient } from "react-query";
import followUpServices from "./followUpServices";
const useFollowUpHooks = () => {
  const queryClient = useQueryClient();

  const {
    data: followUps,
    isLoading,
    isError,
  } = useQuery(["followUps"], () => {
    return followUpServices.getFollowUps();
  });

  const addFollowUpMutation = useMutation(
    (data) => {
      return followUpServices.addFollowUp(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("followUps");
      },
    }
  );

  const editFollowUpMutation = useMutation(
    ({ id, data }) => {
      return followUpServices.editFollowUp(id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("followUps");
      },
    }
  );

  const deleteFollowUpMutation = useMutation((id) => {
    return (
      followUpServices.deleteFollowUp(id),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("followUps");
        },
      }
    );
  });

  return {
    followUps,
    isLoading,
    isError,
    addFollowUpMutation,
    editFollowUpMutation,
    deleteFollowUpMutation,
  };
};

export default useFollowUpHooks;
