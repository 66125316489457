/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { Textarea } from "../ui/textarea";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "../../components/ui/dialog";
import { useForm } from "react-hook-form";
import { Button } from "../ui/button";
import axios from "../../config/axiosAuth";
import { baseUrl, baseUrlUser } from "../../config/baseUrl";
import { toast } from "react-toastify";
import useAddComment from "../../../Mutation/ReportMutation";
import useUserStore from "../../store/userStore";
export const AddCommentModal = ({ id, model}) => {
  const { submitComment } = useAddComment();
  const { register, handleSubmit, formState, reset } = useForm();
const[open,setOpen]=useState(false)
  const { errors } = formState;
  const{user}=useUserStore()
  const onSubmit = (data) => {
    axios
      .post(baseUrlUser + '/add-comment', {
        employeeId: user._id,
        comment: data.comment,
        isAdmin:false,
        model:model,
        id:id
      })
      .then((res) => {
        setOpen(false)
        reset()
      })

      .catch((err) => {
        throw new Error(err)
      });
  };

  const handleleadaddcomment = () => {};

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <button className="button-17 !rounded-[10px]">Add Comment</button>
      </DialogTrigger>

      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} className="grid gap-3">
          <DialogHeader>Add Comment</DialogHeader>
          <Textarea
            className="resize-none"
            placeholder="Add a comment"
            {...register("comment", {
              required: "This field is required",

              minLength: {
                value: 5,
                message: "Minimum length is 5",
              },

              maxLength: {
                value: 100,
                message: "Maximum length is 100",
              },

              validate: (value) => {
                return value.trim() !== "" || "This field is required";
              },
            })}
          />

          {errors.comment && (
            <span className="error-message">{errors.comment.message}</span>
          )}

          <DialogFooter>
            {formState.isValid && (
             
                <Button onClick={handleleadaddcomment} type="submit">
                  Save
                </Button>
            
            )}

            {!formState.isValid && <Button type="submit">Save</Button>}

            <DialogClose asChild>
              <Button>Close</Button>
            </DialogClose>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
