import { baseUrlUser } from "../config/baseUrl";
import axios from "./../config/axiosAuth";

const commentServices = {
  postComment: async (data) => {
    const response = await axios.post(baseUrlUser + "/addCommentCC", data);
    return response.data.result;
  },

  editComment: async (data) => {
    const response = await axios.post(baseUrlUser + "/editCommentCC", data);
    return response.data.result;
  },

  deleteComment: async (data) => {
    const response = await axios.post(baseUrlUser + "/deleteCommentCC", data);
    return response.data.result;
  },
};

export default commentServices;
