import React, { useEffect, useState } from "react";
import { DataTable } from "../../components/table/Data-Table";
import { baseUrl } from "../../config/baseUrl";
import axios from "../../config/axiosAuth";
import { hexToRGBA } from "../../../utils/lib";
import moment from "moment";
import useProjectHook from "../../hooks/useProjectHook";
const EmployeeColumn = [
    {
      header: "S.No",
      cell: ({ row }) => <span>{row.index + 1}</span>,
    },
    {
      header: "Project Name",
      cell: ({ row }) => row?.original?.projectName,
    },
    {
      header: "Project StartDate",
      cell: ({ row }) =>
        moment(row?.original?.projectStartDate).format("DD-MM-YYY"),
    },
    {
      header: "Project EndDate",
      cell: ({ row }) =>
        moment(row?.original?.projectEndDate).format("DD-MM-YYY"),
    },
    {
      header: "Project Status",
  
      cell: ({ row }) => (
        <div
          className="w-max rounded-xl h-[40px] text-center p-[10px] m-auto flex items-center justify-center "
          style={{ backgroundColor:hexToRGBA( row?.original.colorCode,0.3) }}
        >
        <p className="text-[14px]">{  row?.original?.status}</p>
        </div>
      ),
    },
  ];
const CompletedProjects = () => {
const {totalCompletedProjects,completedData}=useProjectHook()


  return <DataTable columns={EmployeeColumn} data={completedData?.project} defaultTheme={true} />;
};

export default CompletedProjects;
