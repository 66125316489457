import { toast } from "react-toastify";
import axios from "../config/axiosAuth";
import { baseUrlCommon, baseUrlUser } from "../config/baseUrl";

const taskServices = {
  getTask: async (id) => {
    const response = await axios.post(baseUrlUser + "/getAllTask", {
      employeeId: id,
    });
    return response.data;
  },

  editTask: async (data) => {
    const response = await axios.put(
      baseUrlUser + "/editTask/" + data._id,
      data.data
    );


    return response.data;
  },
};

export default taskServices;
