import React, { useState } from "react";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  isSameMonth,
  isSameDay,
  addMonths,
  subMonths,
} from "date-fns";
import "./style.css";

const Calendar = ({ events }) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const handlePrevMonth = () => {
    setCurrentDate(subMonths(currentDate, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };

  const monthStart = startOfMonth(currentDate);
  const monthEnd = endOfMonth(currentDate);
  const daysInMonth = eachDayOfInterval({ start: monthStart, end: monthEnd });

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <div className="calendar">
        <div>
          <div
            className="header"
            style={{
              textAlign: "start",
            }}
          >
            Calendar
          </div>
          <div className="divider"></div>
        </div>
        <div className="nav-buttons">
          <div className="month">{format(currentDate, "MMMM yyyy")}</div>
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <div className="nav-button" onClick={handlePrevMonth}>
              &lt; Prev
            </div>
            <div className="nav-button" onClick={handleNextMonth}>
              Next &gt;
            </div>
          </div>
        </div>

        <div className="days">
          {daysInMonth.map((day) => {
            const formattedDate = format(day, "yyyy-MM-dd");
            const event = events?.find((e) => e.date === formattedDate);

            return (
              <div
                title={event?.description}
                key={day.toISOString()}
                className={`day ${
                  isSameMonth(day, currentDate) ? "current-month" : ""
                } ${event ? `category-${event.category}` : ""}`}
              >
                {format(day, "d")}
                {event && (
                  <div className="event-info">
                    <div className={`event-category ${event.category}`}></div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
