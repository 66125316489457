import DoneIcon from "@mui/icons-material/Done";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import CommentModal from "../../components/table/commentTable";
import { useCommentHook } from "../../hooks/useCommentHook";
import useUserStore from "../../store/userStore";
import useAttendanceHook from "./attendance.hook";
import { DailyLogs } from "../../components/table/DailyLogsModal";
import { DataTable } from "../../../PageResponsibility/src/admin/components/table/Data-Table";
import { MonthlyColumn } from "./columns";
import DatePicker from "react-datepicker";
import { months } from "../../../PageResponsibility/src/utils/lib";
import moment from "moment";

const Attendance = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [startDate, setStartDate] = useState(new Date(currentYear, selectedMonth - 1, 1));
  const [endDate, setEndDate] = useState(currentDate);

  useEffect(() => {
    // Update startDate to first day of selected month
    const newStartDate = new Date(currentYear, selectedMonth - 1, 1);
    setStartDate(newStartDate);

    // Update endDate
    if (selectedMonth === currentMonth) {
      // If current month, set to current date
      setEndDate(currentDate);
    } else {
      // If not current month, set to last day of selected month
      const lastDay = new Date(currentYear, selectedMonth, 0);
      setEndDate(lastDay);
    }
  }, [selectedMonth, ]);
  const { user } = useUserStore();
  const body = {
    // employeeId: params.id,
    month: selectedMonth,
    year: startDate.getFullYear(),
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: moment(endDate).format("YYYY-MM-DD"),
  };
  const addCommentMutation = useCommentHook();

  const [currentPage, setCurrentPage] = useState(1);

  const { data: attendance, refetch } = useAttendanceHook(body);

  const pageSize = 8;
  const totalPages = Math.ceil(attendance?.length / pageSize);

  const [inputComment, setInputComment] = useState("");
  const [currentTaskIndex, setCurrentTaskIndex] = useState(null);

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const [dailyLogModal, setDailyLogModal] = useState(false);
  const [logs, setLogs] = useState(null);

  const openDailyLogModal = (data) => {
    setLogs(data);
    setDailyLogModal(true);
  };

  const openCommentModal = (emp) => {
    refetch();
    setIsCommentModalOpen(true);
    setSelected(emp);
  };

  const handleAddComment = (modalName, modalId) => {
    addCommentMutation.mutate({
      comment: inputComment,
      commentBy: user._id,
      modelName: modalName,
      modelId: modalId,
    });

    setInputComment("");
    refetch();
  };
  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value));
  };

  return (
    <>
     <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <select
          style={{ width: "10vw" }}
          name="month"
          id="Select"
          value={selectedMonth}
          onChange={handleMonthChange}
        >
          {months?.map((item) => (
            <option value={item.value}>{item.label}</option>
          ))}
        </select>

        <div style={{ width: "10vw" }}>
          <DatePicker
             dateFormat="dd/MM/yyyy"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="Start Date"
          />
        </div>
        <div style={{ width: "10vw" }}>
          <DatePicker
             dateFormat="dd/MM/yyyy"
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="End Date"
          />
        </div>
      </div>
      <>
       <DataTable data={attendance||[]} columns={MonthlyColumn} defaultTheme={true} heading='Attendance'/>
      </>
      <CommentModal
        isOpen={isCommentModalOpen}
        onRequestClose={() => setIsCommentModalOpen(false)}
        commentData={selected}
        modalName="attendance"
      />
      <DailyLogs
        isOpen={dailyLogModal}
        onClose={() => setDailyLogModal(false)}
        data={logs}
      />
    </>
  );
};

export default Attendance;
