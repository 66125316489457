import React, { useEffect, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import axios from "../../config/axiosAuth";
import { baseUrl } from "../../config/baseUrl";
import useProjectHook from "../../hooks/useProjectHook";
import { lightGreen } from "@mui/material/colors";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Growthchart = ({ projectId }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (projectId !== "") {
      fetchGrowthByProject();
    }
  }, [projectId]);

  const fetchGrowthByProject = async () => {
    try {
      const response = await axios.post(baseUrl + "/projectwiseGrowth", {
        projectId: projectId,
      });
      const monthlyGrowth = response.data.monthlyTeamGrowth;
      const months = {};
      for (const [month, data] of Object.entries(monthlyGrowth)) {
        for (const [department, growth] of Object.entries(data)) {
          if (!months[month]) {
            months[month] = [];
          }
          months[month].push({ label: department, y: parseFloat(growth) });
        }
      }

      const formattedData = Object.entries(months).map(
        ([month, dataPoints]) => ({
          type: "splineArea",
          name: month,
          showInLegend: true,
          color: "lightGreen",
          dataPoints: dataPoints,
        })
      );

      setData(formattedData);
    } catch (err) {
    }
  };

  useEffect(() => {
    if (data) {
      setChartData(data);
    }
  }, [data]);
  // const fetchGrowthByProject = async () => {
  //   try {
  //     const response = await axios.post(baseUrl + "/projectwiseGrowth", {
  //       projectId: projectId,
  //     });
  //     const monthlyGrowth = response.data.monthlyTeamGrowth;
  //     const firstKey = Object.keys(monthlyGrowth)[0];
  //     setData(monthlyGrowth[firstKey]);
  //   } catch (err) {
  //   }
  // };
  // useEffect(() => {
  //   if (data) {
  //     const parsedData = Object.entries(data).map(([department, growth]) => ({
  //       label: department,
  //       y: parseFloat(growth),
  //     }));
  //     setChartData(parsedData);
  //   }
  // }, [data]);

  const [chartData, setChartData] = useState([]);

  const options = {
    animationEnabled: true,
    height: 350,
    padding: "10px",
    axisX: {
      title: "Department",
      labelAngle: 0,
      labelTextAlign: "center",
      interval: 1,
      gridDashType: "line",
      gridColor: "lightgrey",
      gridThickness: 1,
      labelFontColor: "lightgrey",
      titleFontColor: "grey",
      titleFontSize: 16,
      titleFontWeight: "Bold",
      lineColor: "lightgrey",
    },
    axisY: {
      labelFontColor: "lightgrey",
      gridDashType: "line",
      title: "Growth Percentage",
      gridColor: "lightGrey",
      gridThickness: 1,
      tickColor: "lightGrey",
      tickThickness: 1,
      tickLength: 10,
      titleFontColor: "grey",
      titleFontSize: 16,
      titleFontWeight: "Bold",
      lineColor: "lightgrey",
    },
    data: data,

    // type: "splineArea",
    // color: "lightgreen",
    // showInLegend: true,
    // {dataPoints: chartData,}
  };

  return (
    <div className="w-full min-h-[300px] h-[80%]">
      {chartData && chartData.length > 0 ? (
        <CanvasJSChart options={options} />
      ) : (
        <div className="w-full text-center flex items-center justify-center text-[lightgrey] m-auto h-full">
          <p>NO DATA FOUND FOR THE SELECTED PROJECT</p>
        </div>
      )}
    </div>
  );
};

export default Growthchart;
