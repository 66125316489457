import reportServices from "../service/reportServices";

const { useQuery, useQueryClient, useMutation } = require("react-query");

const useReportHook = () => {
  const queryClient = useQueryClient();

  const fetchAllReportsAdmin = async () => {
    const res = await reportServices.getAllReportAdmin();
    return res;
  };

  const { data, isError, isLoading } = useQuery(
    "allReports",
    fetchAllReportsAdmin
  );

  const giveAccessToReportMutation = useMutation(
    reportServices.giveAccessToReport,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("allReports");
        queryClient.invalidateQueries(["accessReports"]);
      },
    }
  );

  return {
    data,
    isError,
    isLoading,
    giveAccessToReportMutation,
  };
};

export default useReportHook;
