import React, { useEffect, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import axios from "../../config/axiosAuth";
import { baseUrl } from "../../config/baseUrl";

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Sales = () => {
  const [chartData, setChartData] = useState([
    { y: 1400, label: "Projects" },
    { y: 1212, label: "Clients" },
    { y: 1080, label: "Proposals" },
    { y: 665, label: "Negotiation" },
  ]);

  useEffect(() => {
    handleSalesChart();
  }, []);

  const handleSalesChart = () => {
    axios
      .post(baseUrl + "/getLeadsChart")
      .then((res) => {

        const transformedData = Object.entries(res.data?.statusPercentages).map(
          ([label, value]) => ({ y: parseFloat(value), label: label })
        );
        setChartData(transformedData);
      })
      .catch((err) => {
      });
  };

  const calculatePercentages = (dataPoints) => {
    const total = dataPoints[0].y;
    return dataPoints.map((point, index) => ({
      ...point,
      percentage: index === 0 ? 100 : ((point.y / total) * 100).toFixed(2),
    }));
  };

  const options = {
    animationEnabled: true,
    height: 300,
    width: 340,
    title: {
      text: "Sales Analysis",
    },
    data: [
      {
        type: "funnel",
        toolTipContent: "<b>{label}</b>: {y} <b>({percentage}%)</b>",
        indexLabelPlacement: "inside",
        indexLabel: "{label} ({percentage}%)",
        dataPoints: chartData,
      },
    ],
  };

  return (
    <>
      <CanvasJSChart options={options} />
    </>
  );
};

export default Sales;
