import React from "react";
import Modal from "react-modal";

export const DailyLogs = ({ isOpen, onClose, data }) => {
  function formatDate(dateString) {
    const dateObject = new Date(dateString);
    return dateObject.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Employee Comment Modal"
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      {data && (
        <div>
          <div
            style={{
              padding: "20px",
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "2px solid gray",
                marginBottom: "10px",
                alignItems: "center",
              }}
            >
              <h5
                style={{
                  fontWeight: "bold",
                }}
              >
                Employees
                <span className="close" onClick={onClose}>
                  &times;
                </span>
              </h5>
            </div>
            <table>
              <thead>
                <tr
                  style={{
                    backgroundColor: "#f1f4f7",
                    color: "gray",
                  }}
                >
                  <th>CheckIn</th>
                  <th></th>
                  <th></th>
                  <th>CheckOut</th>
                </tr>
              </thead>
              <tbody className="tableBody">
                {data?.map((receiver) => (
                  <tr key={receiver?._id}>
                    {/* <td>{receiver?.loggedIn.substring(0, 10)}</td>
                    <td>{receiver?.loggedOut?.substring(0, 10)}</td> */}
                    <td>{formatDate(receiver?.loggedIn)}</td>
                    <td></td>
                    <td></td>
                    <td>{formatDate(receiver?.loggedOut)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </Modal>
  );
};
