import React from "react";
import AccessReportsTakeAccess from "./AccessReports";
import ResponsibilityForTabs from "./TabsResponsibilty";

function Responsibilty() {
  return (
    <div>
      <ResponsibilityForTabs />
      <AccessReportsTakeAccess />
    </div>
  );
}

export default Responsibilty;
