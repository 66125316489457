// import axios from "";
import axios from "../../config/axiosAuth";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/dialog";
import { baseUrl } from "../../config/baseUrl";
import react, { useEffect, useState } from "react";
import { Eye } from "lucide-react";
import { DataTable } from "../../components/table/Data-Table";
import FileViewer from 'react-file-viewer';

export function PreviewDocument({ path }) {

  const [objectURL, setObjectURL] = useState(null);

function getFileExtension(url) {
    const parts = url.split('.');
    if (parts.length > 1) {
      return parts.pop().split('?')[0]; // In case there are query parameters
    }
    return '';
  }


  return (
    <Dialog className=" min-w-[90%] min-h-[90%]">
      <DialogTrigger asChild>
        <div className="w-full flex items-center justify-center min-w-[90%] min-h-[90%]">
          <Eye size={20} color={"lightgrey"} />
        </div>
      </DialogTrigger>
      <DialogContent className=" min-w-[90%] min-h-[90%]">
       
          <FileViewer
            fileType={getFileExtension(path)}
            filePath={path}
          />
        
      </DialogContent>
    </Dialog>
  );
}