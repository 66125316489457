import { create } from "zustand";

export const useClientStore = create((set) => ({
  clients: [],
  totalClientCount: 0,
  getClient: (newClients) =>
    set((state) => ({
      clients: newClients,
    })),
  updateClient: (updatedClient) =>
    set((state) => ({
      clients: state.clients.map((client) =>
        client.clientId === updatedClient.clientId ? updatedClient : client
      ),
    })),

  deleteClient: (clientId) =>
    set((state) => ({
      client: state.client.filter((client) => client.clientId !== clientId),
    })),
  setTotalClientCount: (totalCount) => set({ totalClientCount: totalCount }),
}));

export default useClientStore;
