import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import React, { useState } from "react";
import Modal from "react-modal";
import NotificationHook from "../../hooks/notificationHooks";
import Loading from "../protectedPages/Loading";
import "./sendNotification.css";
import { formatDateTime } from "../../../utils/lib";
import { DataTable } from "../../components/table/Data-Table";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/dialog";

const NotificationList = () => {
  const { allNotifications, specificNotification, isLoading } =
    NotificationHook();

  if (isLoading) {
    return <Loading />;
  }

  const allNotificationColumns = [
    {
      header: "S.No.",
      cell: ({ row }) => row.index + 1,
    },
    {
      header: "Title",
      accessorKey: "title",
    },

    {
      header: "Body",
      accessorKey: "body",
    },
    {
      header: "Created At",
      accessorKey: "createdAt",
      cell: ({ row }) => formatDateTime(row.original.createdAt),
    },
  ];

  const specificNotificationsColumns = [
    {
      header: "S.No.",
      cell: ({ row }) => row.index + 1,
    },
    {
      header: "Title",
      accessorKey: "title",
    },

    {
      header: "Body",
      accessorKey: "body",
    },
    {
      header: "Receiver",
      cell: ({ row }) => {
        return <ReceiverDialog data={row.original} />;
      },
    },
    {
      header: "Created At",
      accessorKey: "createdAt",
      cell: ({ row }) => formatDateTime(row.original.createdAt),
    },
  ];

  return (
    <>
      <h5>All Notifications</h5>

      <DataTable
        columns={allNotificationColumns}
        data={allNotifications||[]}
        className="min-h-[35vh]"
        defaultTheme={true}
      />

      <h5>Employee Notifications</h5>

      <DataTable
        columns={specificNotificationsColumns}
        data={specificNotification||[]}
        defaultTheme={true}
      />
    </>
  );
};

export default NotificationList;

const ReceiverDialog = ({ data }) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <button className="button-17 text-xs w-28 !rounded-[10px]" variant="outline">
          Receivers
        </button>
      </DialogTrigger>
      <DialogContent>
        <div>
          <div
            style={{
              padding: "20px",
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "2px solid gray",
                marginBottom: "10px",
                alignItems: "center",
              }}
            ></div>
            <table>
              <thead>
                <tr
                  style={{
                    backgroundColor: "#f1f4f7",
                    color: "gray",
                  }}
                >
                  <th>Name</th>
                  <th>Employee ID</th>
                  <th>Designation</th>
                </tr>
              </thead>
              <tbody className="tableBody">
                {data?.receivers?.length > 0 ? (
                  data?.receivers?.map((receiver) => (
                    <tr key={receiver?._id}>
                      <td>{receiver?.name}</td>
                      <td>{receiver?.employeeId}</td>
                      <td>{receiver?.designation?.name}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No Receivers</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
