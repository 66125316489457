import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import authService from "../services/authServicesUser";

const useAuthHook = () => {
  const navigate = useNavigate();
  const GetToken = async () => {
    const res = await authService.zegoToken();
    return res;
  };

 
  const loginEmployeeMutation = useMutation(
    (data) => authService.loginEmployee(data?.userEmail, data?.userPassword,data?.latitude,data?.longitude),
    {
      onSuccess: (response) => {
        if (response) {
          localStorage.setItem("token", response.data.token);
    localStorage.setItem("sessionId",response.data.sessionId)

          
        }
      },
    },
    
  );
  // const {
  //   data: zegoToken,
  //   isLoading,
  //   isError,
  // } = useQuery("zegoToken", GetToken, {
  //   onSuccess: (data) => {
  //     console.log(data, "zegodataaa");
  //     localStorage.setItem("zegoToken", data.token);
      
  //   },})
 

  return {
    loginEmployeeMutation,
  };
};

export default useAuthHook;
