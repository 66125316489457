import React, { useState } from "react";
import { useForm } from "react-hook-form";
import useHiringHook from "../../../hooks/useHiringHook";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTrigger,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import { AddIcon } from "../../../components/ui/icons";
import { Input } from "../../../components/ui/input";

export const AddHiringModal = () => {
  const { register, handleSubmit, reset, formState } = useForm();
  const[open,setOpen]=useState(false)

  const { errors } = formState;

  const { addHiringMutation } = useHiringHook();

  const addHiring = (data) => {
    addHiringMutation.mutate(data);
    reset();
    setOpen(false)
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="flex text-sm items-center gap-2">
        <span> Add Hiring</span>
        <AddIcon />
      </DialogTrigger>
      <DialogContent>
        <h2>Add Hiring</h2>
        <form onSubmit={handleSubmit(addHiring)}>
          <div>
            <label htmlFor="position">Position:</label>
            <Input
              type="text"
              id="position"
              name="position"
              placeholder="Position"
              {...register("position", {
                required: {
                  value: true,
                  message: "Position is required",
                },
                validate: (value) => {
                  const trimmedValue = value.trim();

                  if (trimmedValue.length < 5) {
                    return "Minimum length should be 5";
                  }

                  if (trimmedValue.length > 30) {
                    return "Maximum length should be 30";
                  }

                  if (!/^[a-zA-Z]+(\s+[a-zA-Z]+)*$/.test(trimmedValue)) {
                    return "Only alphabets are allowed with spaces in between, and no space as the first character";
                  }

                  return true;
                },
              })}
            />

            {errors.position && (
              <span className="error-message">{errors.position.message}</span>
            )}
          </div>
          <div>
            <label htmlFor="experience">Experience (years):</label>
            <Input
              type="number"
              id="experience"
              name="experience"
              placeholder="experience"

              {...register("experience", {
                required: {
                  value: true,
                  message: "Experience is required",
                },
                // pattern: {
                //   value: /^-?\d{1,2}(-\d{1,2})?$/,
                //   message: "Experience must be a number (negative values allowed)",
                // },
                minLength: {
                  value: 1,
                  message: "Minimum experience should be 1",
                },

                maxLength: {
                  value: 2,
                  message: "Maximum experience execeded",
                },
              })}
            />

            {errors.experience && (
              <span className="error-message">{errors.experience.message}</span>
            )}
          </div>
          <div>
            <label htmlFor="salary">Salary:</label>
            <Input
              type="text"
              id="salary"
              name="salary"
              placeholder="salary"

              {...register("salary", {
                required: {
                  value: true,
                  message: "Salary is required",
                },

               

                maxLength: {
                  value: 10,
                  message: "Maximum length should be 10",
                },

                minLength: {
                  value: 4,
                  message: "Minimum length should be 4",
                },
              })}
            />

            {errors.salary && (
              <span className="error-message">{errors.salary.message}</span>
            )}
          </div>
          <div>
            <label htmlFor="hiring_last_date">Start Date of Hiring:</label>
            <Input
              type="date"
              id="hiring_start_date"
              name="hiring_start_date"
              placeholder="Enter start Date"

              min={new Date().toISOString().split("T")[0]}
              {...register("hiring_start_date", {
                required: {
                  value: true,
                  message: "Start Date of Hiring is required",
                },
              })}
            />

            {errors.hiring_last_date && (
              <span className="error-message">
                {errors.hiring_last_date.message}
              </span>
            )}
          </div>
          <div>
            <label htmlFor="hiring_last_date">Last Date of Hiring:</label>
            <Input
              type="date"
              id="hiring_last_date"
              name="hiring_last_date"
              placeholder="Enter Last Date"

              min={new Date().toISOString().split("T")[0]}
              {...register("hiring_last_date", {
                required: {
                  value: true,
                  message: "Last Date of Hiring is required",
                },
              })}
            />

            {errors.hiring_last_date && (
              <span className="error-message">
                {errors.hiring_last_date.message}
              </span>
            )}
          </div>
          <div>
            <label htmlFor="noOfCandidates">Number of Candidates:</label>
            <Input
              type="number"
              id="noOfCandidates"
              name="noOfCandidates"
              placeholder="Number Of Candidates"

              {...register("noOfCandidates", {
                required: {
                  value: true,
                  message: "Number of Candidates is required",
                },

                minLength: {
                  value: 0,
                  message: "Minimum number of candidates should be 0",
                },

                maxLength: {
                  value: 10,
                  message: "Maximum number of candidates should be 10",
                },
              })}
            />

            {errors.noOfCandidates && (
              <span className="error-message">
                {errors.noOfCandidates.message}
              </span>
            )}
          </div>
          <div>
  <label htmlFor="hiringStatus">Hiring Status:</label>
  <select
    id="hiringStatus"
    name="hiringStatus"
    {...register("hiringStatus", {
      required: {
        value: true,
        message: "Hiring Status is required",
      },
    })}
  >
    <option value="">Select status</option>
    <option value="opening">Opening</option>
    <option value="closing">Closing</option>
    <option value="ongoing">Ongoing</option>
  </select>
  
  {errors.hiringStatus && (
    <span className="error-message">
      {errors.hiringStatus.message}
    </span>
  )}
</div>
          <DialogFooter className="flex justify-between my-2 gap-4">
            {formState.isValid && (
              // <DialogClose asChild>
                <Button type="submit">Save</Button>
              // </DialogClose>
            )}

            {!formState.isValid && <Button type="submit">Save</Button>}
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
