import Modal from "react-modal";
import { tableStyles } from "../../../utils/styles";
import { useState } from "react";
import { AddCommentModal } from "../../pages/reports/AddCommentModal";
import { useEditComment, useDeleteComment } from "../../hooks/useCommentHook";

const CommentModal = ({ isOpen, onRequestClose, commentData, modalName }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [receiverId, setReceiverId] = useState(null);

  const editCommentMutation = useEditComment();
  const deleteCommentMutation = useDeleteComment();

  const handleOpenEditModal = (id) => {
    setOpenEditModal(true);
    setReceiverId(id);
  };

  const closeEditModal = () => {
    setOpenEditModal(false);
    setReceiverId(null);
  };

  const handleDelete = (id) => {
    deleteCommentMutation.mutate({
      commentId: id,
      modelName: modalName,
      modelId: commentData?._id,
    });

    onRequestClose();
  };

  const handleEditComment = (comment) => {
    editCommentMutation.mutate({
      comment: comment,
      commentId: receiverId,
      modelName: modalName,
      modelId: commentData?._id,
    });

    onRequestClose();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        style={tableStyles}
      >
        {commentData && (
          <div>
            <div style={{ padding: "20px", textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "2px solid gray",
                  marginBottom: "10px",
                  alignItems: "center",
                }}
              >
                <h5 style={{ fontWeight: "bold" }}>
                  Employees
                  <span className="close" onClick={onRequestClose}>
                    &times;
                  </span>
                </h5>
              </div>
              <table>
                <thead>
                  <tr style={{ backgroundColor: "#f1f4f7", color: "gray" }}>
                    <th>Name</th>
                    <th>Comment</th>
                    <th>Employee ID</th>
                    <th>Designation</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody className="tableBody">
                  {commentData?.commentByEmployee?.map((receiver) => (
                    <tr key={receiver?._id}>
                      <td>{receiver?.commentBy?.name}</td>
                      <td>{receiver?.comment}</td>
                      <td>{receiver?.commentBy?.employeeId}</td>
                      <td>{receiver?.commentBy?.designation?.name}</td>
                      <td>
                        <button
                          style={{
                            backgroundColor: "#085394",
                            color: "white",
                            padding: "8px 16px",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleOpenEditModal(receiver?._id)}
                        >
                          Edit
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            handleDelete(receiver?._id);
                          }}
                          style={{
                            backgroundColor: "#CD5C5C",
                            color: "white",
                            padding: "8px 16px",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Modal>

      <AddCommentModal
        isOpen={openEditModal}
        closeModal={closeEditModal}
        onSubmit={handleEditComment}
      />
    </>
  );
};

export default CommentModal;
