import EditIcon from "@mui/icons-material/Edit";
import React, { useState } from "react";
import Modal from "react-modal";
import { EmployeeOfMonthModal } from "../modals/EmployeeOfMonthModal";
import useEmployeeHook from "./../../hooks/useEmployeeHook";

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "300px",
    textAlign: "center",
  },
};

Modal.setAppElement("#root");

export const EmpForTheMonth = () => {
  const { empMonth } = useEmployeeHook();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEditClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      className="todo-list "
      style={{
        width: "fit-content",
      }}
    >
      <div
        className="header"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="header-text">Employee of the Month</div>

        <div className="edit-icon" onClick={handleEditClick}>
          <EditIcon
            style={{
              fontSize: "18px",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
      <div className="divider"></div>
      <div style={{ display: "flex" }}>
        <img
          alt="emp"
          style={{
            width: "200px",
          }}
          className="empImg"
          src="https://img.freepik.com/premium-vector/hr-employee-performance-evaluation-work-improvement-career-growth-efficiency-personnel_566886-1966.jpg?w=300"
        />
        <div
          style={{
            overflowWrap: "anywhere",
          }}
        >
          <div style={{ fontWeight: "bold" }}>{empMonth?.name}</div>
          <div>{empMonth?.designation?.name}</div>
        </div>
      </div>

      <EmployeeOfMonthModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};
