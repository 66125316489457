import React, { useEffect, useState } from 'react'
import { DataTable } from '../../components/table/Data-Table'
import { baseUrl, } from '../../config/baseUrl'
import axios from '../../config/axiosAuth'
const EmployeeColumn = [
    {
      header: "S.No",
      cell: ({ row }) => <span>{row.index+1}</span>
    },
    {
      header: "Employee ID:",
      cell: ({ row }) =>row?.original?.employeeId
          
    },
    {
      header: "Employee Name",
     cell: ({ row }) =>row?.original?.name
    },
    {
      header: "Employee Number",
      cell: ({ row }) =>row?.original?.number
    },
    {
      header: "Employee Email",
      cell: ({ row }) =>row?.original?.email
    },
    {
      header: "Employee Gender",
      cell: ({ row }) =>row?.original?.gender
  },
    {
      header: "Employee WorkMode",
      cell: ({ row }) =>row?.original?.workMode
    },
    {
      header: "Employee Designation",
      cell: ({ row }) =>row?.original?.designation?.name
    },
    
  ];
const PresentEmployees = () => {
    const[data,setData]=useState()
    useEffect(()=>{
        fetchDataofPresentEmpoyees()
    },[])

    const fetchDataofPresentEmpoyees=async()=>{
        try{
            const response =await axios.get(baseUrl+'/presentEmployees')
            setData(response?.data?.presentEmployees)
            
        }catch(err){
            throw new Error(err)
        }

    }
  return (
   <DataTable data={data||[]} columns={EmployeeColumn} defaultTheme={true} heading='Present Employees list'/>

  )
}

export default PresentEmployees
