import React from "react";

export function Widgets({ title, total }) {
  return (
    <div
      className="todo-list" // Add a custom class name for the component
      style={{
        width: "230px",
        backgroundColor: "white",
        borderRadius: "20px",
        boxSizing: "border-box",
        padding: "10px",
        overflow: "hidden",
      }}
    >
      <div className="header">
        <div className="header-text">{title}</div>
        <div className="divider"></div>
      </div>
      <div
        style={{
          color: "blue",
          fontSize: "50px",
          textAlign: "center",
        }}
      >
        {total}
      </div>
    </div>
  );
}
