import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React from "react";

export const ToDoList = ({ project, title }) => {
  const maxVisibleItems = 3;

  return (
    <div
      className="todo-list"
      style={{
        width: "300px",
        backgroundColor: "white",
        borderRadius: "20px",
        boxSizing: "border-box",
        padding: "10px",
        overflow: "hidden",
      }}
    >
      <div className="header">
        <div className="header-text">{title ? title : "To-Do List"}</div>
        <div className="divider"></div>
      </div>
      <div
        style={{
          overflowY: "scroll",
          maxHeight: `${maxVisibleItems * 60}px`,
        }}
      >
        {project && project.length > 0 ? (
          project.map((item, index) => (
            <div
              key={index}
              style={{
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "5px 0",
                fontSize: "11px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                }}
              >
                <div className="compleated_button"></div>
                <strong>{item.description}</strong> |{" "}
                <span>
                  {item.startDate} - {item.endDate}
                </span>
              </div>
            </div>
          ))
        ) : (
          <p style={{ padding: "10px" }}>No Projects To Show</p>
        )}
      </div>
      {project?.length > maxVisibleItems && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <KeyboardArrowDownIcon fontSize="small" />
        </div>
      )}
    </div>
  );
};
