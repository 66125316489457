import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

import useUserHook from "../../hooks/userHook";
import Error from "./../protectedPages/Error";
import Loading from "./../protectedPages/Loading";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { SelectCustomStyles } from "../../../PageResponsibility/src/utils/styles";
import { DataTable } from "../../../PageResponsibility/src/admin/components/table/Data-Table";

export const styles = {
  Active: {
    color: "green",
    fontWeight: "bold",
  },
  Inactive: {
    color: "red",
    fontWeight: "bold",
  },
  Pending: {
    color: "orange",
    fontWeight: "bold",
  },
};

const UserLeaves = () => {
  const { userLeaves, userLeavesError, userLeavesLoading, applyLeaveMutation } =
    useUserHook();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [subject, setSubject] = useState("");
  const [reason, setReason] = useState("");
  const [leaveType, setleaveType] = useState("");

  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleSave = (e) => {
    e.preventDefault();

    if (!startDate || !endDate || !subject.trim() || !reason.trim()) {
      alert("Please fill all the fields");
      return;
    }

    const data = {
      fromDate: startDate,
      toDate: endDate,
      subject: subject,
      leaveType: leaveType,
      description: reason,
    };
    applyLeaveMutation.mutate(data);
    closeEditModal();
    setStartDate("");
    setEndDate("");
    setSubject("");
    setleaveType("");
    setReason("");
  };

  const [currentPage, setCurrentPage] = useState(1);

  const pageSize = 6;

  const totalPages = Math.ceil(userLeaves?.count / pageSize);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  if (userLeavesError) {
    return <Error />;
  }
  const options = [
    { label: "Second Half", value: "Second Half" },
    { label: "First half", value: "First half" },
    { label: "Full day", value: "Full day" },
  ];
  const columns = [
    {
      header: "S.No",
      cell: ({ row }) => {
        return <span>{row.index + 1}</span>;
      },
    },
    {
      header: "From Date",
      cell:({row})=><span>{row.original?.fromDate?.substring(0, 10)}</span>

    },
  
    {
      header: "To Date",
      cell:({row})=><span >{row.original?.toDate?.substring(0, 10)}</span>
    },
  
    {
      accessorKey: "leaveType",
      header: "Leave Duration",
    },
    {
      accessorKey: "description",
      header: "Reason",
    }, {
      accessorKey: "subject",
      header: "Subject",
    },
    {
      // accessorKey: "status",
      cell:({row})=><span style={row?.original?.status === "approved"
        ? styles?.Active
        : row?.original?.status  === "rejected"
        ? styles.Inactive
        : styles.Pending}>{row?.original?.status}</span>,
      header: "Status",
    },
  
  ];
  return (
    <>
      <>
        <
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "2px solid gray",
              marginBottom: "10px",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                fontWeight: "bold",
              }}
            >
              Leaves
            </h5>
            <EditIcon
              onClick={openEditModal}
              style={{
                cursor: "pointer",
                color: "#085394",
                fontSize: "18px",
              }}
            />
          </div>
          {/* <table>
            <thead>
              <tr
                style={{
                  // backgroundColor: "#f1f4f7",
                  color: "gray",
                }}
              >
                <th>S.No.</th>
                <th>From Date</th>
                <th>To Date</th>
                <th>Leave Duration</th>

                <th>Reason</th>

                <th>Subject</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody className="tableBody">
              {userLeaves ? (
                userLeaves?.result
                  ?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
                  .map((leave, index) => {
                    const continuousSNo =
                      (currentPage - 1) * pageSize + index + 1;
                    return (
                      <tr key={leave?._id}>
                        <td>{continuousSNo}</td>
                        <td>{leave?.fromDate?.substring(0, 10)}</td>
                        <td>{leave?.toDate?.substring(0, 10)}</td>
                        <td>{leave?.leaveType}</td>

                        <td>{leave?.description}</td>

                        <td>{leave?.subject}</td>

                        <td
                          style={
                            leave?.status === "approved"
                              ? styles?.Active
                              : leave?.status === "rejected"
                              ? styles.Inactive
                              : styles.Pending
                          }
                        >
                          {leave?.status}
                        </td>
                      </tr>
                    );
                  })
              ) : (
                <tr>
                  <td colSpan="11">
                    <CircularProgress />
                  </td>
                </tr>
              )}
            </tbody>
          </table> */}
          <DataTable defaultTheme={true} heading={'Leaves'} data={userLeaves?.result||[]} columns={columns} />
        </>
        {/* <div className="pagination-container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className={`pagination-button ${
              currentPage === 1 ? "pagination-button--disabled" : ""
            }`}
            onClick={handlePreviousPage}
          >
            <div>
              <KeyboardArrowLeftIcon />
            </div>
            <div>Previous</div>
          </div>
          <
            className={`pagination-button ${
              currentPage === totalPages ? "pagination-button--disabled" : ""
            }`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={handleNextPage}
          >
            {/* Next <KeyboardArrowRightIcon /> */}
            {/* <div>Next</div>
            <div>
              <KeyboardArrowRightIcon />
            </div>
          </div>
        </div>  */}
      </>
      <Dialog open={isEditModalOpen} onClose={closeEditModal}>
        <DialogTitle>Add Leave</DialogTitle>
        <DialogContent>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                min: new Date().toISOString().slice(0, 10),
              },
            }}
            sx={{
              marginTop: 2,
              "& .MuiInputLabel-root": {
                color: "rgba(0, 0, 0, 0.6)",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(0, 0, 0, 0.23)",
                },
              },
              "& .MuiInputBase-input": {
                color: "rgba(0, 0, 0, 0.87)",
              },
            }}
          />

          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                min: startDate,
              },
            }}
            sx={{
              marginTop: 2,
              "& .MuiInputLabel-root": {
                color: "rgba(0, 0, 0, 0.6)",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(0, 0, 0, 0.23)",
                },
              },
              "& .MuiInputBase-input": {
                color: "rgba(0, 0, 0, 0.87)",
              },
            }}
          />
          <div className="mt-[10px]">
            <ReactSelect
              onChange={(option) => {
                setleaveType(option.value);
              }}
              styles={SelectCustomStyles}
              isClearable
              value={
                options.find((option) => option.value === leaveType) || null
              }
              options={options}
              placeholder={"Select a type ...."}
            />
          </div>
          <TextField
            label="Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            fullWidth
            inputProps={{
              maxLength: 15,
              minLength: 3,
            }}
            sx={{
              marginTop: 2,
              "& .MuiInputLabel-root": {
                color: "rgba(0, 0, 0, 0.6)",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(0, 0, 0, 0.23)",
                },
              },
              "& .MuiInputBase-input": {
                color: "rgba(0, 0, 0, 0.87)",
              },
            }}
          />

          <TextField
            label="Reason"
            multiline
            rows={4}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            fullWidth
            sx={{
              marginTop: 2,
              "& .MuiInputLabel-root": {
                color: "rgba(0, 0, 0, 0.6)",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(0, 0, 0, 0.23)",
                },
              },
              "& .MuiInputBase-input": {
                color: "rgba(0, 0, 0, 0.87)",
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditModal}>Cancel</Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {userLeavesLoading && <Loading />}
    </>
  );
};

export default UserLeaves;
