import React from "react";
import CheckIcon from "@mui/icons-material/Check";

import { styles } from "../../pages/Leaves/Leave";
import moment from "moment";
import useTimesheetHook from "../../hooks/useTimesheerHook";
import { DataTable } from "../../../PageResponsibility/src/admin/components/table/Data-Table";
import { useLocation } from "react-router-dom";
import { DescriptionModal } from "../../../PageResponsibility/src/admin/components/modals/DiscriptionModal";
// import { formatDateTime } from "../../../utils/lib";

const Columns=[
  {header:'S.No',
    cell:({row})=>row.index+1
  },
  {header:'Employee Id',
    cell:({row})=>row?.original?.employeeId?.employeeId
  }, {header:'Employee name',
    cell:({row})=>row?.original?.employeeId?.name
  }, {header:'Designation',
    cell:({row})=>row?.original?.employeeId?.designation
  },
   {header:'Date',
    cell:({row})=>moment(row?.original?.employeeId?.fromDate).format('DD-MM-YYY')
  }, {header:'Reason',
    cell:({row})=>row?.original?.description?<DescriptionModal trigger={<div className="">{`${(row?.original?.description).substring(0,14)}...`}</div>} data={row?.original?.description}/>:'-'


  },
  {header:'Duration',
    cell:({row})=>row?.original?.employeeId?.leaveType
  }, {header:'Subject',
    cell:({row})=>row?.original?.subject
  }, {header:'Status',
    cell:({row})=><span style={{color:row?.original?.status==='rejected'?'red':row?.original?.status==='approved'?'green':row?.original?.status==='pending'?'orange':'orange'}}>{row?.original?.status}</span>
  },
]
export function LeaveTable(props) {

  const{leaves}=useTimesheetHook();
  const state=useLocation()
  return (
    <>
    <div>Leaves</div>
    <DataTable columns={Columns} data={state?.state?state?.state?.data:leaves?.result||[]} defaultTheme={true} heading='Leaves' TableHeight={'200px'}/>
    </>

    // <div>
    //   <div className="table-container" classname="p-8">
    //     <div
    //       style={{
    //         display: "flex",
    //         justifyContent: "space-between",
    //         borderBottom: "2px solid gray",
    //         marginBottom: "10px",
    //         alignItems: "center",
    //       }}
    //     >
    //       <h5
    //         style={{
    //           fontWeight: "bold",
    //           marginLeft:"10px"
    //         }}
    //       >
    //         Leaves
    //       </h5>
    //     </div>
    //     <table>
    //       <thead>
    //         <tr
    //           style={{
    //             backgroundColor: "#f1f4f7",
    //             color: "gray",
    //           }}
    //         >
    //           <th>S.No.</th>
    //           <th>Employee ID</th>
    //           <th>Name</th>
    //           <th>Designation</th>
    //           <th>Date</th>
    //           <th>Reason</th>
    //           {props?.handleActionChange ? null : <th>Subject</th>}
    //           <th>Status</th>
    //           {props?.handleActionChange ? <th>Action</th> : null}
    //         </tr>
    //       </thead>
    //       <tbody className="tableBody">
    //         {props?.leaves && props?.leaves.length > 0 ? (
    //           props?.leaves.map((leave, index) => {
    //             return (
    //               <tr key={leave?._id}>
    //                 <td>{index}</td>
    //                 <td>{leave?.employeeId?.employeeId}</td>
    //                 <td>{leave?.employeeId?.name}</td>
    //                 <td>{leave?.employeeId?.designation}</td>
    //                 <td>{moment(leave?.fromDate).format("DD/MM/YYYY")}</td>
    //                 {/* <td>{formatDateTime(leave?.fromDate)}</td> */}

    //                 <td>{leave?.description}</td>
    //                 {props?.handleActionChange ? null : (
    //                   <td>{leave?.subject}</td>
    //                 )}
    //                 <td
    //                   style={
    //                     leave?.status === "approved"
    //                       ? styles?.Active
    //                       : leave?.status === "rejected"
    //                       ? styles.Inactive
    //                       : styles.Pending
    //                   }
    //                 >
    //                   {leave?.status}
    //                 </td>
    //                 {props?.handleActionChange ? (
    //                   <td>
    //                     <div
    //                       className="action-options"
    //                       // class="flex justify-center items-center gap-2.5"
    //                       style={{
    //                         display: "flex",
    //                         justifyContent: "center",
    //                         alignItems: "center",
    //                         gap: "10px",
    //                       }}
    //                     >
    //                       <select
    //                         onChange={(e) =>
    //                           props?.handleActionChange(
    //                             leave?._id,
    //                             e.target.value
    //                           )
    //                         }
    //                       >
    //                         <option value="">Select</option>
    //                         <option value="approved">Approve</option>
    //                         <option value="rejected">Reject</option>
    //                       </select>
    //                       <CheckIcon
    //                         onClick={(e) =>
    //                           props?.handleStatusChange(leave?.employeeId?._id)
    //                         }
    //                         style={{
    //                           cursor: "pointer",
    //                           color: "#085394",
    //                           fontSize: "18px",
    //                         }}
    //                       />
    //                     </div>
    //                   </td>
    //                 ) : null}
    //               </tr>
    //             );
    //           })
    //         ) : (
    //           <tr>
    //             <td colSpan="11">No Leaves present</td>
    //           </tr>
    //         )}
    //       </tbody>
    //     </table>
    //   </div>
    
    // </div>
  );
}
