import { useQuery } from "react-query";
import attendanceServices from "./attendanceServices";

const useAttendanceHook = (body) => {
  const {data}=useQuery(["attendance",body.employeeId,body.month,body.year,body.startDate,body.endDate], () => {
    return attendanceServices.getMonthlyAttendance(body)
  });
  return{
    data
  }
  
};

export default useAttendanceHook;
