import { ToastContainer } from "react-toastify";
import ProtectRoute from "./user/pages/protectedPages/ProtectRoute";
import Login from "./user/pages/login/Login";
import ForgotPassUser from "./user/pages/login/ForgotPassUser";
import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { renderProtectedRoute } from "./renderProtectedRoute";
import { routes } from "./routes";
import App from "./user/pages/sharescreen/UserScreenShare";
import SocketProvider from "./user/context/Socketprovider";

export function AppContainer() {
  return (
    <BrowserRouter>
      <Routes>
    <Route path="/" element={<Navigate replace to="/login" />} />

        <Route path="/login" index element={<ProtectRoute Component={Login} />} />
        {routes.map((route) => (
          <Route element={<SocketProvider />}>
            <Route
              key={route.path}
              path={route.path}
              element={renderProtectedRoute(route.component)}
            />
          </Route>
        ))}
        <Route path="/forgotPassUser" element={<ForgotPassUser />} />
        <Route path="/screen" element={<App />} />
      </Routes>
      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={600}
        hideProgressBar
        closeOnClick
        limit={4}
      />
    </BrowserRouter>
  );
}
