import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import useReportHook from "../../hooks/ReportHook";
import { Button } from "../../components/ui/button";
import ReactSelect from "react-select";
import { Trash } from "lucide-react";
import { useLocation } from "react-router-dom";
import useUserProjectStore from "../../store/userProject";
export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    backgroundColor: "#fff",
  },
};

export function EditReportModalUser(props) {
  const [projectsList, setProjectsList] = useState([]);
  const location = useLocation();
  const { register, handleSubmit, control, formState, setValue } = useForm();
  const { fields, append, prepend, remove, swap, move, insert, replace } =
    useFieldArray({
      control,
      name: "reports",
    });
  const { errors } = formState;
  const { projects } = useUserProjectStore();

  useEffect(() => {
    const options = projects.map((item) => ({
      label: item.projectName,
      value: item._id,
    }));
    setProjectsList(options);
  }, [projectsList, projects]);
  useEffect(() => {
    const reportData = location?.state?.data || [];
    const formattedReportData = reportData.map((report) => ({
      project: { value: report.project._id, label: report.project.projectName },
      report: report.report,
      _id: report._id,
    }));
    console.log(formattedReportData, "selectOption projecttt");
    setValue("reports", formattedReportData);
  }, [location?.state?.data, setValue]);
  const {
    editReportUserMutation,
    addReportUserMutation,
    editAccessReportUserMutation,
  } = useReportHook();

  const onSubmit = (data) => {
    const reportData = data.reports.map((item, index) => ({
      report: item.report,
      project: item.project.value,
      reportId: location?.state?.id,
    }));
    const AccesData = {  id: location?.state?.id,reports:[ ...reportData],
   
    }
    
    // location?.state?.access
      editAccessReportUserMutation.mutate(AccesData)
      // : addReportUserMutation.mutate(reportData);

    // props.closeEditReportModal();
  };
  console.log(props.editReport, "editReport");
  console.log(
    location?.state?.data,
    "locationlocationlocationlocationlocationlocationlocationlocation"
  );

  return (
    <>
      <h2 style={{ color: "#085394" }}>Edit Report</h2>
      <div className="modal-container">
        <div className="modal-body">
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            {fields.map((item, index) => (
              <div key={item.id} className="form-row">
                <div className="form-group">
                  <Controller
                    name={`reports.${index}.project`}
                    control={control}
                    render={({ field }) => {
                      const selectedOption = projectsList?.find(
                        (item) => item.value === field.value.value
                      );

                      return (
                        <ReactSelect
                          {...field}
                          isClearable
                          options={projectsList}
                          value={
                            selectedOption
                              ? {
                                  value: selectedOption.value,
                                  label: selectedOption.label,
                                }
                              : null
                          }
                          placeholder={"Select an Project ...."}
                          onChange={(option) => {
                            return (
                              console.log(option, "optionnnn"),
                              field.onChange(
                                option
                                  ? { value: option.value, label: option.label }
                                  : null
                              )
                            );
                          }}
                        />
                      );
                    }}
                  />
                  {errors?.reports?.[index]?.project?.message && (
                    <p className="error-message">
                      {errors.reports[index].project.message}
                    </p>
                  )}
                </div>
                <textarea
                  rows="10"
                  cols="50"
                  {...register(`reports.${index}.report`, {
                    required: "Please enter your daily report",
                  })}
                />
                {errors?.reports?.[index]?.report?.message && (
                  <p className="error-message">
                    {errors.reports[index].report.message}
                  </p>
                )}
                <button type="button" onClick={() => remove(index)}>
                  <Trash color="red" />
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => append({ project: {}, report: "" })}
              className="flex items-center justify-center mt-[10px] gap-2 mb-[30px]"
            >
              <div
                className="w-[20px] h-[20px] bg-rgb(8 83 148,0.4) flex items-center justify-center rounded-[50%] text-[#eaf2ff]"
                style={{ backgroundColor: "rgb(8,83,148,0.3)" }}
              >
                +
              </div>
              Add Project Report
            </button>
            <Button className="btn btn-primary" type="submit">
              Save
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}
