import React, { useMemo, useState } from "react";
import { AddHiringModal } from "./Modals/AddHiringModal";
import Error from "../protectedPages/Error";
import Loading from "../protectedPages/Loading";
import useHiringHook from "../../hooks/useHiringHook";
import { DataTable } from "../../components/table/Data-Table";
import { UpdateHiringModal } from "./Modals/UpdateHiringModal";
import { CommentDialog } from "../../components/modals/CommentDialog";
import { AddCommentModal } from "../reports/AddCommentModal";
import moment from "moment";

const Hiring = () => {
  const { data, isLoading, isError } = useHiringHook();
  const hiringColumns =useMemo(()=>
    [
      {
        header: "S.No.",
        cell: ({ row }) => row.index + 1,
      },
  
      {
        header: "Position",
        accessorKey: "position",
      },
      {
        header: "Start Date",
        accessorKey: "createdAt",
      },
      {
        header: "End Date",
        accessorKey: "hiring_last_date",
      },
      {
        header: "Experience",
        accessorKey: "experience",
      },
      {
        header: "Salary",
        accessorKey: "salary",
      },
      {
        header: "Hiring Status",
        accessorKey: "hiringStatus",
      },
      {
        header: "No of Candidates",
        accessorKey: "noOfCandidates",
      },
     
  
      {
        header: "Add Comment",
        cell: ({ row }) => <AddCommentModal  id={row.original._id} url={'/addHiringCommentByAdmin'}/>,
      },
      {
        header: "Comments",
        cell: ({ row }) => {
          return <CommentDialog id={row.original._id} url={'/getHiringComments'} />;
        },
      },
      {
        header: "Edit",
        cell: ({ row }) => <UpdateHiringModal hiring={row.original} />,
      },
    ] ,[data])
  if (isError) {
    return <Error />;
  }

  if (isLoading) {
    return <Loading />;
  }

 

  return (
    <>
      <div className="flex justify-end">
        <AddHiringModal />
      </div>

      <DataTable columns={hiringColumns} data={data||[]} defaultTheme={true} />
    </>
  );
};

export default Hiring;
