import CheckIcon from "@mui/icons-material/Check";
import React, { useEffect, useMemo, useState } from "react";
import { formatDateTime } from "../../../utils/lib";
import { SendMessageModal } from "../../components/modals/SendMessageModal";
import { DataTable } from "../../components/table/Data-Table";
import useLeaveHook from "../../hooks/useLeaveHook";
import "../Client/client.scss";
import Error from "../protectedPages/Error";
import Loading from "../protectedPages/Loading";
import { AddCommentModal } from "../reports/AddCommentModal";
import { CommentDialog } from "../../components/modals/CommentDialog";
import { Tooltip } from "@mui/material";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import { Link, useNavigate } from "react-router-dom";
import { DescriptionModal } from "../../components/modals/DiscriptionModal";

export const styles = {
  Active: {
    color: "green",
    fontWeight: "bold",
  },
  Inactive: {
    color: "red",
    fontWeight: "bold",
  },
  Pending: {
    color: "orange",
    fontWeight: "bold",
  },
};

const Leaves = () => {
  const navigate = useNavigate();
  const {
    data: leaves,
    statusChangeMutation,
    isError,
    isLoading,
  } = useLeaveHook();
  const { data: employeeList } = useEmployeeHook();

  const [rowSelections, setRowSelections] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [employeeId, setEmployeeId] = useState([]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  
  const leavesColumns = useMemo(
    () => [
      {
        header: "S.No.",
        cell: ({ row }) => {
          return <span>{row.index + 1}</span>;
        },
      },
      {
        header: "Employee ID",
        cell: ({ row }) => {
          return <span>{row.original.employeeId?.employeeId}</span>;
        },
      },
      {
        header: "Name",
        cell: ({ row }) => {
          return <span>{row.original.employeeId?.name}</span>;
        },
      },
      {
        header: "Designation",
        cell: ({ row }) => {
          return <span>{row.original.employeeId?.designation?.name}</span>;
        },
      },
      {
        header: "From Date",
        cell: ({ row }) => {
          return <span>{formatDateTime(row.original.fromDate)}</span>;
        },
      },
      {
        header: "To Date",
        cell: ({ row }) => {
          return <span>{formatDateTime(row.original.toDate)}</span>;
        },
      },
      {
        header: "Reason",
        cell: ({ row }) =>
          row?.original?.description ? (
            <DescriptionModal
              trigger={
                <div className="">{`${(row?.original?.description).substring(
                  0,
                  14
                )}...`}</div>
              }
              data={row?.original?.description}
            />
          ) : (
            "-"
          ),

        // cell: ({ row }) => {
        //   return <span>{row.original.description}</span>;
        // },
      },
      {
        header: "Status",
        cell: ({ row }) => {
          return (
            <span
              style={
                row.original.status === "approved"
                  ? styles.Active
                  : row.original.status === "rejected"
                  ? styles.Inactive
                  : styles.Pending
              }
            >
              {row.original.status}
            </span>
          );
        },
      },
      {
        header: "Action",
        cell: ({ row }) => {
          const leaveStatus = row.original.status;
          const isStatusChangeable = leaveStatus === "pending";

          const actionContent = (
            <div className="flex justify-center items-center gap-2.5 action-options">
              <select
                id="id"
                style={{ width: "8vw" }}
                value={rowSelections[row.original._id] || ""}
                onChange={(e) => {
                  setRowSelections((prev) => ({
                    ...prev,
                    [row.original._id]: e.target.value,
                  }));
                }}
                disabled={isPast(row.original.toDate)}
              >
                <option value="">Select</option>
                <option value="approved">Approve</option>
                <option value="rejected">Reject</option>
              </select>
              <CheckIcon
                onClick={() => {
                  // if (isPast(row.original.toDate)) {
                  //   return;
                  // }
                  const action = rowSelections[row.original._id];
                  // if (!action) {
                  //   return;
                  // }
                  statusChangeMutation.mutate({
                    id: row.original._id,
                    action: action,
                  });
                  if (action === "rejected") {
                    setIsModalOpen(true);
                  }
                  setEmployeeId([row.original.employeeId._id]);

                  // Clear the selection after action
                  setRowSelections((prev) => ({
                    ...prev,
                    [row.original._id]: "",
                  }));
                }}
                className={`cursor-pointer text-lg ${
                  !isPast(row.original.toDate)
                    ? "text-[#085394]"
                    : "text-gray-400"
                }`}
              />
            </div>
          );

          return !isPast(row.original.toDate) ? (
            actionContent
          ) : (
            <Tooltip title="Cannot change status of non-pending leaves">
              <span>{actionContent}</span>
            </Tooltip>
          );
        },
      },
      {
        header: "Add Comment",
        cell: ({ row }) => (
          <AddCommentModal
            id={row.original._id}
            url={"/addLeaveCommentsByAdmin"}
          />
        ),
      },
      {
        cell: ({ row }) => {
          return (
            <CommentDialog id={row.original._id} url={"/getLeaveComments"} />
          );
        },
        header: "Comments",
      },
    ],
    [rowSelections, leaves]
  );
  const userColumns = useMemo(
    () => [
      {
        header: "S.No.",
        cell: ({ row }) => {
          return <span>{row.index + 1}</span>;
        },
      },
      {
        header: "Employee ID",
        cell: ({ row }) => {
          return <span>{row.original?.employeeId}</span>;
        },
      },
      {
        header: "Name",
        cell: ({ row }) => {
          return <span>{row.original?.name}</span>;
        },
      },
      {
        header: "Role",
        cell: ({ row }) => {
          return <span>{row.original?.role}</span>;
        },
      },
      {
        header: "Designation",
        cell: ({ row }) => {
          return <span>{row.original?.designation?.name}</span>;
        },
      },
      {
        header: "ViewDetails",
        cell: ({ row }) => {
          return (
            <div
              onClick={() =>
                navigate(`/employeeLeaveList/${row?.original?._id}`)
              }
              className="button-17 !rounded-[10px]"
            >
              View Details
            </div>
          );
        },
      },
    ],
    []
  );

  if (isError) {
    return <Error />;
  }
  const currentDate = new Date();
  const isPast = (todate) => {
    const date = new Date(todate);

    // Set the time components to zero for both dates to only compare the date part
    const currentDateWithoutTime = new Date(currentDate.setHours(0, 0, 0, 0));
    const toDateWithoutTime = new Date(date.setHours(0, 0, 0, 0));

    return currentDateWithoutTime > toDateWithoutTime;
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <DataTable
        columns={leavesColumns}
        data={leaves || []}
        defaultTheme={true}
        heading={"Recent Leaves"}
        TableHeight={"300px"}
      />
      <DataTable
        columns={userColumns}
        data={employeeList || []}
        defaultTheme={true}
        heading={"Employee Wise Leave List"}
        TableHeight={"400px"}
      />
      <SendMessageModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        employeeId={employeeId}
      />
    </>
  );
};

export default Leaves;
