import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import useAuthHook from "./../../hooks/authHook";
import authService from "../../services/authServicesUser";
import { useQuery } from "react-query";
import axios from "../../config/axiosAuth";
import { baseUrlUser } from "../../config/baseUrl";
import { handleKeyDown } from "../../../utils/lib";

export function UserLogin() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [location, setLocation] = useState(null);
  const { loginEmployeeMutation } = useAuthHook();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const GetToken = async () => {
    const response = await axios.get(baseUrlUser + "/employeeZegoToken");
    localStorage.setItem("zegoToken", response.data.token);
    navigate("/userdashboard");
  };
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
      GetToken();
    }
  }, [token]);
  // const onSubmit = async (data) => {
  //   loginEmployeeMutation.mutate(data,location);
  // };
  const onSubmit = async (data) => {
    // Disable button for 2 seconds
    setIsButtonDisabled(true);

    loginEmployeeMutation.mutate(data, {
      onSuccess: () => {
        // Re-enable button after 2 seconds
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 2000);
      },
      onError: (res) => {
        // Even if there's an error, re-enable button after 2 seconds
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 2000);
      },
    });
  };

  function handleLocationClick(data) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success(data), error);

    } else {
    }
  }

  function success(position,data) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setLocation({ latitude, longitude });
    onSubmit(data)
  }

  function error() {
  }
  return (
    <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-container">
        <div className="input-container">
          <label>Employment ID:</label>
          <input
            autoFocus
            autoComplete="off"
            type="text"
            onKeyDown={handleKeyDown}
            placeholder="Enter your ID"
            {...register("userEmail", {
              required: "This field is required",
              // pattern: {
              //   // value: /^[a-zA-Z0-9]+$/,
              //   message: "Only letters and numbers are allowed",
              // },
              maxLength: {
                value: 15,
                message: "Maximum 15 characters allowed",
              },
            })}
          />
          {errors.userEmail && (
            <span className="error-message">{errors.userEmail.message}</span>
          )}
        </div>
        <div className="input-container">
          <label>Password:</label>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          >
            <input
              style={{ width: "90%", border: "none" }}
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              onKeyDown={handleKeyDown}
              autoCapitalize="off"
              {...register("userPassword", {
                required: "This field is required",
                // pattern: {
                //   value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                //   message:
                //     // "Password must be at least 8 characters long and contain at least one letter and one number",
                // },
                maxLength: {
                  value: 20,
                  message: "Maximum 15 characters allowed",
                },
              })}
            />
            <div
              className="password-toggle-icon"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>
          {errors.userPassword && (
            <span className="error-message">{errors.userPassword.message}</span>
          )}
        </div>
        <div className="forgot-password">
          <p>Forgot Password? </p>
          <Link to="/forgotPassUser">
            <p>Click Here</p>
          </Link>
        </div>
      </div>

      <button
        className="login-button"
        type="submit"
          disabled={isButtonDisabled || loginEmployeeMutation.isLoading}
      >
        {loginEmployeeMutation.isLoading ? "Logging in..." : "Login"}
      </button>
    </form>
  );
}
