import { useQuery } from "react-query";
import { employeeServices } from "../../service/employeeService";

const useGetByCategory = () => {
  const getEmployeeByCategory = useQuery(
    "employeeByCategory",
    employeeServices.totalEmployeeByCategory
  );

  return getEmployeeByCategory;
};

export default useGetByCategory;
