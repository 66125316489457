// import axios from "";
import axios from "../../config/axiosAuth";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/dialog";
import { baseUrl } from "../../config/baseUrl";
import { useEffect, useState } from "react";
import { DataTable } from "../../components/table/Data-Table";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import { useForm } from "react-hook-form";
import { Button } from "../../components/ui/button";
import { SiTryitonline } from "react-icons/si";
import { useQueryClient } from "react-query";
import { MdDelete } from "react-icons/md";

export function TransferTeamAndDeleteTeam({ teamToBeDeleted ,teamId}) {
  const [finalData, setFinalData] = useState([]);
  const[open,setOpen]=useState(false)
  const{departmentList}=useEmployeeHook();
  const { register, handleSubmit, setValue, watch, formState } = useForm({})
  const { errors } = formState;
  const queryClient=useQueryClient()
  useEffect(() => {
    const transformedData = departmentList.filter((item) => {
      return item.teamName!==teamToBeDeleted;
    });
    setFinalData(transformedData);
  }, []);

 const onSubmit=async(data)=>{
    try{
const response= await axios.post(baseUrl+'/transfer-team',{
    sourceTeamId:teamId,
    targetTeamId:data.transferTo
})
console.log(response,'response')
const deleteTeam =await axios.post(baseUrl+'/deleteTeam',{
    teamId:teamId
})
console.log(deleteTeam,'deleteTeam')

queryClient.invalidateQueries('departmentList')

    }catch(err){
throw new Error(err)
    }
 }

  return (
    <Dialog className='min-w-[600px] min-h-[300px]'open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <div className="w-full flex items-center justify-center">
          {/* size={20} color={"Red"} /> */}
          <MdDelete  size={20} color={"Red"} />
        </div>
      </DialogTrigger>
      <DialogContent className='min-w-[700px] min-h-[300px]'>
        
      <form onSubmit={(handleSubmit(onSubmit))} className="flex flex-col">
        <div ><p className="text-xl font-bold text-[#085394] text-center">You need to transfer the employees to a different team before deleting a team !!</p></div>
       
       <div className="flex flex-col justify-evenly">
         <div className="form-row">

        <div className="form-group">
           <p className="text-md">{`Transfer employees from ${teamToBeDeleted} to`}</p>
        </div>
      <div className="form-group">
          <select
            id="transferTo"
            name="transferTo"
            value={watch("transferTo")}
            onChange={(e) => setValue("transferTo", e.target.value)}
            {...register("transferTo", {
              required: "Please select a Team to transfer employees",
            })}
          >
            <option value="" className="text-slate-300">
              Select Team
            </option>
            {finalData?.map((team) => (
              <option key={team._id} value={team._id}>
                {team.teamName}
              </option>
            ))}
          </select>
          {errors.transferTo && (
            <p className="text-xs text-red-500  ">{errors.transferTo.message}</p>
          )}
        </div>
        </div>
        {/* <DialogClose asChild> */}
        <div className="ml-auto"><Button type='Submit'>Complete Action </Button></div>
        {/* </DialogClose > */}
        </div>
      </form>
      </DialogContent>
    </Dialog>
  );
}
