import { useQuery, useMutation, useQueryClient } from "react-query";
import leadsServices from "./leadsServices";

const useLeadsHooks = () => {
  const queryClient = useQueryClient();

  const { data: leads, isLoading } = useQuery(["leads"], () => {
    return leadsServices.getLeads();
  });

  const addLeadMutation = useMutation((data) => leadsServices.addLead(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("leads");
    },
  });

  const editLeadMutation = useMutation(
    ({ id, data }) => leadsServices.editLead(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("leads");
      },
    }
  );

  const deleteLeadMutation = useMutation(
    (id) => {
      return leadsServices.deleteLead(id);
    },

    {
      onSuccess: () => {
        queryClient.invalidateQueries("leads");
      },
    }
  );

  return {
    leads,
    isLoading,
    addLeadMutation,
    editLeadMutation,
    deleteLeadMutation,
  };
};

export default useLeadsHooks;
