import { create } from "zustand";

const useScreenShareStore = create((set) => ({
  callStarted: false,
  setCallStarted: (value) => set({ callStarted: value }),
  screenShareRoom:null,
  setScreenShareRoom:(value)=>({screenShareRoom:value})
  
}));

export default useScreenShareStore;
