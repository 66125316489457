// exports.baseUrl = "https://api.kickrtechnology.in/api/v1/admin";
// // exports.baseUrl = "http://localhost:3002/api/v1/admin";

// // exports.baseUrlUser = "https://api.kickrtechnology.in/api/v1/user";
// exports.baseUrlCommon = "https://api.kickrtechnology.in/api/v1/common";
// // exports.baseUrl = 'https://apioffice.kickrtechnology.online/api/v1/admin/';
// exports.base = "https://api.kickrtechnology.in";
exports.baseUrl = process.env.REACT_APP_BASE_URL;
// exports.baseUrl = "http://localhost:3002/api/v1/admin";

// exports.baseUrlUser = "https://api.kickrtechnology.in/api/v1/user";
exports.baseUrlCommon = process.env.REACT_APP_BASE_URL_COMMON;
// exports.baseUrl = 'https://apioffice.kickrtechnology.online/api/v1/admin/';
exports.base = process.env.REACT_APP_BASE;
