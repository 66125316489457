import { toast } from "react-toastify";
import { baseUrl } from "../config/baseUrl";
import axios from "./../config/axiosAuth";

const taskServices = {
  getTasks: async (projectId, page, limit) => {
    const response = await axios.post(`${baseUrl}/getAllTask`, {
      projectId,
      page,
      limit,
    });

    return response.data;
  },

  getProjectUnderReview: async (projectId, page, limit) => {
    try {
      const response = await axios.post(`${baseUrl}/getUnderReviewProjects`, {
        projectId,
        page,
        limit,
      });

      return response.data;
    } catch (error) {
    }
  },

  getGraphData: async (id) => {
    try {
      const response = await axios.post(`${baseUrl}/getTaskGraph`, {
        projectId: id,
      });

      return response.data.result;
    } catch (error) {
    }
  },

  getPieData: async () => {
    try {
      const response = await axios.get(`${baseUrl}/getTaskChart`);
      return response.data.result;
    } catch (error) {
    }
  },

  editTask: async (data) => {
    const response = await axios.put(
      baseUrl + "/editTask/" + data.id,
      data.data
    );


    return response.data;
  },
};

export { taskServices };
