import React from "react";
import "./login.css";
import "react-toastify/dist/ReactToastify.css";
import { UserLogin } from "./UserLogin";

import { useEffect } from "react";
import { findtoken } from "../../../firebase";

const Login = () => {
  useEffect(() => {
    // localStorage.clear();
    
    findtoken();
  }, []);

  return (
    <div className="login-container">
      <div className="login-bg">
        <div className="login-top">
          <div>User</div>
        </div>
        <UserLogin />
      </div>
    </div>
  );
};

export default Login;
