import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useProjectHook from "../../hooks/useProjectHook";
import { AdminSIdeBar } from "../../components/sidebar/AdminSIdeBar";
import { useTaskManagementHook } from "../taskmangement/useTaskMangementHook";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import Typography from "@mui/material/Typography";
import Error from "../protectedPages/Error";
import Loading from "../protectedPages/Loading";
import Navbar from "../../components/navbar/Navbar";
import { useProjectTaskReview } from "./projectTimelineHook";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useProjectHandleEmployeeStatus } from "./projectHandleStatusHook";

function Timeline() {
  const [currentPage, setCurrentPage] = useState(1);
  const { data } = useProjectHook();
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");

  const projects = data || [];
  const selectedProject =
    data?.length > 0 ? data?.find((p) => p._id === projectId) : null;

  const {
    data: taskData,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useProjectTaskReview(projectId, currentPage, 8);

  const { changeProjectMutation } = useProjectHandleEmployeeStatus();

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setSearchParams({ projectId, page: newPage, limit: 8 });
  };

  useEffect(() => {
    setSearchParams({ projectId, page: currentPage, limit: 8 });
  }, [projectId, currentPage, setSearchParams]);

  useEffect(() => {
    refetch();
  }, [selectedProject, currentPage, refetch]);

  const handleStatusChange = (employeeId, projectId) => {
    changeProjectMutation.mutate({ employeeId, projectId });
    refetch();
  };

  return (
    <>
      <div className="taskmanagement__container">
        <div className="taskmanagement__selectProject">
          <label htmlFor="">Select Project</label>
          <select
            name=""
            id=""
            value={selectedProject ? selectedProject._id : ""}
            onChange={(e) => {
              const selectedProjectId = e.target.value;
              setSearchParams({ projectId: selectedProjectId });
            }}
          >
            <option value="" disabled>
              {"Select a project"}
            </option>

            {projects.map((project) => (
              <option key={project._id} value={project._id}>
                {project.projectName}
              </option>
            ))}
          </select>
        </div>
        <div className="taskmanagement_table">
          <div className="table-container" style={{ padding: "10px" }}>
            <table>
              <thead style={{ color: "#b7b9bd" }}>
                <tr
                  style={{
                    backgroundColor: "#f1f4f7",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  <th>S.No</th>
                  <th>EMployee Name</th>
                  <th>Project Name</th>
                  <th>Designation</th>
                  <th>Status</th>
                  <th>Confirm Delivery</th>
                </tr>
              </thead>
              <tbody className="tableBody">
                {isError && (
                  <tr>
                    <td
                      colSpan="5"
                      style={{ textAlign: "center", color: "red" }}
                    >
                      Error loading tasks
                    </td>
                  </tr>
                )}
                {isFetching && (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center" }}>
                      <Loading />
                    </td>
                  </tr>
                )}
                {!isError && !isLoading && taskData?.result?.length > 0
                  ? taskData?.result?.map((task, index) => (
                      <tr key={task._id}>
                        <td>{index + 1}</td>
                        <td>{task.employeeName}</td>
                        <td>{task.projectName}</td>
                        <td>{task.designation?.name}</td>
                        <td>{task.status}</td>
                        <td>
                          {task.status === "Delivered" ? (
                            "Delivery Confirmed"
                          ) : (
                            <TaskAltIcon
                              style={{
                                color: "green",
                              }}
                              onClick={() => {
                                handleStatusChange(
                                  task.employeeId,
                                  task.projectId
                                );
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    ))
                  : !isError &&
                    !isLoading && (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          No data available
                        </td>
                      </tr>
                    )}
              </tbody>
            </table>
          </div>
          <Stack spacing={2} className="pagination-container">
            <Typography>Page: {currentPage}</Typography>
            <Pagination
              count={taskData?.totalPages}
              page={currentPage}
              onChange={(event, value) => handlePageChange(value)}
              color="primary"
            />
          </Stack>
        </div>
      </div>
      {projectId === null && <Loading />}
    </>
  );
}

export default Timeline;
