export function transformProjectDataToToDoList(data) {
  const transformedEvents = [];

  data?.forEach((project) => {
    transformedEvents.push({
      description: project.projectName,
      startDate: project.projectStartDate.split("T")[0],
      endDate: project.projectEndDate.split("T")[0],
    });
  });

  return transformedEvents;
}

export function transformProjectData(data) {
  const transformedEvents = [];

  data?.forEach((project) => {
    const projectStartDate = project.projectStartDate.split("T")[0];
    const projectEndDate = project.projectEndDate.split("T")[0];

    transformedEvents.push({
      date: projectStartDate,
      category: "project-start",
      description: `${project.projectName} Start`,
    });

    transformedEvents.push({
      date: projectEndDate,
      category: "project-end",
      description: `${project.projectName} End`,
    });
  });

  return transformedEvents;
}

// eventUtils.js

export function transformEvents(allEvents) {
  const transformedEvents = [];

  const getYear = new Date().getFullYear();

  if (allEvents?.birthday) {
    allEvents.birthday.forEach((birthday) => {
      const newDate = birthday.dob.split("T")[0];
      const updatedDate = `${getYear}-${newDate.split("-")[1]}-${
        newDate.split("-")[2]
      }`;
      transformedEvents.push({
        date: updatedDate,
        category: "birthdays",
        description: `${birthday?.name}'s Birthday`,
      });
    });
  }

  if (allEvents?.leave) {
    allEvents.leave.forEach((leave) => {
      transformedEvents.push({
        date: leave.fromDate.split("T")[0],
        category: "leaves",
        description: `${leave?.employeeId?.name} leave`,
      });
    });
  }

  if (allEvents?.holiday) {
    allEvents.holiday.forEach((holiday) => {
      transformedEvents.push({
        date: holiday.date.split("T")[0],
        category: "holidays",
        description: holiday.occupation,
      });
    });
  }

  if (allEvents?.project) {
    allEvents.project.forEach((project) => {
      transformedEvents.push({
        date: project.projectStartDate.split("T")[0],
        category: "project-start",
        description: `${project.projectName} Start`,
      });
      transformedEvents.push({
        date: project.projectEndDate.split("T")[0],
        category: "project-end",
        description: `${project.projectName} End`,
      });
    });
  }

  return transformedEvents;
}

export const formatLeaveDate = (fromDate, toDate) => ({
  startDate: fromDate.split("T")[0],
  endDate: toDate.split("T")[0],
});

export const formatBirthdayDate = (dob) => {
  const currentYear = new Date().getFullYear();
  return {
    startDate: `${currentYear}-${dob.split("-")[1]}-${
      dob.split("-")[2].split("T")[0]
    }`,
    endDate: `${currentYear}-${dob.split("-")[1]}-${
      dob.split("-")[2].split("T")[0]
    }`,
  };
};

export const formatDate = (date) => ({
  startDate: date.split("T")[0],
  endDate: date.split("T")[0],
});

export const transformLeaveEvent = (item) => ({
  ...formatLeaveDate(item.fromDate, item.toDate),
  category: "leaves",
  description: `${item?.employeeId?.name}'s leave`,
  status: item.status,
});

export const transformBirthdayEvent = (item) => ({
  ...formatBirthdayDate(item.dob),
  category: "birthdays",
  description: `${item?.name}'s birthday`,
});

export const transformProjectEvent = (item) => ({
  ...formatDate(item.projectStartDate),
  endDate: formatDate(item.projectEndDate).endDate,
  category: "project_start",
  description: `${item.projectName}`,
});

export const transformHolidayEvent = (item) => ({
  id: item?._id,
  ...formatDate(item?.date),
  category: "holidays",
  description: `${item?.occupation}`,
});

export const transformOtherEvent = (item) => ({
  id: item?._id,
  ...formatDate(item?.date),
  category: "other_events",
  description: `${item?.description}`,
});
// export async function getCurrentLocation() {
//   try {
//       const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });

//       if (permissionStatus.state === 'denied') {
//           // Show a custom message or UI explaining how to enable permissions
//           // showEnableLocationUI();
//           return await requestLocation();
//       } else if (permissionStatus.state === 'prompt' || permissionStatus.state === 'granted') {
//           // Attempt to request the location
//           return await requestLocation();
//       }
//   } catch (error) {
//       console.error("Error getting location:", error);
//   }
// }

// function requestLocation() {
//   return new Promise((resolve, reject) => {
//       if ("geolocation" in navigator) {
//           navigator.geolocation.getCurrentPosition(
//               (position) => {
//                   const latitude = position.coords.latitude;
//                   const longitude = position.coords.longitude;
//                   const accuracy = position.coords.accuracy;
//                   resolve({ latitude, longitude, accuracy });},
//               (error) => {
//                   if (error.code === error.PERMISSION_DENIED) {
//                       showEnableLocationUI();
//                   }
//                   reject(error);
//               }
//               ,{
//                 enableHighAccuracy: true,
//                 timeout: 10000,
//                 maximumAge: 0
//               }
//           );
//       } else {
//           reject(new Error("Geolocation is not supported by this browser."));
//       }
//   });
// }

// function showEnableLocationUI() {
//   const retry = alert("Location access is required for this feature. Please enable location permissions in your browser settings and refresh the page.");
//   if (retry) {
//       // Optionally, provide more detailed instructions or a link to the browser's settings page.
//   }
// }
// export function getCurrentLocation() {
//   return new Promise((resolve, reject) => {
//     if ("geolocation" in navigator) {
//       navigator.geolocation.getCurrentPosition(
//           (position) => {
//               const latitude = position.coords.latitude;
//               const longitude = position.coords.longitude;
//               resolve({ latitude, longitude });
//           },
//           (error) => {
//               if (error.code === error.PERMISSION_DENIED) {
//                   alert("Location permission denied. Please enable location permissions in your browser settings.");
//                   navigator.geolocation.getCurrentPosition(
//                     (position) => {
//                         const latitude = position.coords.latitude;
//                         const longitude = position.coords.longitude;
//                         resolve({ latitude, longitude });
//                     },)
//               }
//               reject(error);
//           }
      
//           );
//       } else {
//           reject(new Error("Geolocation is not supported by this browser."));
//       }
//   });
// }
// function showEnableLocationUI() {
//   const retry = confirm("Location access is required for this feature. Please enable location permissions in your browser settings and refresh the page.");
//   if (retry) {
//       // Optionally, provide more detailed instructions or a link to the browser's settings page.
//   }
// }
export async function getCurrentLocation() {
  try {
      // Check for navigator.permissions support
      if (navigator.permissions) {
          const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
  
          if (permissionStatus.state === 'denied') {
              // Show a custom message or UI explaining how to enable permissions
              showEnableLocationUI();
              return await requestLocation();
          } else if (permissionStatus.state === 'prompt' || permissionStatus.state === 'granted') {
              // Attempt to request the location
              return await requestLocation();
          }
      } else {
          // Fallback in case permissions API is not supported (older browsers)
          return await requestLocation();
      }
  } catch (error) {
      console.error("Error getting location:", error);
      return { error: 'Could not retrieve location' };
  }
}

function requestLocation() {
  return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
              (position) => {
                  const latitude = position.coords.latitude; // High precision
                  const longitude = position.coords.longitude; // High precision
                  const accuracy = position.coords.accuracy;
                  resolve({ latitude, longitude, accuracy });
              },
              (error) => {
                  if (error.code === error.PERMISSION_DENIED) {
                      showEnableLocationUI();
                  }
                  reject(error);
              },
              {
                  enableHighAccuracy: true,
                  timeout: 30000,
                  maximumAge: 0
              }
          );
      } else {
          reject(new Error("Geolocation is not supported by this browser."));
      }
  });
}

function showEnableLocationUI() {
  // Notify user to enable permissions
  alert("Location access is required for this feature. Please enable location permissions in your browser settings and refresh the page.");
}
