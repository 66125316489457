import React from "react";
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export function AccessToList(props) {
  return (
    <Modal
      open={props.giveAccessModalisOpen}
      onClose={() => props.setGiveAccessModalIsOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade
        in={props.giveAccessModalisOpen}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "400px",
          padding: "16px",
        }}
      >
        <Paper>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <TextField
              fullWidth
              label={`Whose Report ${props.selectedEmployeeId?.name} will get access to?`}
              variant="outlined"
              value={props.searchText}
              onChange={props.handleSearch}
              InputProps={{
                endAdornment: <SearchIcon />,
              }}
            />
          </div>
          <List
            style={{
              maxHeight: "400px",
              overflow: "auto",
            }}
          >
            {props.filteredEmployees?.map((employee) => (
              <ListItem
                key={employee.id}
                onClick={() => {
                  props.handleSelectEmployeeForAccess(employee);
                }}
              >
                <ListItemAvatar>
                  <Avatar>{employee.name[0]}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={employee.name}
                  secondary={employee.position}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Fade>
    </Modal>
  );
}
