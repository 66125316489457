import { create } from "zustand";

export const useUserReportStore = create((set) => ({
  userReports: [],
  setReports: (reports) =>
    set((state) => ({
      userReports: reports,
    })),
  totalReports: 0,
  setTotalReports: (totalCount) => set({ totalReports: totalCount }),
}));

export default useUserReportStore;
