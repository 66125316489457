// apiConfig.js

export const authHeaders = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + localStorage.getItem("token"),
};

export const getToken = localStorage.getItem("token");

export const setToken = (token) => {
  localStorage.setItem("token", token);
};
