import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { DataTable } from "../../components/table/Data-Table";
import Loading from "./../protectedPages/Loading";
import AddLeadModal from "./AddLeadModal";
import useLeadsHooks from "./Leads.hook";
import { columns } from "./columns";

const Leads = () => {
  const { leads, isLoading } = useLeadsHooks();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className=" flex justify-end">
        <AddLeadModal />
      </div>
      <DataTable columns={columns} data={leads||[]} defaultTheme={true}/>
    </>
  );
};

export default Leads;
