// import axios from "";
import axios from "../../config/axiosAuth";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/dialog";
import { baseUrl } from "../../config/baseUrl";
import { useEffect, useState } from "react";

export function DescriptionModal({
 data,
  trigger,
}) {
  const [open, setOpen] = useState(false);
 
  
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
       {trigger}
      </DialogTrigger>
      <DialogContent style={{width:'500px',display:'flex'}}>
        <>
          <div className="w-full">
            <div
              style={{
                padding: "20px",
                textAlign: "center",
                display:'flex'
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "2px solid gray",
                  marginBottom: "10px",
                  alignItems: "center",
                  display:'flex'
                }}
              ></div>
              <div className=" flex text-balance whitespace-pre-line break-words !max-h-[400px] max-w-[450px] overflow-y-auto h-max-content max-w-[500px]" style={{whiteSpace: 'pre-line',
    width:' 450px',
    /* text-overflow: ellipsis; */
    
    wordWrap: 'break-word'}}>
               {data}
              </div>
            </div>
          </div>
        </>
      </DialogContent>
    </Dialog>
  );
}
