import { CommentDialog } from "../../components/modals/CommentDialog";
import { AddCommentModal } from "../reports/AddCommentModal";
import EditLeadModal from "./EditLeaderModal";



export const columns = [


  {
    header: "S.no",
    cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },

  {
    accessorKey: "clientName",
    header: "Client Name",
  },
  {
    accessorKey: "companyName",
    header: "Company Name",
  },
  {
    accessorKey: "phone",
    header: "Phone",
  },
  {
    accessorKey: "email",
    header: "Email",
  },
  {
    accessorKey: "enquiryAbout",
    header: "Enquiry About",
  },
  {
    accessorKey: "feedBack",
    header: "Feedback",
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => {
      const status = row.getValue("status");
      let color;
      switch (status.toLowerCase()) {
        case 'done':
          color = 'green';
          break;
        case 'close':
          color = 'red';
          break;
        case 'hot leads':
          color = 'orange';
          break;
        case 'followup':
          color = 'blue';
          break;
        default:
          color = 'black';
      }
      return <span style={{ color: color, fontWeight: 'bold' }}>{status}</span>;
    },
  },
  {
    cell: ({ row }) => {
      return <EditLeadModal Lead={row.original} />;
    },
    header: "Edit",
  },

  {
    cell:({row})=>row.original?.link?<a href={row.original?.link} className="text-[blue] max-w-[150px] w-[150px] truncate">{row.original?.link}</a>:'No-Link',
      header: "Link URL",
    },

  {
    header: "Add Comment",
    cell: ({ row }) => <AddCommentModal id={row.original._id}url={'/addLeadCommentByAdmin'} />,
  },
  // {
  //     accessorKey: "name",
  //     header: "Delete",
  // },
  {
    cell: ({ row }) => {
      return <CommentDialog id={row.original._id} url={'/getLeadComments'}/>;
    },
    header: "Comments",
  },
];
