import { create } from "zustand";

// Create a Zustand store for birthdays
const useBirthdayStore = create((set) => ({
  birthdays: [],
  setBirthdays: (data) => set({ birthdays: data }),
  addBirthday: (birthday) =>
    set((state) => ({ birthdays: [...state.birthdays, birthday] })),
  removeBirthday: (birthdayId) =>
    set((state) => ({
      birthdays: state.birthdays.filter((item) => item.id !== birthdayId),
    })),

  // Add any other properties or functions related to birthdays here
}));

export default useBirthdayStore;
