import { create } from "zustand";

export const PreviewUrlStore = create((set) => ({
    previewUrls: [],
  setPreviewUrl: (reports) =>
    set((state) => ({
        previewUrls: reports,
    })),
 
}));

export default PreviewUrlStore;