import React, { useEffect, useState } from "react";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import Loading from "../protectedPages/Loading";
import { columns } from "./columns";
import { DataTable } from "../../components/table/Data-Table";
import axios from "../../config/axiosAuth";
import { baseUrl } from "../../config/baseUrl";
import DatePicker from "react-datepicker";
import { months } from "../../../utils/lib";

const Payroll = () => {
  const [salary, setSalary] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const handlepayroll = () => {
    const year = startDate.getFullYear();
    axios
      .post(baseUrl + "/salaryOfAllEmp", {
        year: year,
        month: selectedMonth,
      })

      .then((res) => {
        setSalary(res.data.results);
      })

      .catch((err) => {
      });
  };

  useEffect(() => {
    handlepayroll();
  }, [selectedMonth, startDate, endDate]);

  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value));
  };

  return (
    <>
    <div className="flex gap-2 mb-10">

      <select
        style={{ width: "10vw" }}
        name="month"
        id="Select"
        value={selectedMonth}
        onChange={handleMonthChange}
      >
        {months?.map((item) => (
          <option value={item.value}>{item.label}</option>
        ))}
      </select>
      <div style={{ width: "10vw" }}>
        <DatePicker
           dateFormat="dd/MM/yyyy"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
        />
      </div>
      <div style={{ width: "10vw" }}>
        <DatePicker
           dateFormat="dd/MM/yyyy"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          placeholderText="End Date"
        />
      </div>
    </div>
      <DataTable columns={columns} data={salary || []} defaultTheme={true} />
    </>
  );
};

export default Payroll;
