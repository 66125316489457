exports.baseUrl = "https://api.kickrtechnology.in/api/v1/admin";
exports.baseUrlUser = "https://api.kickrtechnology.in/api/v1/user";
exports.baseUrlCommon = "https://api.kickrtechnology.in/api/v1/common";
exports.base = "https://api.kickrtechnology.in";

exports.baseUrlUser =  process.env.REACT_APP_BASE_URL_USER

exports.baseUrl = process.env.REACT_APP_BASE_URL;
exports.baseUrlCommon = process.env.REACT_APP_BASE_URL_COMMON;
exports.base = process.env.REACT_APP_BASE;
