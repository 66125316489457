import { create } from "zustand";

// Define the Zustand store
const empForTheMothStore = create((set) => ({
  employeeForTheMonth: {}, // Initialize user as null
  setEmployeeForTheMonth: (userData) => {
    set({ employeeForTheMonth: userData });
  },
  clearUser: () => {
    set({ employeeForTheMonth: null });
  },
}));

export default empForTheMothStore;
