const { useQuery, useMutation } = require("react-query");
const {
  getNotifications,
  sendToAllNotification,
  sendToFewNotification,
} = require("../service/notificationServices");

const useNotificationHook = () => {
  const fetchAllNotifications = async () => {
    const data = await getNotifications("0");
    return data;
  };
  const fetchSpecificNotifications = async () => {
    const data = await getNotifications("1");
    return data;
  };

  const fetchAdminNotification = async () => {
    const data = await getNotifications("2");
    return data;
  };

  const { data: allNotifications, isLoading: allNotificationsIsLoading } =
    useQuery("allNotifications", fetchAllNotifications, {
      select: (data) => (data ? [...data].reverse() : []),
    });

  const {
    data: specificNotification,
    isLoading: specificNotificationIsLoading,
  } = useQuery("specificNotification", fetchSpecificNotifications, {
    select: (data) => (data ? [...data].reverse() : []),
  });

  const { data: adminNotification, isLoading: adminNotificationIsLoading } =
    useQuery("adminNotification", fetchAdminNotification);

  const sendToAllMutation = useMutation((data) => {
    return sendToAllNotification(data);
  });

  const sendToFewMutation = useMutation((data) => {
    return sendToFewNotification(data);
  });

  const isLoading =
    allNotificationsIsLoading ||
    specificNotificationIsLoading ||
    adminNotificationIsLoading;

  return {
    allNotifications,
    specificNotification,
    isLoading,
    sendToAllMutation,
    sendToFewMutation,
    adminNotification,
  };
};

export default useNotificationHook;
