import { getCurrentLocation } from "../../utils/transformData";
import { baseUrlCommon, baseUrlUser } from "../config/baseUrl";
import axios from "./../config/axiosAuth";

const authService = {
  getRole: async () => {
    const response = await axios.get(`${baseUrlCommon}/roleCheck`);
    return response.data.result;
  },

  zegoToken: async () => {
    const response = await axios.get(`${baseUrlUser}/employeeZegoToken`);
    return response.data;
  },

  loginEmployee: async (employeeId, password) => {
    const { latitude, longitude } = await getCurrentLocation();

    const response = await axios.post(`${baseUrlUser}/employeeLogin`, {
      employeeId: employeeId.trim(),
      password: password.trim(),
      lat:latitude,
      long:longitude,
      fcm_token: localStorage.getItem("fcmToken"),
    });

    return response;
  },

  sendOtpUser: async (employeeId) => {
    const response = await axios.post(`${baseUrlUser}/employeeForget`, {
      employeeId: employeeId,
    });
    return response.data;
  },
  sendRequest: async (employeeId) => {
    const response = await axios.post(`${baseUrlUser}/send-request`, {
      employeeId: employeeId,
    });
    return response.data;
  },

  resetPasswordUser: async (employeeId, otp, newPassword) => {
    const response = await axios.post(`${baseUrlUser}/resetPassword`, {
      employeeId: employeeId,
      otp: otp,
      newPassword: newPassword,
    });
    return response.data;
  },
};

export default authService;
