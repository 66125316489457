import { useMutation, useQueryClient } from "react-query";
import { addTicket } from "../../service/testingServices";

const useAddTicket = () => {
  const queryClient = useQueryClient();

  const addTicketMutation = useMutation(
    (data) => {
      return addTicket(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("allTickets");
        queryClient.invalidateQueries('getTicketsById')
      },
    }
  );

  return {
    addTicketMutation,
  };
};

export default useAddTicket;
