import React from "react";
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export function EmployeeList(props) {
  const{isEmployeeListOpen,setIsEmployeeListOpen,searchText,handleSearch,filteredEmployees,handleEmployeeSelect}=props
  return (
    <Modal
      open={isEmployeeListOpen}
      onClose={() =>setIsEmployeeListOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isEmployeeListOpen}>
        <Paper
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "400px",
            padding: "16px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: "8px",
            background: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <TextField
              fullWidth
              label="Search Employee to give access"
              variant="outlined"
              value={searchText}
              onChange={handleSearch}
              InputProps={{
                endAdornment: <SearchIcon />,
              }}
            />
          </div>
          <List
            style={{
              maxHeight: "400px",
              overflow: "auto",
            }}
          >
            {filteredEmployees?.length>0?filteredEmployees?.map((employee) => (
              <ListItem
                key={employee.id}
                onClick={() => {
                  handleEmployeeSelect(employee);
                }}
              >
                <ListItemAvatar>
                  <Avatar>{employee.name[0]}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={employee.name}
                  secondary={employee.position}
                />
              </ListItem>
            )):
            <ListItem>No results Found</ListItem>}
          </List>
        </Paper>
      </Fade>
    </Modal>
  );
}
