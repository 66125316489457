import { send } from "process";
import React, { useEffect, useRef, useState } from "react";
import { IoSend } from "react-icons/io5";

const ChatSection = ({ socketRef, userId, roomId,messages, setMessages,userName }) => {
  const [message, setmessage] = useState("");
  
  const chatBoxRef = useRef(null);

  
  const sendMessages = () => {
    const data = {
      message: message,
      sender: userId,
      room: roomId.linkId,
      senderName:userName,
      timestamp: new Date().toLocaleTimeString(),
    };
    socketRef.emit("sendMessageInRoom", {
      message: message,
      sender: userId,
      room: roomId.linkId,
      timestamp: new Date().toLocaleTimeString(),
    });
  };
  useEffect(() => {
    const chatBox = chatBoxRef.current;

    if (chatBox) {
      const observer = new MutationObserver((mutations) => {
        const hasNewMessages = mutations.some(
          (mutation) =>
            mutation.type === "childList" || mutation.type === "subtree"
        );

        if (hasNewMessages) {
          chatBox.scroll({
            top: chatBox.scrollHeight,
            behavior: "smooth",
          });
        }
      });
      observer.observe(chatBox, {
        childList: true,
        subtree: true,
      });
      return () => {
        observer.disconnect();
      };
    }
  }, []);
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setmessage("");
      sendMessages();
      e.target.value = "";
    }
  };

  return (
    <>
      <div className="people_head">Chat with the people</div>
      <div className="chat_inputs_box" ref={chatBoxRef}>
        {messages.map(
          (msg, i) =>
            msg.message &&
            msg.message !== "" && (
              <div
                key={i}
                className="chat_individual"
                style={{marginLeft:msg.sender===userId?'auto':'',marginRight:msg.sender!==userId?'auto':''}}
              >
                {msg.message && msg.message !== "" && (
                  <
                    
                  >
                    {/* <img
                      src={msg.image ? msg.image : "DummyAccount"}
                      alt={"/profile Icon"}
                      className="w-9 h-9 rounded-md flex self-end	"
                    /> */}
                    
                      <p
                        className={`${
                          "isDarkMode" ? "text-white" : "text-black"
                        } text-md font-bold mb-[5px] text-left normal-case`}
                      >
                        {msg.sender && msg.sender ===userId ? "You" : msg.senderName}
                      </p>
                     
                        <p
                          className={`normal-case	${
                            "" ? "text-white" : "text-black"
                          }`}
                        >
                          {msg.message}
                        </p>
                      
                     
                      <p
                        className='timestamp_text'
                         
                      >
                        {msg.timestamp}
                      </p>
                    </>
                 
                )}
              </div>
            )
        )}
      </div>
      <div className="button_section">
        <input
          className={`max-w-full h-[45px] flex-1 ${
            "isDarkMode" ? "bg-[#320272]  text-white" : "#FFFFFF text-black"
          } border-none`}
          placeholder="Type a message..."
          type="text"
          id="sendInput"
          value={message}
          onKeyPress={handleKeyPress}
          onChange={(e) => setmessage(e.target.value)}
        />

        <div
          className={`h-auto ${
            "isDarkMode" ? "bg-[#F81DFB] " : "bg-[#8871E6] hover:bg-[#8871E6]"
          }`}
          onClick={sendMessages}
        >
          <IoSend color="white" size="28px" />
        </div>
      </div>
    </>
  );
};

export default ChatSection;
