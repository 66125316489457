import axiosAuth from "../config/axiosAuth";
import { baseUrl } from "../config/baseUrl";

const apiRequest = async (method, url, data, params) => {
  try {
    const response = await axiosAuth({
      method,
      url: `${baseUrl}${url}`,
      data,
      params,
    });
    return response.data.result;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

const hiringServices = {
  getAllHiring: async () => {
    return apiRequest("get", "/getAllHiring");
  },

  addHiring: async (data) => {
    return apiRequest("post", "/addNewHiring", data);
  },

  updateHiring: async (id, data) => {
    return apiRequest("put", `/updateHiring/${id}`, data);
  },

  deleteHiring: async (id) => {
    return apiRequest("delete", `/deleteHiring/${id}`);
  },
};

export { hiringServices };
