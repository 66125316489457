import { useMutation, useQueryClient } from "react-query";
import { employeeServices } from "../../service/employeeService";

const useProjectHandleEmployeeStatus = () => {
  const queryClient = useQueryClient();

  const changeProjectMutation = useMutation(
    (data) => employeeServices.changeProjectStatus(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["projectReview"]);
      },
    }
  );

  return {
    changeProjectMutation,
  };
};

export { useProjectHandleEmployeeStatus };
