import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar.jsx";
import { AdminSIdeBar } from "../../components/sidebar/AdminSIdeBar";
import { projectServices } from "../../service/projectService.js";
import { TeamLeaders } from "../../components/infoCard/TeamLeaders.jsx";
import moment from "moment";
import axios from "../../config/axiosAuth.js";
import { baseUrl } from "../../config/baseUrl.js";
import { DataTable } from "../../components/table/Data-Table.jsx";
import { ProjectDetailColoum, TaskColoum } from "./columns.js";
import { ViewResponsibilities } from "../team/ViewResponsibilitiesDailog.jsx";

export const circle = {
  width: "10px",
  height: "10px",
  borderRadius: "50%",
  border: "1px solid black",
  display: "inline-block",
};

const tableHeaderStyle = {
  backgroundColor: "#f2f2f2",
  padding: "10px",
  borderBottom: "1px solid #ddd",
  textAlign: "left",
};

const tableCellStyle = {
  padding: "10px",
  borderBottom: "1px solid #ddd",
};

// New Modal component
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          background: "white",
          padding: "20px",
          borderRadius: "5px",
          maxWidth: "500px",
          width: "100%",
        }}
      >
        {children}
        <button
          onClick={onClose}
          style={{
            marginTop: "10px",
            width: "8vw",
            height: "4vh",
            borderRadius: "5px",
            border: "none",
            background: "#085394",
            color: "white",
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

const ProjectDetails = () => {
  const [taskmanagemnet, setTaskManagement] = useState();
  const [projectdetail, setProjectDetail] = useState([]);

  const params = useParams();
  const id = params.projectId;

  // New state for modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const fetchOneProject = async () => {
    const data = await projectServices.getOneProject(id);
    return data;
  };

  const fetchProjectReport = async () => {
    const data = await projectServices.getReportForProject(id);
    return data;
  };

  const { data: project } = useQuery(["oneProject", id], fetchOneProject);
  const handletaskmanagement = () => {
    axios
      .post(baseUrl + "/projectAllTask", {
        projectId: id,
      })
      .then((res) => {
        setTaskManagement(res.data.tasks);
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    handletaskmanagement();
  }, []);
  useEffect(()=>{
    setProjectDetail([project])
  },[project])

// const formatData = (data) => {
//   if(data){

//     return Object.entries(data).map(([teamName, teamData]) => ({
//       team: teamName,
//       startDate: new Date(teamData?.leaderStartDate).toLocaleDateString(),
//       endDate: new Date(teamData?.leaderEndDate).toLocaleDateString(),
//       leader: teamData?.leaderId?.name,
//       leaderId: teamData?.leaderId?.employeeId,
//       employees: teamData?.employees
        
//     }));
//   }
//   };
const employeeColumn=[
  { header: "Employee Id", cell:({row})=>row?.original?.employeeId?.employeeId },
  { header: "Employee Name", cell:({row})=>row?.original?.employeeId?.name },
  { header: "Employee Designation", cell:({row})=>row?.original?.employeeId?.designation?.name },

  { header: "Start Date", cell:({row})=> moment(row?.original?.employeeStartDate).format('DD-MM-YYYY') },
  { header: "End Date", cell:({row})=> moment(row?.original?.employeeEndDate).format('DD-MM-YYYY') },
]
const columns = React.useMemo(
  () => [
    { header: "Team", cell:({row})=>row.original?.teamType?.teamName },
    { header: "Start Date", cell:({row})=>moment(row.original?.leaderStartDate).format('DD-MM-YYYY') },
    { header: "End Date",cell:({row})=>moment(row.original?.leaderEndDate).format('DD-MM-YYYY') },
    { header: "Leader", cell:({row})=> row.original?.leaderId?.name},
    { header: "Leader ID",  cell:({row})=> row.original?.leaderId?.employeeId},
    { header: "Employees", cell:({row})=><ViewResponsibilities straightData={row.original?.members} columnData={employeeColumn} heading={'Employee list'}/> },
  ],
  [project,id]
);

  return (
    <>
      <div>
       
        <DataTable
          columns={columns||[]}
          data={project?.teams}
          defaultTheme={true}
          heading={
            <>
        <span className="mx-[10px]">Project: {project?.projectName}</span> <span className="mx-[10px]">Start Date :  {moment(project?.projectStartDate).format('DD-MM-YYYY')}</span><span className="mx-[10px]">End Date :  {moment(project?.projectEndDate).format('DD-MM-YYYY')}</span>
            </>
        
        }
        />
      </div>

      <div>
        <p
          style={{
            marginLeft: "1rem",
            marginTop: "1rem",
            fontWeight: "bold",
          }}
        >
          Task Management
        </p>

        <DataTable
          columns={TaskColoum}
          data={taskmanagemnet||[]}
          defaultTheme={true}
        />
      </div>

      {/* New Modal */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 style={{ fontWeight: "bold" }}>Documents</h2>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={tableHeaderStyle}>S.No</th>
              <th style={tableHeaderStyle}>Description</th>
              <th style={tableHeaderStyle}>Link</th>
              <th style={tableHeaderStyle}>Images</th>
              <th style={tableHeaderStyle}>End Date</th>
              <th style={tableHeaderStyle}>Status</th>
            </tr>
          </thead>
          <tbody>
            {selectedDocuments.length > 0 ? (
              selectedDocuments.map((doc, index) => (
                <tr key={index}>
                  <td style={tableCellStyle}>{index + 1}</td>
                  <td style={tableCellStyle}>{doc.description || "N/A"}</td>
                  <td style={tableCellStyle}>
                    {doc.link ? (
                      <a
                        href={doc.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View
                      </a>
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td style={tableCellStyle}>{doc.images ? "Yes" : "No"}</td>
                  <td style={tableCellStyle}>
                    {doc.endDate
                      ? moment(doc.endDate).format("DD-MM-YYYY")
                      : "N/A"}
                  </td>
                  <td style={tableCellStyle}>{doc.status || "N/A"}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="6"
                  style={{ ...tableCellStyle, textAlign: "center" }}
                >
                  No documents available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Modal>
    </>
  );
};

export default ProjectDetails;
