import React, { useState } from 'react';
import { MdClose } from "react-icons/md";
import axios from "../../config/axiosAuth";
import { baseUrl } from "../../config/baseUrl";
import { Category } from '@mui/icons-material';

const EventModal = ({ closeModal, reopenMeetingModal,activeCategory }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [dateTime, setDateTime] = useState("");
  const[eventCatogery,setEventCatogery]=useState('')

  const handleAddEvent = () => {
    axios
      .post(baseUrl + "/addMeeting", {
        title,
        category:
        description,
        date: dateTime,
      })
      .then((res) => {
        closeModal();
        // reopenMeetingModal();
      })
      .catch((err) => {
      });
  };

  const categories = [
   
    {
      label: "Project",
      value: "Projects",
    },
    {
      label: "Birthday",
      value: "birthday",
    },
    {
      label: "Meetings",
      value: "meetings",
    },
    {
      label: "Leaves",
      value: "leaves",
    },
    {
      label: "Events",
      value: "event",
    },
    {
      label: "Holidays",
      value: "holidays",
    },
  ];
  
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-8 relative z-10" style={{boxShadow:'0px 3px 3px'}}>
        <div
          onClick={closeModal}
          className="absolute top-2 right-2 cursor-pointer"
        >
          <MdClose size={24} onClick={closeModal}/>
        </div>
        <h2 className="text-xl font-bold mb-4 capitalize">{activeCategory}</h2>
        {/* <select value={eventCatogery} onChange={(e)=>setEventCatogery(e.target.value)}>

       { categories?.map((item)=><option value={item.value} key={item.value}>{item.label}</option>) }
        </select> */}
        <div style={{ marginTop: "1rem" }}>
          <input
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            style={{ borderBottom: "2px solid black" }}
            type="text"
            placeholder="Add title"
          />
        </div>

        <div style={{ marginTop: "1rem" }}>
          <input
            onChange={(e) => setDateTime(e.target.value)}
            value={dateTime}
            type="datetime-local"
            id="datetime"
            name="datetime"
          />
        </div>

        <div style={{ marginTop: "1rem" }}>
          <textarea
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            style={{ width: "20vw", height: "15vh" }}
            placeholder="Add Description"
          />
        </div>

        <div style={{ marginTop: "10px" }} className='flex gap-4'>
          <button
            onClick={handleAddEvent}
            style={{
              // backgroundColor: "#033FC1",
              // color: "white",
              // border: "none",
              // width: "8vw",
              // height: "4vh",
              // borderRadius: "5px",
              // cursor: "pointer",
            }}
            className=' bg-gray-300 text-white px-4 py-2 rounded bg-[#3f51b5] cursor-pointer'
          >
            Save Event
          </button>
        <button 
          onClick={closeModal}
          className=" bg-gray-300 text-white px-4 py-2 rounded  bg-[#3f51b5] cursor-pointer"
        >
          Cancel
        </button>
        </div>

      </div>
    </div>
  );
};

export default EventModal;