import { useMutation } from "react-query";

import commentServices from "../services/addCommentServices";
import { toast } from "react-toastify";

export const useCommentHook = () => {
  return useMutation(
    (data) => {
      return commentServices.postComment(data);
    },
    {
      onSuccess: () => {
        // toast.success("Comment Added Successfully");
      },
    }
  );
};

export const useEditComment = () => {
  return useMutation(
    (data) => {
      return commentServices.editComment(data);
    },
    {
      onSuccess: () => {
        // toast.success("Comment Updated Successfully");
      },
    }
  );
};

export const useDeleteComment = () => {
  return useMutation(
    (data) => {
      return commentServices.deleteComment(data);
    },
    {
      onSuccess: () => {
        // toast.success("Comment Deleted Successfully");
      },
    }
  );
};
