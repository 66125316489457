import { create } from 'zustand';

// Create a Zustand store
const useLeaveRequestStore = create((set) => ({
  leaves: [],         
  approvedLeave: [],  
  setLeaveRequests: (requests) => {
    set({ leaves: requests });
  },
  setApprovedLeave: (requests) => {
    set({ approvedLeave: requests });
  },
  totalApprovedLeaves: 0,
  setTotalApprovedLeaves: (totalCount) => set({ totalApprovedLeaves: totalCount }),
  totalLeaves: 0,
  setTotalLeaves: (totalCount) => set({ totalLeaves: totalCount }),
}));

export default useLeaveRequestStore;
