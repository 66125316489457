import { useMutation, useQuery, useQueryClient } from "react-query";
import taskServices from "../../services/TaskServices";

const useTaskMangementEditHook = () => {
  const queryClient = useQueryClient();

  const editTaskMutation = useMutation((data) => taskServices.editTask(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("tasks");
    },
  });

  return {
    editTaskMutation,
  };
};

export default useTaskMangementEditHook;
