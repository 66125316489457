import React, { useEffect, useState } from "react";

import "./sendNotification.css";
import { baseUrlUser } from "../../config/baseUrl";
import axios from '../../../PageResponsibility/src/admin/config/axiosAuth'

const UserNotification = () => {
  const [notification, setNotification] = useState([]);
  const getAllNotifications = async () => {
    try {
      const response = await axios.get(baseUrlUser + "/getAllNotification");
      setNotification(response.data.result);
      console.log(response.data.result);

      await axios.put(baseUrlUser + "/markRead", {});
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllNotifications();
  }, []);

  return (
    <>
      <h2>All Notifications</h2>
      <div className="notification_box">
        {notification?.map((notification) => (
          <div
            className="notification"
            style={
              notification?.isRead === false
                ? { backgroundColor: "#b9d2fb" }
                : {}
            }
          >
            <div
              style={{
                padding: "10px",
              }}
            >
              <div className="notification__header">
                <h6>{notification?.title}</h6>
              </div>
              <div className="notification__body">
                <p>{notification?.body}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default UserNotification;
