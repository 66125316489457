import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import {
  Modal,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Pagination,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import useAccessReportsHooks from "./getAccessReport";
import Loading from "./../protectedPages/Loading";
import { SendMessageModal } from "../../components/modals/SendMessageModal";

const AccessReportsTakeAccess = () => {
  const { accessReports, isLoading, isError, removeAccessMutation, refetch } =
    useAccessReportsHooks();

  const [currentPage, setCurrentPage] = useState(1);
  const [expandedEmployee, setExpandedEmployee] = useState(null);

  const pageSize = 3;

  let totalPages = Math.ceil(accessReports?.length / pageSize);

  const toggleEmployee = (employeeId) => {
    setExpandedEmployee((prev) => (prev === employeeId ? null : employeeId));
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [sendMessageModal, setSendMessageModal] = useState(false);
  const [employeeId, setEmployeeId] = useState([]);

  const handleOpenModal = (employee) => {
    setSelectedEmployee(employee);
    setModalOpen(true);
    refetch();
  };

  const handleCloseModal = () => {
    setSelectedEmployee(null);
    setModalOpen(false);
  };

  const handleDeleteAccess = (accessFrom, accessOn) => {

    removeAccessMutation.mutate({
      accessOn: accessOn._id,
      accessFrom: accessFrom._id,
    });

    handleCloseModal();
    setSendMessageModal(true);
    setEmployeeId([accessFrom._id]);
    refetch();
  };

  return (
    <>
      <h3 className="ml-2">Access Reports</h3>
      <div className="table-container">
        <table>
          <thead>
            <tr
              style={{
                color: "#085394",
                borderBottom: "2px solid #085394",
              }}
            >
              <th>S.No.</th>
              <th>Name</th>
              <th>Employee Id</th>
              <th colSpan={2}>Has Rights for</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="tableBody">
            {accessReports && accessReports?.length > 0 ? (
              accessReports
                ?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
                .map((employee, index) => {
                  const continuousSNo =
                    (currentPage - 1) * pageSize + index + 1;
                  const isExpanded = expandedEmployee === employee.employeeId;

                  return (
                    <React.Fragment key={employee.employeeId}>
                      <tr
                        style={{
                          borderBottom: "1px solid #ddd",
                          cursor: "pointer",
                        }}
                        onClick={() => toggleEmployee(employee.employeeId)}
                      >
                        <td>{continuousSNo}</td>
                        <td>{employee?.name}</td>
                        <td>{employee?.employeeId}</td>
                        <td>
                          {isExpanded ? (
                            employee.rights && employee.rights.length > 0 ? (
                              <ul
                                style={{
                                  listStyle: "none",
                                  padding: 0,
                                  marginLeft: 0,
                                }}
                              >
                                {employee.rights.map((right) => (
                                  <li
                                    key={right._id}
                                    style={{
                                      marginBottom: "8px",
                                    }}
                                  >
                                    <strong>{right.employeeId.name}:</strong>{" "}
                                    {Array.isArray(right.accessType) ? (
                                      right.accessType.map((item, index) => (
                                        <span key={index}>
                                          {item}
                                          {index <
                                            right.accessType.length - 1 && ", "}
                                        </span>
                                      ))
                                    ) : (
                                      <span>{right.accessType}</span>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              "No Access Rights"
                            )
                          ) : (
                            <span>Show Access Rights</span>
                          )}
                        </td>
                        <td>
                          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </td>

                        <td>
                          <Button
                            variant="contained"
                            onClick={() => handleOpenModal(employee)}
                          >
                            Take Access
                          </Button>
                        </td>
                      </tr>
                    </React.Fragment>
                  );
                })
            ) : (
              <tr>
                <td colSpan="4">No Employees present</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <Stack spacing={2} className="pagination-container">
        <Typography>Page: {currentPage}</Typography>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          color="primary"
        />
      </Stack>

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            padding: "20px",
          }}
        >
          <Typography variant="h6">
            {selectedEmployee?.name}'s Access Rights
          </Typography>
          <List>
            {selectedEmployee &&
              selectedEmployee.rights.map((right) => (
                <ListItem key={right._id}>
                  <ListItemText
                    primary={`${right.employeeId.name}: ${right.accessType.join(
                      ", "
                    )}`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() =>
                        handleDeleteAccess(
                          selectedEmployee,
                          // right._id,
                          // selectedEmployee.name
                          right.employeeId
                        )
                      }
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </div>
      </Modal>

      {isLoading && <Loading />}
      <SendMessageModal
        isOpen={sendMessageModal}
        onClose={() => setSendMessageModal(false)}
        employeeId={employeeId}
      />
    </>
  );
};

export default AccessReportsTakeAccess;
